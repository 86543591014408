import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 1;
  }

  .containerNode {
    color: #4a4a49;
    height: 100%;
    display: flex;
    flex-direction: column;   
    position: absolute;
    top: 0px;
    width: 100%;
    bottom: 0px;
  }

  .nomessage{
    text-align: center;
    margin: 2em;
    font-size: 1.2em;
  }
  
  .header {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    height: 56px;
    padding-left: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3em;
  }

  .bodyNode {
    height: 100%;
    overflow-y: auto;
  }

  .leftColumn {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: grey;
    width: 80px;
    min-width: 60px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 5px;
  }

  .leftColumn .icon {
    margin: 0 auto;
  }

  .leftColumn .alarmLevel {
    margin: 0 auto;
    font-size: 14px;
  }

  .rightColumn {
    /* height:120px; */
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: grey;
    display: table-cell;
  }

  .rightColumn .top {
    padding-right: 10px;
    padding-top: 10px;
  }

  .top .stationName {
    padding-left: 10px;
    font-size: 18px;
    display: inline-block;
    min-width: 50px;
  }

  .top .timestamp {
    color: grey;
    font-size: 14px;
    display: inline-block;
    float: right;
    min-width: 50px;
  }

  .rightColumn .content {
    margin-top: 10px;
  }

  .content .title {
    display: flex;
    padding-left: 10px;
    font-size: 14px;
  }

  .content .message {
    white-space: pre-wrap;
    display: flex;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 5px;
    color: rgba(6, 3, 4, 0.73);
    font-size: 14px;
  }

  .hidden{
    display: none;
  }

  .content .blank {
    height: 20px;
  }

  .close {
    float: right;
    color: gray;
    font-size: 26px;
    line-height: 56px;
    padding-right: 20px;
  }

  .close:hover {
    cursor: pointer;
    color: lightgray;
  }
`;
