import { html, LitElement } from 'lit-element';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import '@giswebgroup/ki-wcp-base/src/components/ki-icon/ki-icon';
import style from './ki-legend-bar.css';

export default class KiLegendBar extends LoaderMixin(LitElement) {
  static styles = style;

  constructor() {
    super();
    this.title = '';
    this.options = [];
  }

  static get properties() {
    return {
      title: { type: String },
      options: { type: Array },
    };
  }

  render() {
    return html`
      <table>
        <tr>
          ${this.options.map(
            item => html`
              <td>
                <div style="background:${item.marker.backgroundColor}" class="circle">
                  <div class="icon"><ki-icon icon=${item.marker.icon}></ki-icon></div>
                </div>
              </td>
              <td><div class="label">${item.label}</div></td>
            `,
          )}
        </tr>
      </table>
    `;
  }
}

customElements.define('ki-legend-bar', KiLegendBar);
