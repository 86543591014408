import { filter, groupBy, keyBy, find } from 'lodash-es/collection';
import moment from 'moment';
import { mapValues, map, uniq } from 'lodash-es';
import { maxBy } from 'lodash-es/math';
import { mapKeys } from 'lodash-es/object';
import Classifier from '@giswebgroup/ki-wcp-water/src/components/ki-station-map/Classifier';
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import proj4 from '@giswebgroup/ki-wcp-water/src/common/projections';

// TODO rename

const ca_sta_returnfields = []

function fetchValueLayerDatafromKiWIS(usergroup, groupId, kiwis, params = {}, options = {}){
  const reqObj = {
    timeseriesgroup_id: groupId,
    metadata: true,
    crs:"localxy",
    md_returnfields: [
      'station_id',
      'site_name',
      'station_name',
      'station_no',
      'ts_name',
      'ts_id',
      'station_id',
      'ts_path',
      'ts_shortname',
      'site_no',
      'stationparameter_name',
      'stationparameter_no',
      'ca_sta',
      'ts_unitsymbol',
      'parametertype_name'
    ],
    ca_sta_returnfields,
    ...params
  }
  if(options.classificationfields){
    reqObj.classificationfields = options.classificationfields;
  }
  
  return kiwis.getTimeseriesValueLayer(reqObj);
}

function fetchValueLayerData(usergroup, groupId, kiwis, params, options){
  return fetchValueLayerDatafromKiWIS(usergroup, groupId, kiwis, params, options) 
}


function getLayer(kiwis, groupId, groupType, usergroup, options) {
  let layer;
  if (groupType === 'station') {
    layer = kiwis.getStationList({
      stationgroup_id: groupId,
      returnfields: ['station_local_y', 'station_local_x','station_carteasting', 'station_cartnorthing','site_name', 'station_name', 'station_no', 'station_id', 'site_no', 'site_name', 'ca_sta', 'station_latitude', 'station_longitude', 'station_local_x', 'station_local_y'],
      ca_sta_returnfields,
    });
  } else {
    layer = fetchValueLayerData(usergroup, groupId, kiwis,null , options)
  }
  return layer;
}

async function getAdditionalGroups(additionalTsGroups, usergroup, kiwis){
  const result = {}
  await Promise.all(Object.entries(additionalTsGroups).map(([aGroupId, fields]) => {
    return fetchValueLayerData(usergroup, aGroupId, kiwis, {returnfields: fields.returnfields, md_returnfields: "station_no,stationparameter_no"})
      .then(values => {
        const indexMap = keyBy(values, v => `${v.station_no}-${v.stationparameter_no}`);
        Object.entries(fields).forEach(([key, dataField]) => {
          result[key] = mapValues(indexMap, dataField);
        });
      });
  }))
  return result;
}

export async function getCatchmentStatus(kiwis, catchmentClassificationInfocrue, options) {
  return !options.enableCatchmentByStatus
    ? new Classifier(catchmentClassificationInfocrue).classify([])
    : Promise.all([kiwis
        .getTimeseriesValues({
          format: 'json',
          metadata: true,
          ts_path: 'Bassins/*/Status/StatusInfoCrue.Cmd',
          returnfields: ['Timestamp', 'Value', 'Data Comment', 'Occurrance Timestamp'],
          md_returnfields: ['station_name', 'station_no', 'ts_name', 'ts_id', 'station_id', 'site_no', 'stationparameter_name', 'ca_sta'],
          ca_sta_returnfields: ['river_name'],
        })]).then(([catchments]) => {

    const now = moment();
    catchments.forEach(c => {
      const occts = c.data?.[0]?.[3];
      c.BASSIN_INFOCRUE = c.station_name;
      c.BASSIN_INFOCRUE_NO = c.station_no;
      c.catchmentAlarmTimestamp = (c.data?.[0]?.[0] && moment(c.data?.[0]?.[0]).format('L LT')) || '-';
      c.web_classes = c.data?.[0]?.[1];
      c.timestamp = c.data?.[0]?.[0];
      c.catchmentAlarmMessage = c.data?.[0]?.[2];
      if(c.web_classes ===0 && now > moment(c.timestamp).add(3, "days")){
        c.catchmentAlarmMessage = null;
        c.catchmentAlarmTimestamp = null;
      }
      if (occts && now > moment(occts)) {
        c.catchmentAlarmMessage = null;
        c.catchmentAlarmTimestamp = null;     
      }
    });
    return new Classifier(catchmentClassificationInfocrue).classify(catchments);
  })
    .catch(err => {
      console.error(err);
      return new Classifier(catchmentClassificationInfocrue).classify([]);
    });
}

async function replaceGroupId(kiwis, options) {
  const groups = await kiwis.getGroupList();
  function getId(name) {
    const names = name.split(",")
    const groupIds = [];
    groups.forEach(gr => {
      names.includes(gr.group_name) && groupIds.push(gr.group_id)
    })
    return groupIds.join();
  }
  options.groupId = getId(options.groupName);
  options.additionalTsGroups = mapKeys(options.additionalTsGroups, (v, k) => getId(k));
}

export default async function fetchData(options) {
  const { usergroup } = options ;
  const kiwis = new KIWIS({ basePath: options.kiwisPath });
  await replaceGroupId(kiwis, options);
  const { groupId, groupType, stationClassification, decimals, catchmentClassificationInfocrue, imagesBasePath, additionalTsGroups, parameterGrouping, stationfilter } = options;

  const statusPriorities = stationClassification?.tags.map(c => c.name) || [];

  const catchmentStatusMapping = catchmentClassificationInfocrue && {};
  catchmentClassificationInfocrue?.tags.forEach((t, k) => {
    catchmentStatusMapping[k + 1] = t.name;
  });

  return Promise.all([

    getCatchmentStatus(kiwis, catchmentClassificationInfocrue, options),

    getLayer(kiwis, groupId, groupType, usergroup, options).then(values => {
      return values
    }),

    // images info
    fetch(`${imagesBasePath}/index.json`)
      .then(response => {
        return response.json();
      })
      .then(images => {
        images.forEach(i => {
          i.file = `${imagesBasePath}/${i.file}`;
        });
        return images;
      }),

    // fetch additional columns
    additionalTsGroups && getAdditionalGroups(additionalTsGroups, usergroup, kiwis)
  ]).then(([catchments, stations, stationImages, additionalFields]) => {
    const formatter = new Intl.NumberFormat('fr-BE', { minimumFractionDigits: 2 , maximumFractionDigits: 2}); // TODO get proper language
    const formatterCoords = new Intl.NumberFormat('fr-BE', { minimumFractionDigits: 5 , maximumFractionDigits: 5}); // TODO get proper language

    if(stationfilter){
      stations = filter(stations, stationfilter)
    }

    if(parameterGrouping){
      const parname = groupBy(stations, "station_id");
      const mergedStations = [];
      Object.keys(parname).forEach(value => {
          const item = parname[value];
          const station = find(item, {"parametertype_name": parameterGrouping});
          if(station && item.length ===2 && (item[0].web_classes || item[1].web_classes)){
            if(item[0].web_classes){
              station.web_classes = `${item[0].parametertype_name}_${item[0].web_classes}`;
            }else{
              station.web_classes = `${item[1].parametertype_name}_${item[1].web_classes}`;
            }
            
            mergedStations.push(station)
          }else if(station){
            mergedStations.push(station)
          }
        })
        stations = mergedStations;
    }

  
  stations = new Classifier(stationClassification).classify(stations);
  const groupedStations = groupBy(stations, 'BASSIN_INFOCRUE_NO');
  const catchmentTagIndex = keyBy(catchments, 'BASSIN_INFOCRUE_NO');
  
    catchments.forEach(c => {
      c.stations = groupedStations[c.BASSIN_INFOCRUE_NO] || [];
    });
    let catchmentCalcTagIndex = {};
    let calculatedCatchmentStatus = null;
    if (options.enableCatchmentByObservation) {
      calculatedCatchmentStatus =
        stationClassification &&
        uniq(map(stations, 'BASSIN_INFOCRUE_NO')).map(c => {
          return {
            station_no: c,
            __tag: maxBy(groupedStations[c].filter(st => st.__tag!=="Outdated" && st.__tag!=="_default"), s => -statusPriorities.indexOf(s.__tag))?.__tag || '_default',
          };
        });

      catchmentCalcTagIndex = stationClassification && keyBy(calculatedCatchmentStatus, 'station_no');
    }



    stations.forEach(s => {
      if(decimals && s.ts_value){
        s.ts_value = +s.ts_value.toFixed(decimals)
      }
      s.point = [parseInt(s.station_local_x, 10), parseInt(s.station_local_y, 10)];
      [s.station_longitude, s.station_latitude] = proj4( "EPSG:31370", "EPSG:4326", s.point);
      [s.station_carteasting, s.station_cartnorthing] = proj4( "EPSG:31370", "EPSG:3812", s.point);
      s.lambert72 = `${s.point[0]} / ${s.point[1]}`
      s.lambert2008 = `${parseInt(s.station_carteasting,10)} / ${parseInt(s.station_cartnorthing,10)}`
      s.wgs84 = `${formatterCoords.format(s.station_longitude)} / ${formatterCoords.format(s.station_latitude)}`
      s.absoluteValue = s.GROUND_DATUM && s.ts_value !== null ? formatter.format(parseFloat(s.GROUND_DATUM) - s.ts_value) : null;
      s.catchment_status_value = catchmentTagIndex[s.BASSIN_INFOCRUE_NO]?.web_classes || null;
      s.catchment_status = catchmentTagIndex[s.BASSIN_INFOCRUE_NO]?.__tag || '_default';
      s.catchment_status_calc = catchmentCalcTagIndex[s.BASSIN_INFOCRUE_NO]?.__tag || '_default';
      if(options.translateAttributes){
        options.translateAttributes.forEach(attr => {s[`${attr}_transl`] = options.i18n.t(s[attr]) || s[attr]})
      }
      s.image = filter(stationImages, {
        site_no: s.site_no,
        station_no: s.station_no,
      });
      additionalFields &&
        Object.entries(additionalFields).forEach(([f, indexMap]) => {
          s[f] = indexMap[`${s.station_no}-${s.stationparameter_no}`];
        });
    });

    return {
      stations,
      catchments,
      calculatedCatchmentStatus,
    };
  });
}
