/*
 * stations: classified
 * catchments: classified
 * set stationClassification
 * fetch
 *
 *
 * idea: deboundce anotation?
 * */
// TODO need rework
import { addLinkFile } from '@giswebgroup/ki-wcp-base/src/common/style';
import fstyle from '@fortawesome/fontawesome-free/css/all.css';
import kistyle from '@giswebgroup/ki-watericons/css/external.css';
import mapicons from '@giswebgroup/ki-mapicons/css/external.css';
import computed from '@giswebgroup/ki-wcp-base/src/decorators/computed';
import {RGBAToHex, contrastColor} from '@giswebgroup/ki-wcp-base/src/common/util';
import LegendFactory from '@giswebgroup/ki-wcp-water/src/components/ki-station-map/LegendFactory';

const cssLoaded = Promise.all([
  addLinkFile(fstyle).then(() => {
    // for chrome 76+ ， load font file
    return document.fonts && document.fonts.load('900 14px "Font Awesome 5 Free"');
  }),
  addLinkFile(kistyle).then(() => {
    // for chrome 76+ ， load font file
    return document.fonts && document.fonts.load('300 14px "Kisterswater"');
  }),
  addLinkFile(mapicons).then(() => {
    // for chrome 76+ ， load font file
    return document.fonts && document.fonts.load('300 14px "Kistersmap"');
  }),
]).catch(err => {
  console.error(err);
});

export default class TagStyleConfig {
  constructor() {
    this.legendFactory = new LegendFactory(); // TODO
  }

  set config(config) {
    this._config = config;
    cssLoaded.then(() => {
      this.legendFactory = new LegendFactory(this._config);
    //  this.view.requestUpdate();
    });
  }

  @computed('_config')
  get statusBarStyles() {
    const statusBarStyles = {};
    this._config.tags.forEach(item => {
      statusBarStyles[item.name] = {
        backgroundColor: item.marker.backgroundColor,
        color: contrastColor(RGBAToHex(item.marker.backgroundColor), true),
        iconColor: item.marker.color,
        icon: item.marker.icon,
        label: item.label,
      };
    });
    return statusBarStyles;
  }

  @computed('_config')
  get catchmentsStyles() {
    const catchmentsStyles = {};
    const items = this._config.tags.concat(this._config.default ? [{ name: '_default', ...this._config.default }] : []);
    items.forEach(item => {
      catchmentsStyles[item.name] = {
        name: item.name,
        marker: item.marker,
        style: item.style,
        highlightStyle: item.highlightStyle,

      };
    });
    return catchmentsStyles;
  }

  @computed('_config')
  get tableTagStyle() {
    const tags = {};
    this._config.tags.forEach(item => {
      tags[item.name] = {
        backgroundColor: item.marker.backgroundColor,
        color: item.marker.color,
        iconColor: item.marker.color,
        icon: item.marker.icon,
        label: item.label,
        showLabelInTable: this._config.showLabelInTable,
      };
    });
    return tags;
  }

  @computed('legendFactory')
  get tagMarkers() {
    // TODO performance?
    return this.legendFactory.markers;
  }

  @computed('legendFactory')
  get tagSelectedMarkers() {
    return this.legendFactory.selectedMarkers;
  }

  @computed('legendFactory')
  get legendOptions() {
    return this.legendFactory.getLegendOptions();
  }
}
