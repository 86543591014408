import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
  }

  .handler {
    width: 20px;
    padding: 0;
    cursor: ew-resize;
    flex: 0 0 auto;
    align-items: center;
    display: flex;
  }

  .handler::before {
    content: '';
    display: block;
    width: 4px;
    height: 5%;
    background: #acb4c1;
    margin: 0 auto;
    border-radius: 50px;
  }

  .containerNode {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 0;
  }

  .containerNode {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 0;
  }

  .containerNode .graphWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
    padding-right: 10px;
  }

  .containerNode .graphWrapper #graph {
    display: flex;
  }

  .containerNode .graphWrapper #graph {
    flex: 1;
    min-height: 0;
    position: relative;
  }

  .containerNode .graphWrapper #graph .highcharts-container .highcharts-legend .highcharts-legend-item span {
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -moz-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -ms-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 50px;
  }

  .containerNode .graphWrapper #graph .highcharts-container .highcharts-legend .highcharts-legend-item span:hover {
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.23);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -o-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.23);
  }

  .visible {
    display: flex;
  }

  .containerNode .tableWrapper.visible {
    flex: 0 0 470px;
    border-left: 1px solid #acb4c1;
  }

  .hidden {
    display: none;
    width: 0;
  }

  .containerNode .tableWrapper {
    flex-direction: column;
    overflow: auto;
  }
  .navBar {
    display: flex;
    height: 56px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    padding: 0 1em;
  }

  .navBar .leftHeader {
    align-items: center;
    display: flex;
    flex: 1;
    height: 100%;
    background: white;
    color: rgb(74, 74, 73);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    max-width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .navBar .rightHeader {
    align-items: center;
    display: flex;
    white-space: nowrap;
    flex: 1;
    justify-content: flex-end;
  }

  .separator {
    font-size: 1.5em;
    font-weight: bold;
    padding: 0 0.5em;
  }

  .navBar ki-icon-btn {
    box-shadow: none;
    margin: 0 0.5em;
  }

  .navBar ki-icon-btn.selected,
  .navBar ki-icon-btn:hover {
    box-sizing: border-box;
  }

  .navBar ki-icon-btn.selected,
  .navBar ki-icon-btn.selected:hover {
    color: #ffffff;
    background: #1093cd;
  }


  .navBar ki-icon-btn.selected ki-icon::part(icon),
  .navBar ki-icon-btn.selected:hover ki-icon::part(icon) {
    fill: #ffffff;
  }


  .navBar ki-icon-btn:hover {
    color: #4a4a49;
  }

  @media (max-width: 1024px) {
    .separator {
      display: none;
    }

    .navBar {
      flex-direction: column-reverse;
      height: 80px;
    }

    .navBar .rightHeader {
      border-bottom: 1px solid rgb(226, 228, 237);
      justify-content: flex-end;
    }
  }
  @media (max-width: 1424px) {
    .containerNode .tableWrapper.visible {
      flex: 0 0 100%;
    }
    .containerNode .graphWrapper {
      width: 0;
      padding: 0;
    }
  }
`;
