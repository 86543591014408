import { css, html, LitElement } from 'lit-element';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-menu/ki-station-menu';
import moment from 'moment';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Map, View } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import computed from '@giswebgroup/ki-wcp-base/src/decorators/computed';
import autoRun from '@giswebgroup/ki-wcp-base/src/decorators/autoRun';
import query from '@giswebgroup/ki-wcp-base/src/decorators/query';
import { template as lodashTemplate, isNil } from 'lodash-es';
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import { FontIcon } from '@giswebgroup/ki-wcp-water/src/components/ki-station-map/FontIcon';
import {Tile as TileLayer} from "ol/layer";
import XYZ from "ol/source/XYZ";
import TileGrid from "ol/tilegrid/TileGrid";
import { until } from 'lit-html/directives/until.js';
import nls from '../../locales/index.nls';
import {  template } from '@giswebgroup/ki-wcp-base/src/common/util';
import queryParam from '@giswebgroup/ki-wcp-base/src/decorators/queryParam';

export default
@i18n(nls)
class KiStationDetailPanel extends LoaderMixin(LitElement) {
  // TODO move to css.js
  // language=CSS
  static styles = css`
    :host {
      display: block;
      height: 100%;
      background: white;
      z-index: 11;
    }

    hr {
      margin: 0;
    }
    .station-detail-panel-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    #tree-menu {
      flex: 1;
    }
    .header-panel {
      position: relative;
    }

    .header-panel img {
      width: 100%;
      object-fit: cover;
    }

    .titlePanel {
      position: absolute;
      width: 100%;
      padding: 18px 16px;
      box-sizing: border-box;
      height: 50%;
      background: linear-gradient(180deg, #f5f5f5, transparent);
      z-index: 9;
    }

    .title {
      height: 56px;
      width: 298px;
      color: #4a4a49;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }

    .ts-overview {
      display: flex;
      flex-wrap: wrap;
    }

    .tile {
      display: flex;
      padding: 3px 8px;
      flex: 0 0 50%;
      box-sizing: border-box;
    }

    .tile .up {
      transform: rotate(45deg);
    }

    .tile .down {
      transform: rotate(-45deg);
    }

    .tile ki-icon {
      display: inline-block;
      color: var(--theme-color, #0056a0);
      font-size: 18px;
      padding: 8px;
    }

    .tile .label {
      font-size: 10px;
      line-height: 16px;
      color: var(--theme-color, #0056a0);
    }

    .tile .value {
      font-size: 14px;
      line-height: 21px;
      color: #4a4a49;
    }

    .close-btn {
      color: #7f8b91;
      position: absolute;
      top: 18px;
      right: 18px;
    }

    .close-btn:hover {
      color: #4a4a49;
      cursor: pointer;
    }

    .action-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      color: var(--theme-color);
      font-size: 12px;
      border-top: 1px solid gray;

      border-bottom: 1px solid gray;
    }
    .action-bar ki-icon {
      font-size: 14px;
    }
    .action-btn {
      cursor: pointer;
      padding: 10px 0;
    }
    .contact-us {
      text-decoration: none;
      color: var(--theme-color, #0056a0);
    }

    .action-bar ki-icon.navbutton{
      font-size: 2em;
      padding: 10px 20px;
      cursor: pointer;
    }
    /* TODO */
    :host /deep/ ki-tree-node {
      font-size: 1.5em;
      color: red;
    }

    .status-bar {
      background: rgba(0, 128, 0, 0.2);
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 128, 0, 1);
      font-size: 1.2em;
    }

    .status-bar ki-icon {
      width: 29px;
      text-align: center;
    }

    .status-bar span {
      padding: 0 8px;
      color: #4a4a49;
    }
    #map {
      text-align: center;
    }
    #map img {
      height: 100%;
    }
    .hidden {
      display: none;
    }
  `;

  static get properties() {
    return {
      station: { type: Object },
      kiwisPath: { type: String },
      menuConfig: { type: Array },
      detailsConfig: { type: Object },
      statusBarStyles: { type: Object },
      mapBackGroundLayer: { type: String },
      isFavourite: { type: Boolean },
      supporter: { type: Object },
      topic: { type: String },
      //
      __tsOverview: { type: Object },
      __menuOptions: { type: Array },
      __majorFloods: { type: Object },
      __map: { type: Object },
      __markerLayer: { type: Object },
    };
  }


  @queryParam('topic')
  topic;

  @query('#map')
  __mapElement;

  @query('#stationImg')
  __imgElement;

  @query('#treeMenu')
  __treeMenu;

  @computed('kiwisPath')
  get kiwis() {
    return new KIWIS({
      basePath: this.kiwisPath,
    });
  }

  constructor() {
    super();
    this.menuConfig = [];
    this.__menuOptions = [];
  }

  // eslint-disable-next-line class-methods-use-this
  _renderTsTile({ icon, label, value, iconClass }) {
    // TODO
    return html`
      <div class="tile">
        <ki-icon class="${iconClass}" icon="${icon}"></ki-icon>
        <div>
          <div class="label">${label}</div>
          <div class="value">${until(value, "...")}</div>
        </div>
      </div>
    `;
  }

  _renderTsOverview() {
    const tsOverview = this.__tsOverview;
    if (!tsOverview) {
      return '';
    }
    const iconStyles = {
      0: 'ki ki-arrow-right',
      1: 'ki ki-arrow-up',
      '-1': 'ki ki-arrow-down',
    };
    const topics = this.detailsConfig.topics || ['last_value', 'last_measure_time', 'one_hour_value', 'tendency'];
    const formatter = new Intl.NumberFormat('fr-BE', { minimumFractionDigits: 2 , maximumFractionDigits: 2}); // TODO get proper language

    const lastVal = this.detailsConfig.lastVal ? tsOverview[this.detailsConfig.lastVal] : tsOverview.ts_value || tsOverview.invalidatedValue;

    const tiles = [
      {
        topic: 'last_value',
        icon: this.detailsConfig.infoIcon || 'ki ki-water-drop',
        label: this.detailsConfig.lastValueLabel || this.i18n.t(tsOverview.stationparameter_name) || this.i18n.t('last_measure'),
        value: isNil(lastVal) ? '-' : `${lastVal} ${tsOverview.ts_unitsymbol}`,
      },
      {
        topic: 'last_measure_time',
        icon: 'ki ki-clock-o',
        label: this.detailsConfig.tsFormat === 'L' ? this.i18n.t('last_measure_date') : this.i18n.t('last_measure_time'),
        value: tsOverview.timestamp ? moment(tsOverview.timestamp).format(this.detailsConfig.tsFormat || 'L LT') : '-',
      },
      {
        topic: 'last_measure_date',
        icon: 'ki ki-calendar',
        label: this.detailsConfig.tsFormat === 'L' ? this.i18n.t('last_measure_date') : this.i18n.t('last_measure_time'),
        value: tsOverview.timestamp ? moment(tsOverview.timestamp).format(this.detailsConfig.tsFormat || 'L LT') : '-',
      },
      {
        topic: 'inactive_since',
        icon: 'ki ki-calendar',
        label: this.detailsConfig.tsFormat === 'L' ? this.i18n.t('last_measure_date') : this.i18n.t('last_measure_time'),
        value: tsOverview.GR_EQ_DESDATE ? moment(tsOverview.GR_EQ_DESDATE).format('L') : '-',
      },
      {
        topic: 'tendency',
        icon: iconStyles[tsOverview.tendencyValue],
        label: this.i18n.t('tendency'),
        value: tsOverview.tendencyValue !== null && tsOverview.tendencyValue !== undefined ? this.i18n.t(`trend_${tsOverview.tendencyValue}`) : '-',
      },
      {
        topic: 'lowest_value',
        icon: 'ki ki-arrow-up',
        label: this.i18n.t('gw_ts_highest_value'),
        value: isNil(tsOverview.lowestValue) ? '-' : `${formatter.format(tsOverview.lowestValue)} m`,
      },
      {
        topic: 'lowest_date',
        icon: 'ki ki-calendar',
        label: this.i18n.t('gw_ts_lowest_date'),
        value: tsOverview.lowestTime ? moment(tsOverview.lowestTime).format(this.detailsConfig.tsFormat || 'L LT') : '-',
      },
      {
        topic: 'highest_value',
        icon: 'ki ki-arrow-down',
        label: this.i18n.t('gw_ts_lowest_value'),
        value: isNil(tsOverview.highestValue) ? '-' : `${formatter.format(tsOverview.highestValue)} m`,
      },
      {
        topic: 'highest_date',
        icon: 'ki ki-calendar',
        label: this.i18n.t('gw_ts_highest_date'),
        value: tsOverview.highestTime ? moment(tsOverview.highestTime).format(this.detailsConfig.tsFormat || 'L LT') : '-',
      },
      {
        topic: 'absolute_value',
        icon: 'ki ki-water-drop',
        label: this.i18n.t('absoluteValue'),
        value: this.fetchLastValue(tsOverview.ts_path) // tsOverview.absoluteValue === null ? '-' : `${tsOverview.absoluteValue} m`,
      },
      {
        topic: 'GR_TYPECAPTEUR',
        icon: 'ki ki-info',
        label: this.i18n.t('GR_TYPECAPTEUR'),
        value: tsOverview.GR_TYPECAPTEUR || "",
      },
      {
        topic: 'GR_EQUIPEMENT',
        icon: 'ki ki-home',
        label: this.i18n.t('GR_EQUIPEMENT'),
        value: tsOverview.GR_EQUIPEMENT_transl || "",
      },
    ].filter(aTopic => topics.indexOf(aTopic.topic) > -1);
    return html`<div class="ts-overview" ">
      ${tiles.map(t => this._renderTsTile(t))}
    </div>`;
  }

  _renderStatusBar() {
    if (this.detailsConfig.hideStatus) {
      return '';
    }
    const statusStyle = this.statusBarStyles[this.__station.__tag];
    return html`<div class="status-bar" style="background-color:${statusStyle.backgroundColor}; color:${statusStyle.color}">
      <ki-icon icon="${statusStyle.icon || ''}"></ki-icon>
      <span style="color:${statusStyle.color}">${statusStyle.label}</span>
    </div>`;
  }

  render() {
    if (!this.station || !this.statusBarStyles) {
      return html``;
    }
    // eslint-disable-next-line no-template-curly-in-string
    const title = this.detailsConfig.title || '${station.station_no} | ${station.station_name} | ${station.river_name}';
    // language=html
    return html`
      ${this._renderLoader()}
      <div class="station-detail-panel-wrapper">
        <div class="header-panel">
          <div class="titlePanel">
            <span class="title">${lodashTemplate(title)(this)}</span>
            <ki-icon id="close-btn" title="${this.i18n.t('close')}" class="close-btn" icon="ki ki-times" @click="${this.close}"></ki-icon>
          </div>
          <div id="map" style="display:block;height: 200px;" alt="">
            <img id="stationImg" />
          </div>
        </div>
        <div>
          <div class="action-bar">
            <ki-icon class="navbutton" icon="ki ki-chevron-left"  @click="${this.prevStation}"></ki-icon>
            <div class="action-btn" @click="${this.toggleFavourite}">
              <ki-icon icon="ki ${this.isFavourite ? 'ki-star' : 'ki-star-o'}"></ki-icon>
              <div>${this.i18n.t('favourite_station')}</div>
            </div>
            <a class="action-btn contact-us" id="contactus-btn" @click="${this.contactUs}">
              <ki-icon icon="ki ki-envelope"></ki-icon>
              <div>${this.i18n.t('contactus-btn')}</div>
            </a>
            <ki-icon class="navbutton" icon="ki ki-chevron-right" @click="${this.nextStation}"></ki-icon>
          </div>
          ${this._renderStatusBar()} ${this._renderTsOverview()}
        </div>
        <hr />
        <ki-station-menu id="treeMenu" @itemSelected=${(e)=> {this.topic = e.detail.value}} .value="${this.topic}" .station="${this.station}" .items="${this.__menuOptions}" .floods="${this.__majorFloods}" .detailsConfig="${this.detailsConfig}"></ki-station-menu>
      </div>
    `;
  }

  prevStation() {
    this.dispatchEvent(
      new CustomEvent('selectstation', {
        bubbles: true,
        composed: true,
        detail:{station: -1}
      }),
    );
  }

  nextStation() {
    this.dispatchEvent(
      new CustomEvent('selectstation', {
        bubbles: true,
        composed: true,
        detail:{station: 1}
      }),
    );
  }

  @autoRun(['__mapElement', 'station'])
  _createMap() {
    this.__imgElement && (this.__imgElement.src = ''); // reset
    if (this.station?.image?.length && this.station.image[0].file) {
      // image is array
      this.__imgElement.src = this.station.image[0].file;
    }

    if (this.__mapElement && !this.__map) {
      this.__markerLayer = new VectorLayer({ source: new VectorSource() });
      this.__markerLayer.setZIndex(1);
      this.__map = new Map({
        layers: [this.__markerLayer],
        target: this.__mapElement,
        controls: [],
        view: new View({
          center: this.station.point,
          zoom: 18,
          projection: 'EPSG:31370',
        }),
      });

      this.__map.addLayer(new TileLayer({
        name: 'walonmap',
        maxZoom: 22,
        opacity: 1.0,
        source: new XYZ({
          tileGrid: new TileGrid({
            tileSize:  [512, 512],
            sizes:  [512, 512],
            origin: [-35872700, 41422700],
            resolutions: [396.87579375158754, 330.72982812632296, 264.5838625010584, 198.43789687579377, 132.2919312505292, 66.1459656252646, 26.458386250105836, 19.843789687579378, 13.229193125052918, 6.614596562526459, 3.9687579375158752, 2.6458386250105836, 1.3229193125052918, 0.6614596562526459, 0.26458386250105836, 0.13229193125052918, 0.06614596562526459],
            extent: [42000.1053417176, 19999.916642703116, 296000.1053417176, 167999.91664270312]
          }),
          projection:"EPSG:31370",
          url: '//geoservices.wallonie.be/arcgis/rest/services/IMAGERIE/ORTHO_LAST/MapServer/tile/{z}/{y}/{x}',
          crossOrigin: 'Anonymous',
          params: {format: "JPEG"}
        }),
      }));

    }

    const mapNode = this.renderRoot.querySelector('#map .ol-viewport');
    mapNode && mapNode.classList.toggle('hidden', this.station?.image?.length);
    this.__imgElement && this.__imgElement.classList.toggle('hidden', !this.station?.image?.length);
  }

  toggleFavourite() {
    this.isFavourite = !this.isFavourite;
    this.dispatchEvent(
      new CustomEvent('toggleFavourite', {
        detail: {
          stationId: this.station.station_id,
          isFavourite: this.isFavourite,
        },
      }),
    );
  }

  contactUs() {
    const contactUs = this.renderRoot.querySelector('#contactus-btn');
    const user = this.supporter && this.supporter.mail;
    const subject =  this.supporter && this.supporter.subject ? template(this.supporter.subject, this.station) :`${this.station.station_name}`;
    contactUs.href = `mailto:${user}?subject=${subject}`;
  }

  close() {
    this.dispatchEvent(
      new CustomEvent('close', {
        detail: {
          values: this.values,
        },
      }),
    );
  }

  @autoRun(['station', 'kiwis'])
  // eslint-disable-next-line consistent-return
  fetchTsOverView(station, kiwis) {
    if (station && kiwis) {
      this.__tsOverview = station;
    }
  }

  @computed('kiwis')
  fetchLastValue(ts_path, type = "Absolute Value"){
    this.lastAbsValue = this.kiwis
      .getTimeseriesValues({ts_path, metadata:true, returnfields:`Timestamp,${type},AV Quality Code`},
      null,
      'json').then(([ts]) => {
        if(ts && ts.data[0] && ts.data[0][1]){
            return `${ts.data[0][1].toFixed(2)} ${ts.ts_unitsymbol}`
        }
          return "-"
        
      })
      return this.lastAbsValue;
  
  }


  @autoRun(['station', 'kiwis'])
  // eslint-disable-next-line consistent-return
  fetchMajorFloods(station, kiwis) {
    if (station && kiwis && station.parametertype_name === "H" || station.parametertype_name === "Q") {
      this.__majorFloods = null;
      return kiwis
        .getTimeseriesValues(
          {
            ts_path: `${station.site_no}/${station.station_no}/${station.stationparameter_no}/Cmd.ReferenceFlood.Top3`,
            period: 'complete',
            metadata: true,
          },
          null,
          'json',
        )
        .then(([ts]) => {
          if (!ts) {
            return;
          }
          if (ts.data.length > 0) {
            this.__majorFloods = ts;
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
  }

  @autoRun(['station', '__map', '__markerLayer', '__mapElement'])
  // eslint-disable-next-line class-methods-use-this
  _positionMarker(station, map, markerLayer) {
    if(station && this.topic){
      this.dispatchEvent(
        new CustomEvent('itemSelected', {
          detail: { value: this.topic},
          bubbles: true,
          composed: true,
        }),
      );
    }
    if (station && map && markerLayer) {
      map.setTarget(this.__mapElement);
      markerLayer.getSource().clear();
      const marker = new Feature(new Point(station.point));
      marker.setStyle(
        new Style({
          image: new FontIcon({
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'red',
            icon: 'ki ki-map-marker',
          }),
        }),
      );
      markerLayer.getSource().addFeature(marker);
      map.updateSize();
      map.getView().setCenter(station.point);
      map.getView().setZoom(18);
    }
  }

  @autoRun(['menuConfig', 'kiwis', 'station'])
  // eslint-disable-next-line consistent-return
  _filterMenuOptions(menuConfig, kiwis, station) {
    if (menuConfig && kiwis && station) {
      return kiwis
        .getTimeseriesList(
          {
            station_no: station.station_no,
          },
          ['ts_path'],
        )
        .then(data => {
          data = data.map(item => item.ts_path);
          // filter ts_path
          // TODO
          menuConfig.forEach(c => {
            c.children = c.children
              .map(item => {
                // eslint-disable-next-line camelcase
                // TODO need discuss, should provide from component or in config file.
                if (item.options?.config?.tsPath) {
                  const { tsPath } = item.options.config;
                  if (typeof tsPath === 'string' && data.indexOf(tsPath) < 0) {
                    return null;
                  }
                  // array
                  if (Array.isArray(tsPath) && tsPath.every(it => data.indexOf(it.ts_path) < 0)) {
                    return null;
                  }
                }
                return item;
              })
              .filter(item => !!item);
          });

          this.__menuOptions = menuConfig;
        });
    }
  }
}

customElements.define('ki-station-detail-panel', KiStationDetailPanel);
