/* eslint-disable */
import { html, LitElement } from 'lit-element';
import cloneDeep from 'lodash-es/cloneDeep';
import { orderBy, last } from 'lodash-es';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import * as moment from 'moment';
import { every, find, forEach, isEmpty, isNull, isNumber, isUndefined, merge, keyBy, values } from 'lodash-es';
import KiToast from '@giswebgroup/ki-wcp-base/src/components/ki-toast/ki-toast';
import {template} from "@giswebgroup/ki-wcp-base/src/common/util";
import "@giswebgroup/ki-wcp-base/src/components/ki-popup/ki-popup";
import "@giswebgroup/ki-wcp-base/src/components/ki-list/ki-list";
import KiQualityBar from '../ki-quality-bar/ki-quality-bar';
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import * as Utils from '../ki-chart/ki-chart-utils';
import '../ki-chart/ki-chart';
import style from './ki-timeseries-graph.css';
import nls from '../../locales/index.nls';
import KiGraphConfiguration from '../ki-graph-configuration/ki-graph-configuration';
import { formatNumber } from '../ki-chart/ki-chart-utils';
import '../ki-time-range-picker/ki-time-range-picker';
import '@giswebgroup/ki-wcp-base/src/components/ki-icon/ki-icon-btn';
import './ki-timeseries-table';  
import '@vaadin/vaadin-select';
import queryParam from '@giswebgroup/ki-wcp-base/src/decorators/queryParam';
import responsive, { SM, ViewPort } from '@giswebgroup/ki-wcp-base/src/decorators/responsive';

const CACHE_HEADER_TIME = 300; // In seconds
const QUALITY_VISIBILITY_KEY = 'ki-timeseries-graph-quality-visibility';
const DEFAULT_TRANSFORMATIONS = ['min', 'max'];
const MAX_NUM_POINTS = 5000;

export default
@i18n(nls)
class KiTimeseriesGraph extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  _customSerieVisibility;

  _dataGridCols;

  _dataGridData;

  _gaps;

  _fromTime;

  _toTime;

  _qualityBarValues;

  _serieDefinition;

  _tableValues;

  _timeseries;

  _tsPaths;

  _idProperty;

  _init;

  _tsPathProperty;

  alarms;

  dataPersistence;

  defaultPeriod;

  defaultPeriodCoverage;

  expiryDate;

  flexibleAggregations;

  graphConfig;

  graphName;

  indicators;

  kiwisUrl;

  levels;

  maxNumPoints;

  fromParam;

  toParam;

  qualityCodes;

  referenceFloods;

  resolutionParam;

  showGraphConfig;

  tsPath;

  transformations;

  zoomEnabled;

  zones;

  @queryParam('station')
  currentStationId;


  @queryParam('catchment')
  currentCatchmentId;

  constructor() {
    super();
    this._customSerieVisibility = {};
    this._dataGridCols = [];
    this._dataGridData = [];
    this._gaps = {};
    this._init = false;
    this._fromTime = 0;
    this._toTime = 0;
    this._qualityBarValues = {};
    this._serieDefinition = {};
    this._tableValues = {};
    this._constraints = {};
    this._timeseries = [];
    this._tsPaths = [];
    this.colors = this.colors || null;;
    this.showAlarms = this.showAlarms || 'true';
    this.showIndicators = this.showIndicators || 'true';
    this.isMultiGraph = this.isMultiGraph || false;
    this._idProperty = 'ts_id';
    this._tsPathProperty = 'ts_path';
    this.filteredStationList = this.filteredStationList || [];
    this.dataPersistence = true;
    this.downloadItems = this.downloadItems || [
      {
        label: 'CSV',
        value: 'csv',
      },
      {
        label: 'XLSX',
        value: 'xlsx',
      },
      {
        label: 'JSON',
        value: 'dajson',
      },
      {
        label: this.i18n.t('plain_text'),
        value: 'ascii',
      },
      {
        label: 'PNG',
        value: 'png',
      },
    ];
    this.defaultPeriod = '';
    this.defaultPeriodCoverage = '';
    this.multiGraphLabel ="${station_no} / ${station_name}";
    this.headerLabel = "${station_no} | ${station_name}";
    this.expiryDate = 'PT1H';
    this.flexibleAggregations = this.flexibleAggregations || [
      {
        timeRange: 'P3W',
        // eslint-disable-next-line no-template-curly-in-string
        ts_path: '${site_no}/${station_no}/${stationparameter_no}/h.Mean',
      },
      {
        timeRange: 'P3M',
        // eslint-disable-next-line no-template-curly-in-string
        min_ts_path: '${site_no}/${station_no}/${stationparameter_no}/Day.Min',
        // eslint-disable-next-line no-template-curly-in-string
        max_ts_path: '${site_no}/${station_no}/${stationparameter_no}/Day.Max',
      },
      {
        timeRange: 'P5Y',
        // eslint-disable-next-line no-template-curly-in-string
        min_ts_path: '${site_no}/${station_no}/${stationparameter_no}/Month.Min',
        // eslint-disable-next-line no-template-curly-in-string
        max_ts_path: '${site_no}/${station_no}/${stationparameter_no}/Month.Max',
      },
      {
        timeRange: 'P50Y',
        // eslint-disable-next-line no-template-curly-in-string
        min_ts_path: '${site_no}/${station_no}/${stationparameter_no}/Year.Min',
        // eslint-disable-next-line no-template-curly-in-string
        max_ts_path: '${site_no}/${station_no}/${stationparameter_no}/Year.Max',
      },
    ];
    this.graphConfig = {};
    this.graphName = '';
    this.kiwisUrl = '';
    this.maxNumPoints = MAX_NUM_POINTS;
    this.fromParam = 'from';
    this.toParam = 'to';
    this.qualityCodes = [];
    this.resolutionParam = 'ts_spacing';
    this.showGraphConfig = true;
    this.transformations = DEFAULT_TRANSFORMATIONS;
    this.zones = {};
    this.periods = [];
    this.zoomEnabled = null;
    this.graphHeaderTitle = this.graphHeaderTitle || null;
    this.hideConfigure = false;
    this.hideQbar = false;
    this.dateFormat = 'L LT';

    this._changeDataEvent = event => {
      if (event && event.detail) {
        this._fromTime = event.detail.fromTime;
        this._toTime = event.detail.toTime;
        this._savePeriodInLocalStorage();
        this._createDataRequest(this._tsPaths, event.detail);
      }
    };

    this._periodSelectedEvent = event => {
      if (event && event.detail) {
        const _fromEvt = new Date(event.detail.from).getTime();
        const _toEvt = new Date(event.detail.to).getTime();
        this._fromTime = moment(_fromEvt);
        this._toTime = moment(_toEvt);
        this._savePeriodInLocalStorage();
        this._createDataRequest(this._tsPaths, event.detail);
      }
    };

    const _t = this;
    this._manageShowHide = event => {
      if (event && event.detail && event.detail.target && event.detail.target.options) {
        const _options = event.detail.target.options;
        _t._customSerieVisibility[_options.id] = _options.visible;
      }
    };
  }

  static get properties() {
    return {
      alarms: { type: Array },
      defaultPeriod: { type: String },
      defaultPeriodCoverage: { type: String },
      flexibleAggregations: { type: Array },
      colors: { type: Array },
      graphConfig: { type: Object },
      graphName: { type: String },
      indicators: { type: Array },
      kiwisUrl: { type: String },
      levels: { type: Array },
      qualityCodes: { type: Array },
      currentStationId: { type: String },
      referenceFloods: { type: Array },
      showGraphConfig: { type: Boolean },
      showAlarms: { type: String },
      showIndicators: { type: String },
      transformations: { type: Array },
      tsPath: { type: Array },
      periods: { type: Array },
      zoomEnabled: { type: Boolean },
      zones: { type: Object },
      _constraints: { type: Object },
      dateFormat: { type: String },
      hideConfigure: { type: Boolean }, //use to hide qualitybar,table and graph configuration in some condition.
      hideQbar: { type: Boolean },
    };
  }

  render() {
    const chart = this.renderRoot.querySelector('#chart');
    let margins = {};
    if (chart && chart._chart) {
      margins = {
        marginLeft: chart._chart.margin[3], // 0-top, 1-right, 2-bottom, 3-left.
        marginRight: chart._chart.margin[1], // 0-top, 1-right, 2-bottom, 3-left.
      };
    }
    const firstTsId = this._tsPaths && this._tsPaths[0] ? this._tsPaths[0][this._idProperty] : '';
    const _qualities = this._qualityBarValues && this._qualityBarValues[firstTsId] ? this._qualityBarValues[firstTsId] : [];
    this._tsPaths && this._replaceGraphConfigPlaceholders(this._tsPaths[0]);
    this._setVisibilityQualityBar();

    // language=html
    return html`
      <div class="header">
        <div class="leftHeader">
           ${this._setHeaderTitle()}
        </div>
        <div id="kiGraphConfiguration" class="${this.hideConfigure ? 'hidden' : ''}"></div>

        <ki-time-range-picker
          id="timeRangePicker"
          .from="${this._fromTime}"
          .to="${this._toTime}"
          .constraints="${this._constraints}"
          showPeriodList="true"
          .periods="${this.periods}"
        ></ki-time-range-picker>
        <div class="rightHeader">

          <ki-icon-btn id="download-btn" title="${this.i18n.t('download')}" icon="ki ki-arrow-alt-to-bottom" @click="${this.downloadData}"></ki-icon-btn>
          <ki-popup for="download-btn" top="40px" left="0" id="download-popup">
            <ki-list>
              ${this.downloadItems.map(downloadItem => {
                return html`<ki-list-item @click="${() => this.clickDownloadItem(downloadItem.value)}"> ${downloadItem.label} </ki-list-item>`;
              })}
            </ki-list>
          </ki-popup>

          <ki-icon-btn title="${this.i18n.t('showHideQualityBar')}" class="${this.hideConfigure || this.hideQbar ? 'hidden' : ''}" id="quality-btn" icon="ki ki-quality-bars" @click="${this.toggleQualityBar}"></ki-icon-btn>

          <ki-icon-btn title="${this.i18n.t('showHideTable')}"  id="table-btn" icon="ki ki-table" @click="${this.toggleTable}"></ki-icon-btn>

          <ki-icon-btn id="expand-btn" title="${this.i18n.t('viewFullScreen')}" icon="ki ki-expand" @click="${this.fullScreen}"></ki-icon-btn>

          <ki-icon-btn id="close-btn" title="${this.i18n.t('closeView')}" icon="ki ki-times" @click="${this.closeElement}"></ki-icon-btn>
        </div>
      </div>
      <div class="containerNode">
        ${this._renderLoader()}
        <div class="graphWrapper">
          <ki-chart
            id="chart"
            .from="${this._fromTime}"
            .to="${this._toTime}"
            .timeseries="${this._timeseries}"
            .levels="${this.levels}"
            .gaps="${this._gaps}"
            .gridData="${this._dataGridData}"
            .graphConfig="${this.graphConfig}"
            .zones="${this.zones}"
            .colors="${this.colors}"
            .isMultiGraph="${this.isMultiGraph}"
            .zoomEnabled="${this.zoomEnabled}",
            @contextmenu="${this.previousPeriod}"
          ></ki-chart>
          <ki-quality-bar
            id="qualityBar"
            style="${this.hideConfigure || this.hideQbar ? 'display:none' : ''}"
            .margins="${margins}"
            .from="${this._fromTime}"
            .to="${this._toTime}"
            .qualities="${_qualities}"
            .qualityCodes="${this.qualityCodes}"
          ></ki-quality-bar>
        </div>
        <div class="handler hidden"></div>
        <div id="tableWrapper" class="tableWrapper hidden">
          <ki-timeseries-table id="dataGrid" .columns="${this._dataGridCols}" .data="${this._dataGridData}"></ki-timeseries-table>
        </div>
      </div>
    `;
  }
  prevStation() {
    let currentIndex = this.stationSelectList.findIndex(item => item.ts_path.includes(this.currentStationId));
    currentIndex -= 1;
    if(currentIndex< 0){
      currentIndex = this.stationSelectList.length -1;
    }
    const topic = this.currentItem.item.parametertype_name === "H" || this.currentItem.item.parametertype_name === "Q" ?  `${this.stationSelectList[currentIndex].stationparameter_no}-hr` : null;
 
    this.dispatchEvent(
      new CustomEvent('selectstation', {
        bubbles: true,
        composed: true,          
        detail: {
          station: this.stationSelectList[currentIndex],
          topic
        }
      }),
    );
  }

  nextStation() {
    let currentIndex = this.stationSelectList.findIndex(item => item.ts_path.includes(this.currentStationId));
    currentIndex += 1;
    if(currentIndex=== this.stationSelectList.length){
      currentIndex = 0;
    }
    const topic = this.currentItem.item.parametertype_name === "H" || this.currentItem.item.parametertype_name === "Q" ?  `${this.stationSelectList[currentIndex].stationparameter_no}-hr` : null;
    this.dispatchEvent(
      new CustomEvent('selectstation', {
        bubbles: true,
        composed: true,          
        detail: {
          station: this.stationSelectList[currentIndex],
          topic
        }
      }),
    );
  }
  previousPeriod(e) {
    e.preventDefault();
    this.dispatchEvent(
      new CustomEvent('gotopreviousselection', {
        bubbles: true,
        composed: true,
      }),
    );
  }
  firstUpdated() {
    const _t = this;
    this._addEvents();
    this.promiseLoader(
      Promise.all(this.getTsPaths())
        .then(([list, stationlist]) => {
          _t._stationlist = keyBy(stationlist, "station_id");
          _t._tsPaths = list;
          if (_t._tsPaths && _t._tsPaths.length === 0) {
            KiToast.showToast({ type: 'error', content: _t.i18n.t('noTSDetected') });
          }
          _t._init = true;
          _t._setFirstDates();
          _t._createGraphConfigurationNode();
          _t._createDataRequest(_t._tsPaths, { detail: false });
        })
        .catch(() => {
          KiToast.showToast({ type: 'error', content: 'Request Failed' });
        }),
    );
  }

  updated() {
    const _t = this;
    const chart = this.renderRoot.querySelector('#chart');
    if (chart && chart._chart) {
      chart.updateComplete.then(() => {
        _t._updateTooltipFormatter(chart);
      });
    }
  }

  _addEvents() {
    const _t = this;
    const timeRangePicker = this.renderRoot.querySelector('#timeRangePicker');
    timeRangePicker.addEventListener('changedate', (event) => {
      if (event && event.detail) {
        this._fromTime = event.detail.fromTime;
        this._toTime = event.detail.toTime;
        this._savePeriodInLocalStorage();
        this._createDataRequest(this._tsPaths, event.detail);
      }});
    this.renderRoot.querySelector('#chart').addEventListener('periodselected', this._periodSelectedEvent);

    const handler = this.renderRoot.querySelector('.handler');
    if (handler) {
      const wrapper = this.renderRoot.querySelector('.containerNode');
      const graphWrapper = this.renderRoot.querySelector('.graphWrapper');
      const tableWrapper = this.renderRoot.querySelector('.tableWrapper');
      let isHandlerDragging = false;
      handler.addEventListener('mousedown', e => {
        if (e.target === handler) {
          isHandlerDragging = true;
        }
      });

      this._mousemoveEvent = e  => {
        if (!isHandlerDragging) {
          return false;
        }
        const containerOffsetLeft = wrapper.offsetLeft || e.target.clientWidth - wrapper.clientWidth;
        const pointerRelativeXpos = e.clientX - containerOffsetLeft;
        const boxAminWidth = 60;

        tableWrapper.style.flex = 1;
        graphWrapper.style.width = `${Math.max(boxAminWidth, pointerRelativeXpos - 8)}px`;
        graphWrapper.style.flexGrow = 0;
        _t.renderRoot.querySelector('#chart')._chart.reflow();
        return true;
      };
      this.addEventListener('mousemove', this._mousemoveEvent);

      this._mouseupEvent = e  => () => {
        isHandlerDragging = false;
      };
      this.addEventListener('mouseup', this._mouseupEvent);
    }
  }

  _createGraphConfigurationNode() {
    if (this.showGraphConfig === false) {
      return;
    }
    const _t = this;
    const firstTs = this._tsPaths && this._tsPaths[0] ? this._tsPaths[0] : {};
    const firstTsPath = firstTs ? firstTs[this._tsPathProperty] : '';
    const graphConfigNode = this.renderRoot.querySelector('#kiGraphConfiguration');

    this._addalarmEvent = e => {
      if (!e || !e.detail || !e.detail.values || !firstTsPath) {
        return;
      }
      if (!_t.levels) {
        _t.levels = [];
      }
      if (e.detail.values.isSelected) {
        e.detail.values.valuetype = e.detail.values.valuetype || 'PoR';
        _t.levels.push(e.detail.values);
      } else {
        _t.levels = _t.levels.filter(level => level.tsId !== e.detail.values.tsId);
        _t.renderRoot.querySelector('#chart')._chart.yAxis.forEach(yAxis => {
          yAxis.removePlotLine(e.detail.values.tsId);
        });
      }
      _t._setLevelsTsData();
    };

    this._addindicatorEvent = e => {
      if (!e || !e.detail || !e.detail.values || !firstTsPath) {
        return;
      }
      if (!_t.levels) {
        _t.levels = [];
      }
      if (e.detail.values.isSelected) {
        _t.levels.push(e.detail.values);
      } else {
        _t.levels = _t.levels.filter(level => level.tsId !== e.detail.values.tsId);
        _t.renderRoot.querySelector('#chart')._chart.yAxis.forEach(yAxis => {
          yAxis.removePlotLine(e.detail.values.tsId);
        });
      }
      _t._setLevelsTsData();
    };
    const navBar = new KiGraphConfiguration({
      alarms: this.alarms,
      kiwisUrl: this.kiwisUrl,
      tsPath: firstTsPath,
      timeserieObj: firstTs,
      from: this._fromTime,
      to: this._toTime,
      isMultiGraph: this.isMultiGraph,
      showIndicators: this.showIndicators,
      indicators: this.indicators,
      referenceFloods: this.referenceFloods,
      showAlarms: this.showAlarms,
      showYears: 'false',
    });
    graphConfigNode.appendChild(navBar);
    navBar.addEventListener('addalarm', this._addalarmEvent);
    navBar.addEventListener('addindicator', this._addindicatorEvent);
  
  }


  createEventFunctions(){
    const downloadBtn = this.renderRoot.querySelector('#download-btn');
    const downloadPopUp = this.renderRoot.querySelector('#download-popup');

    this._clickEvent = e => {
      if (downloadBtn && downloadBtn.classList && downloadPopUp && downloadPopUp.classList) {
        if (downloadPopUp.classList.contains('visible')) {
          downloadBtn.classList.add('selected');
        } else {
          downloadBtn.classList.remove('selected');
        }
      }
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.createEventFunctions()

    this.addEventListener('showts', this._manageShowHide);
    this.addEventListener('hidets', this._manageShowHide);
    this.addEventListener('click', this._clickEvent);

  }

  disconnectedCallback() {
    this.removeEventListener('showts', this._manageShowHide);
    this.removeEventListener('hidets', this._manageShowHide);
    this.removeEventListener('click', this._clickEvent);
    this.removeEventListener('addalarm', this._addalarmEvent);
    this.removeEventListener('addindicator', this._addindicatorEvent);
    this._mousemoveEvent && this.removeEventListener('mousemove', this._mousemoveEvent);
    this._mouseupEvent && this.removeEventListener('mouseup', this._mouseupEvent);
    super.disconnectedCallback();
  }

  _calculateAgg(item) {
    this.flexibleAggregations.sort((a, b) => {
      return moment.duration(b.timeRange, 's').asMilliseconds() - moment.duration(a.timeRange, 's').asMilliseconds();
    });
    const minMaxObj = this._getRequestableDatesByTS(item);
    const flexibleAgg = find(this.flexibleAggregations, flexAgg => {
      const flexAggPeriod = moment.duration(flexAgg.timeRange, 's').asMilliseconds();
      const currentPeriod = minMaxObj.to.valueOf() - minMaxObj.from.valueOf();
      return flexAggPeriod <= currentPeriod;
    });
    return flexibleAgg || null;
  }

  _createDataRequest(tsPaths, detail) {
    const _t = this;
    const promises = [];
    document.dispatchEvent(
      new CustomEvent('datadateupdate', {
        bubbles: true,
        detail: {
          from: this._fromTime,
          to: this._toTime,
          ignoreHistory: detail.ignoreHistory,
        },
      }),
    );
    tsPaths.forEach(item => {
      _t._insertSerieDefinition(item);
      promises.push(_t._getTsData(item));
    });
    this._timeseries = [];

    this.promiseLoader(
      Promise.all(promises).then(
        promisesData => {
          promisesData.forEach(promData => {
            const tsData = promData && promData.length > 1 ? _t._mergeMinMaxTimeseries(promData[0], promData[1]) : promData[0];
            if (tsData) {
              const tsObj = _t._createTimeseriesObj(cloneDeep(tsData));
              _t._timeseries.push(tsObj);
            }
          });
          _t._setLevelsTsData();
        },
        () => {
          // console.log('Error tsData');
        },
      ),
    );
  }

  _createTimeseriesData(item) {
    const chartType =
      this._serieDefinition[item[this._idProperty]] && this._serieDefinition[item[this._idProperty]].chartConfig ? this._serieDefinition[item[this._idProperty]].chartConfig.type : 'line';
    let mySeries = this._getSeries(item, chartType);
    if (mySeries && mySeries.length > 0) {
      mySeries = this._fillGapsWithNull(item, mySeries);
    }
    return mySeries;
  }

  _createTimeseriesObj(item) {
    const _t = this;
    const origTs = this._tsPaths[0];
    item.ts_idReal = item.ts_id;
    item.ts_pathReal = item.ts_path;
    item.ts_id = origTs.ts_id;
    if(!item.transformation){
      item.ts_path = origTs.ts_path;
    }

    this.currentItem = item;
 
    const originalChartConf = this.tsPath.find(ts => {
      return ts[_t._tsPathProperty] === item[_t._tsPathProperty] || ts[_t._idProperty] === item[_t._idProperty];
    });
    const additionalChartConfig = this._serieDefinition[item[this._idProperty]] ? this._serieDefinition[item[this._idProperty]].chartConfig : {};
    const moreConfig = this._serieDefinition[item[this._idProperty]] || {};
    const tsName = this.tsPath.filter(tsdef=> tsdef.ts_path === item.ts_pathReal)[0]?.name || originalChartConf.name || item.ts_name;
    const customName = this.isMultiGraph ? { name: this.multiGraphLabel ? template(this.multiGraphLabel, item) :`${item.station_no} / ${item.station_name}` } :  { name: `${tsName}` };
    return Object.assign(item, additionalChartConfig, originalChartConf, moreConfig, customName, {
      data: this._createTimeseriesData(item)
    });
  }

  _fillGapsWithNull(ts, mySeries) {
    // TODO: insert this logic inside previous loop (_getSeries)
    const _t = this;
    const id = ts[this._idProperty];
    const resolution = ts[this.resolutionParam];
    const isEquidistant = Utils.isEquidistant(resolution);
    let auxSerie = [];
    let baseTimeStamp = mySeries && mySeries[0] ? moment(mySeries[0][0]) : moment();
    const nextTimeStamp = mySeries && mySeries[1] ? mySeries[1][0] : moment();
    const chartType = this._serieDefinition[id].chartConfig.type;
    let firstNull = null;
    let lastNull = null;
    this._gaps[id] = [];
    const millisecondsPeriod = Utils.getMillisecondsPeriod(resolution, baseTimeStamp, nextTimeStamp);
    if (!millisecondsPeriod) {
      return mySeries;
    }
    mySeries.forEach((item, index) => {
      const currTimeStamp = item[0];
      const currValue = item[1];
      let prevTimeStamp;
      if (baseTimeStamp > currTimeStamp + millisecondsPeriod) {
        let _baseTimeStamp = Object.assign({}, currTimeStamp);
        prevTimeStamp = moment(_baseTimeStamp).subtract(millisecondsPeriod).valueOf();
        baseTimeStamp = moment(_baseTimeStamp).add(millisecondsPeriod).valueOf();
      } else {
        prevTimeStamp = moment(baseTimeStamp).subtract(millisecondsPeriod).valueOf();
        baseTimeStamp = moment(baseTimeStamp).add(millisecondsPeriod).valueOf();
      }

      if (chartType === 'line' && isEquidistant) {
        let existGap = false;
        while (baseTimeStamp < currTimeStamp) {
          // Here exists a GAP
          existGap = true;
          baseTimeStamp = moment(baseTimeStamp).add(millisecondsPeriod).valueOf();
          auxSerie.push([baseTimeStamp, null]);
        }
        if (existGap) {
          var _from = mySeries && mySeries[index - 1] ? moment(mySeries[index - 1][0]).valueOf() : prevTimeStamp;
          _t._gaps[id].push({ from: _from, to: currTimeStamp });
        }
      }

      auxSerie.push(item);
      if (currValue === null && !firstNull) {
        firstNull = mySeries && mySeries[index - 1] ? moment(mySeries[index - 1][0]).valueOf() : prevTimeStamp;
      } else if (currValue !== null && firstNull) {
        lastNull = currTimeStamp;
      }

      if (firstNull && (lastNull || index === mySeries.length - 1)) {
        _t._gaps[id].push({ from: firstNull, to: lastNull || currTimeStamp });
        firstNull = null;
        lastNull = null;
      }
    });
    auxSerie = auxSerie.sort((a, b) => {
      return a[0] - b[0];
    });
    return auxSerie;
  }

  _getColumnsGrid() {
    const _t = this;
    const columns = [
      {
        field: 'timestamp',
        label: this.i18n.t('time_stamp'),
        type: 'date',
      },
    ];
    this._tsPaths.forEach(ts => {
      const originalChartConf = _t.tsPath.find(tsOriginal => {
        return tsOriginal[_t._tsPathProperty] === ts[_t._tsPathProperty] || tsOriginal[_t._idProperty] === ts[_t._idProperty];
      });
      const chartType = _t._serieDefinition[ts[_t._idProperty]] && _t._serieDefinition[ts[_t._idProperty]].chartConfig ? _t._serieDefinition[ts[_t._idProperty]].chartConfig.type : 'line';
      const colObj = {
        id: ts[_t._idProperty],
        field: ts[_t._idProperty],
        label: originalChartConf && originalChartConf.name ? originalChartConf.name : ts.ts_name,
        format: chartType === 'line' ? 'number' : '',
      };
      columns.push(colObj);
    });
    return columns;
  }

  _getColorAndTextByCode(code) {
    if (!this.qualityCodes || this.qualityCodes.length === 0) {
      this.qualityCodes = KiQualityBar.getDefaultQualities();
    }
    return this.qualityCodes.find(qCode => qCode.key === code || qCode.code === code);
  }

  _getDataGrid() {
    const _t = this;
    const args = [];
    forEach(this._tableValues, (timeseries, tsId) => {
      const json = [];
      forEach(timeseries, values => {
        if (values.timestamp >= _t._fromTime.valueOf() && values.timestamp <= _t._toTime.valueOf()) {
          const temp = {};
          temp.id = values.timestamp;
          temp.timestamp = moment(values.timestamp).format(_t.dateFormat);
          temp[tsId] = values.value;
          temp[`quality_${tsId}`] = values.quality;
          temp[`qualityCode_${tsId}`] = values.qualityCode;
          temp[`remarks_${tsId}`] = values.remark;
          json.push(temp);
        }
      });
      args.push(json);
    });
    let toSort = Utils.joinObjects.apply(Utils.joinObjects, args); // Pass all elements in args as parameter list
    toSort = toSort.sort((a, b) => {
      return a.id - b.id;
    });
    return toSort;
  }

  _getMinMaxDatesPerTS(item) {
    let min = null;
    let max = null;
    if (Utils.checkValidParams(item, [this.fromParam, this.toParam])) {
      if (!min || min > moment(item[this.fromParam]).valueOf()) {
        min = moment(item[this.fromParam]).valueOf();
      }
      if (!max || max < moment(item[this.toParam]).valueOf()) {
        max = moment(item[this.toParam]).valueOf();
      }
    } else {
      const defaultPeriod = 'P1M';
      const period = Utils.calcPeriod(defaultPeriod);
      if (!min || min > period.from) {
        min = period.from;
      }
      if (!max || max < period.to) {
        max = period.to;
      }
    }
    this._constraints = {
      min: moment(min),
      max: moment(max),
    };
    return this._constraints;
  }

  _getRequestableDatesByTS(item) {
    const minMaxObj = this._getMinMaxDatesPerTS(item);
    let auxFrom = minMaxObj.min.valueOf() < this._fromTime.valueOf() ? this._fromTime.valueOf() : minMaxObj.min.valueOf();
    let auxTo = minMaxObj.max.valueOf() > this._toTime.valueOf() ? this._toTime.valueOf() : minMaxObj.max.valueOf();
    if (
      (this._fromTime.valueOf() > minMaxObj.min.valueOf() && this._fromTime.valueOf() > minMaxObj.max.valueOf()) ||
      (this._toTime.valueOf() < minMaxObj.min.valueOf() && this._toTime.valueOf() < minMaxObj.max.valueOf())
    ) {
      auxFrom = this._fromTime.valueOf();
      auxTo = this._toTime.valueOf();
    }
    return {
      from: auxFrom,
      to: auxTo,
    };
  }

  _getSeries(data, chartType) {
    if (!data || !data.data) {
      return [];
    }
    const _t = this;
    const mySeries = [];
    const id = data.ts_idReal ? data.ts_idReal.split(';')[0] : data.ts_id.split(';')[0]; // In KiWIS we are retrieving something like id = "37203042;aggregate(1:0:0|min|max)"
    const dataColumns = data && data.columns ? data.columns.toLowerCase().split(',') : [];
    const valueIndex = dataColumns.indexOf('minimum') === -1 ? dataColumns.indexOf('value') : dataColumns.indexOf('minimum');
    const valueInterpolationType = dataColumns.indexOf('interpolation type') === -1 ? valueIndex : dataColumns.indexOf('interpolation type');
    const timestampIndex = dataColumns.indexOf('timestamp');
    const valueMaxIndex = dataColumns.indexOf('maximum') === -1 ? valueIndex : dataColumns.indexOf('maximum');
    const valueQualityIndex = dataColumns.indexOf('quality code name') === -1 ? dataColumns.indexOf('quality code') : dataColumns.indexOf('quality code name');
    const valueQualityCodeIndex = dataColumns.indexOf('quality code');
    const valueRemarkIndex = dataColumns.indexOf('data comment');

    if (data.data[0] && data.data[0][valueInterpolationType]) {
      const interpolationType = this.graphConfig.forceInterpolation || data.data[0][valueInterpolationType];
      Utils.insertInterpolationType(this._serieDefinition[id], interpolationType, chartType);
    }

    this._qualityBarValues[id] = [];
    if(!data.valuetype){
      this._tableValues[id] = {};
    }

    data.data.forEach(collect => {
      const currTimeStamp = collect[timestampIndex];
      const currValue = collect[valueIndex];
      const currValuesObj = chartType === 'line' || chartType === 'column' ? [currTimeStamp, currValue] : [currTimeStamp, currValue, collect[valueMaxIndex]];
      mySeries.push(currValuesObj);
      _t._qualityBarValues[id].push({
        timestamp: currTimeStamp,
        code: collect[valueQualityCodeIndex],
      });
      if (currValue !== null && !data.valuetype) {
        _t._tableValues[id][currTimeStamp] = {
          id: currTimeStamp,
          timestamp: currTimeStamp,
          quality: isNull(collect[valueQualityIndex]) || isUndefined(collect[valueQualityIndex]) ? '' : collect[valueQualityIndex],
          qualityCode: isNull(collect[valueQualityCodeIndex]) || isUndefined(collect[valueQualityCodeIndex]) ? '' : collect[valueQualityCodeIndex],
          remark: collect[valueRemarkIndex] || '',
          value: chartType === 'line' ? formatNumber(currValue) : `${formatNumber(currValue)} \u2014 ${formatNumber(collect[valueMaxIndex])}`,
        };
      }
    });
    return mySeries;
  }

  roundMoment(date, duration, method) {
    return moment(Math[method]((+date) / (+duration)) * (+duration)); 
}

  _getTsData(item) {
    const _t = this;
    const addition = this._serieDefinition && this._serieDefinition[item[this._idProperty]] && item ? Utils.getAddition(this._serieDefinition[item[this._idProperty]]) : 0;
    const requestDates = this._getRequestableDatesByTS(item);
    const auxFrom = moment(requestDates.from).subtract(addition, 'ms');
    const auxTo = moment(requestDates.to).add(addition, 'ms');
    const returnfields = ['Timestamp,Value,Interpolation Type,Quality Code,Quality Code Name']
    const reqObj = {
      maxquality: 206,
      forceCacheHeaderTime: CACHE_HEADER_TIME,
      valuesasstring: false,
      ts_path: item[this._tsPathProperty],
      metadata: true,
      md_returnfields:
        'ts_id, ts_path, ts_spacing, ts_name, ts_shortname, station_no, station_id, station_latitude, station_longitude, station_name, ts_unitname, ts_unitsymbol, stationparameter_name, ca_sta',
        'ca_sta_returnfields': ["ISIN_LOCALITE"],
      dateformat: 'UNIX', // Dates returned in ms -> best option for highcharts
    };
    if (item.valuetype === 'PoR') {
      reqObj.period = 'complete'
    }else if (item.valuetype === 'eventlist' || item.valuetype === 'aggregation') {
      reqObj.period = 'complete'
    }else if (false && item.valuetype === 'LTV') {
      reqObj.period = 'P1Y';
      reqObj.to = moment(item.to).add(-1, "year").format("YYYY")
    }else{
      reqObj.from = this.roundMoment(auxFrom, moment.duration("PT5M"), "ceil").toISOString();
      reqObj.to = auxTo.toISOString();
    }
    const originalChartConf = this.tsPath.find(ts => {
      return ts[_t._tsPathProperty] === item[_t._tsPathProperty] || ts[_t._idProperty] === item[_t._idProperty];
    });
    if (originalChartConf && originalChartConf.forceTransformation) {
      reqObj.ts_path += ';' + originalChartConf.forceTransformation;
    }
    if(this._serieDefinition[item[this._idProperty]] &&
      this._serieDefinition[item[this._idProperty]].aggregation&&
      this._serieDefinition[item[this._idProperty]].aggregation.ts_path){
        reqObj.ts_path = template(this._serieDefinition[item[this._idProperty]].aggregation.ts_path, item);
    }else if (
      this._serieDefinition[item[this._idProperty]] &&
      this._serieDefinition[item[this._idProperty]].aggregation &&
      this._serieDefinition[item[this._idProperty]].aggregation.max_ts_path &&
      originalChartConf &&
      originalChartConf.type !== 'column' &&
      originalChartConf.type !== 'line'
    ) {
      const maxTsPath = this._serieDefinition[item[this._idProperty]].aggregation.max_ts_path;
      const minTsPath = this._serieDefinition[item[this._idProperty]].aggregation.min_ts_path;
      const minMaxObjTsPaths = Utils.getMinMaxTsPaths(item[this._tsPathProperty], minTsPath, maxTsPath);
      reqObj.ts_path = `${minMaxObjTsPaths.minTsPath},${minMaxObjTsPaths.maxTsPath}`;
      return new KIWIS({ basePath: this.kiwisUrl }).getTimeseriesValues(reqObj, returnfields, 'json');
    }
    return new KIWIS({ basePath: this.kiwisUrl }).getTimeseriesValues(reqObj, returnfields, 'json');
  }

  _isSameStation() {
    return every(this._tsPaths, { station_no: this._tsPaths[0].station_no });
  }

  _insertLevelDataExtremes(data, levelFrom, levelTo) {
    if (data && !isEmpty(data)) {
      const firstValue = data[0];
      const lastValue = data[data.length - 1];
      if (levelFrom && moment(levelFrom).valueOf() < firstValue[0]) {
        data.unshift([moment(this._fromTime.valueOf()).subtract(1, 'day').valueOf(), firstValue[1]]);
      }
      if (levelTo && moment(levelTo).valueOf() > lastValue[0]) {
        data.push([moment(this._toTime.valueOf()).add(1, 'day').valueOf(), lastValue[1]]);
      }
    }
  }

  _insertSerieDefinition(_item) {
    const _t = this;
    const originalChartConf = this.tsPath.find(ts => {
      return ts[_t._tsPathProperty] === _item[_t._tsPathProperty] || ts[_t._idProperty] === _item[_t._idProperty];
    });
    const _isEquidistant = Utils.isEquidistant(_item[this.resolutionParam]);
    const _aggregation = this._calculateAgg(_item);
    const chartType = originalChartConf && originalChartConf.type ? originalChartConf.type : Utils.getChartType(_aggregation);
    const _visible =
      this._customSerieVisibility[_item[this._idProperty]] !== null || this._customSerieVisibility[_item[this._idProperty]] !== undefined ? this._customSerieVisibility[_item[this._idProperty]] : true;
    this._serieDefinition[_item[this._idProperty]] = {
      aggregation: _aggregation,
      chartConfig: { type: chartType },
      isMultiGraph: this.isMultiGraph,
      isEquidistant: _isEquidistant,
      item: _item,
      resolution: _item[this.resolutionParam],
      visible: _visible,
    };
  }

  _mergeMinMaxTimeseries(minTs, maxTs) {
    const _t = this;
    const timeserie = find(this._serieDefinition, serieDef => {
      const minMaxObjTsPaths = Utils.getMinMaxTsPaths(serieDef.item[_t._tsPathProperty], serieDef.aggregation.min_ts_path, serieDef.aggregation.max_ts_path);
      return (
        serieDef.aggregation &&
        (minMaxObjTsPaths.maxTsPath === maxTs[_t._tsPathProperty] || minMaxObjTsPaths.maxTsPath === minTs[_t._tsPathProperty]) &&
        (minMaxObjTsPaths.minTsPath === maxTs[_t._tsPathProperty] || minMaxObjTsPaths.minTsPath === minTs[_t._tsPathProperty])
      );
    });
    const returnObj = { ...timeserie.item };
    const returnArray = [];
    const dataColumns = minTs.columns.toLowerCase().split(',');
    const timestampIndex = dataColumns.indexOf('timestamp');
    const valueIndex = dataColumns.indexOf('minimum') === -1 ? dataColumns.indexOf('value') : dataColumns.indexOf('minimum');
    minTs.data.forEach(minTsValue => {
      const maxTsValue = find(maxTs.data, max => max[timestampIndex] === minTsValue[timestampIndex]);
      if (maxTsValue) {
        const arr = minTsValue;
        arr.splice(2, 0, maxTsValue[valueIndex]);
        returnArray.push(arr);
      }
    });
    returnObj.columns = minTs.columns.toLowerCase().replace(',value,', ',minimum,maximum,');
    returnObj[this.resolutionParam] = minTs[this.resolutionParam];
    this._insertSerieDefinition(Object.assign({}, returnObj));
    returnObj.data = returnArray;
    return returnObj;
  }

  _reloadDataGrid() {
    this._dataGridCols = this._getColumnsGrid();
    this._dataGridData = this._getDataGrid();
  }

  _replaceGraphConfigPlaceholders(timeserie) {
    if (!timeserie) {
      return;
    }
    if (this.graphConfig && this.graphConfig.yAxis) {
      if (this.graphConfig.yAxis.softMax && !isNumber(this.graphConfig.yAxis.softMax)) {
        let valueToReplace = timeserie[this.graphConfig.yAxis.softMax.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')];
        if (valueToReplace) {
          valueToReplace = Number(valueToReplace.split(' ')[0]);
          this.graphConfig.yAxis.softMax = Number(this.graphConfig.yAxis.softMax.replace(this.graphConfig.yAxis.softMax, valueToReplace));
        }
      }
      if (this.graphConfig.yAxis.softMin && !isNumber(this.graphConfig.yAxis.softMin)) {
        let valueToReplace = timeserie[this.graphConfig.yAxis.softMin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')];
        if (valueToReplace) {
          valueToReplace = Number(valueToReplace.split(' ')[0]);
          this.graphConfig.yAxis.softMin = Number(this.graphConfig.yAxis.softMin.replace(this.graphConfig.yAxis.softMin, valueToReplace));
        }
      }
      if (this.graphConfig.yAxis.max && !isNumber(this.graphConfig.yAxis.max)) {
        let valueToReplace = timeserie[this.graphConfig.yAxis.max.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')];
        if (valueToReplace) {
          valueToReplace = Number(valueToReplace.split(' ')[0]);
          this.graphConfig.yAxis.max = Number(this.graphConfig.yAxis.max.replace(this.graphConfig.yAxis.max, valueToReplace));
        }
      }
      if (this.graphConfig.yAxis.min && !isNumber(this.graphConfig.yAxis.min)) {
        let valueToReplace = timeserie[this.graphConfig.yAxis.min.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')];
        if (valueToReplace) {
          valueToReplace = Number(valueToReplace.split(' ')[0]);
          this.graphConfig.yAxis.min = Number(this.graphConfig.yAxis.min.replace(this.graphConfig.yAxis.min, valueToReplace));
        }
      }
    }
  }

  _savePeriodInLocalStorage() {
    const timeRangePickerNode = this.renderRoot.querySelector('#timeRangePicker');
    if (timeRangePickerNode) {
      timeRangePickerNode.fromDate.setDate(new Date(this._fromTime.toISOString()));
      timeRangePickerNode.toDate.setDate(new Date(this._toTime.toISOString()));
    }
    if (!this.dataPersistence || !this._init) {
      return;
    }
    const localStorageObject = {
      from: this._fromTime.valueOf(),
      to: this._toTime.valueOf(),
      expiryDate: moment().add(moment.duration(this.expiryDate, 's').asSeconds(), 'second').format(),
    };
    localStorage.setItem('kichart-periodState', JSON.stringify(localStorageObject));
  }

  _getMaxTo(tsList){
    let max = moment(tsList[0].to);
    tsList.forEach(item => {
      if(moment(item.to)>max){
        max = moment(item.to)
      }
    })
    return max;
  }

  _setFirstDates() {
    const restoredObj = this.dataPersistence ? JSON.parse(localStorage.getItem('kichart-periodState')) : null;
    if (restoredObj && moment(restoredObj.expiryDate).format() > moment().format()) {
      this._fromTime = moment(restoredObj.from);
      this._toTime = moment(restoredObj.to);
    } else {
      let _from = this._fromTime || moment().subtract(1, 'week');
      let _to = this._toTime || moment();
      if (this.defaultPeriod) {
        _from = this.defaultPeriodMobile && this.clientWidth<801 ? moment().subtract(moment.duration(this.defaultPeriodMobile)) : moment().subtract(moment.duration(this.defaultPeriod));
        _to = moment().endOf("day");
      } else if (this.defaultPeriodCoverage && this._tsPaths && this._tsPaths[0]) { 
        _to = this._getMaxTo(this._tsPaths);
        _from = moment(_to).subtract(moment.duration(this.defaultPeriodCoverage));
      } else if (this._tsPaths && this._tsPaths[0] && this._tsPaths[0][this.fromParam] && this._tsPaths[0][this.toParam]) {
        _from = moment(this._tsPaths[0][this.fromParam]);
        _to = moment(this._tsPaths[0][this.toParam]);
      }
      this._fromTime = _from;
      this._toTime = _to;
    }
    const timeRangePickerNode = this.renderRoot.querySelector('#timeRangePicker');
    if (timeRangePickerNode) {
      timeRangePickerNode.fromDate.setDate(new Date(this._fromTime.toISOString()));
      timeRangePickerNode.toDate.setDate(new Date(this._toTime.toISOString()));
    }
  }

  _setHeaderTitle() {
    const tsPaths = this._tsPaths;
    if (!tsPaths || !this.currentItem) {
      return html``;
    }
    let _headerTitleText = '';
    const _stations = [];
    const _parameters = [];
    const _timeseries = [];
    tsPaths.forEach(ts => {
      if (_stations.indexOf(ts.station_name) === -1) {
        _stations.push(ts.station_name);
      }
      if (_parameters.indexOf(ts.stationparameter_name) === -1) {
        _parameters.push(ts.stationparameter_name);
      }
      if (_timeseries.indexOf(ts.ts_path) === -1) {
        _timeseries.push(ts.ts_path);
      }
    });
    if(this.graphHeaderTitle){
      _headerTitleText += `${this.graphHeaderTitle}`;
    }
    else if (tsPaths.length === 1) {
      const _ts = tsPaths[0] || {};
      _headerTitleText += template(this.headerLabel.replaceAll("§", "$"), this.currentItem);
    } else if (!_stations || _stations.length === 0 || !_parameters || _parameters.length === 0) {
      // No data
      _headerTitleText = '';
    } else {
      _headerTitleText += _stations.length === 1 ? _stations[0] : `${_stations.length} ${this.i18n.t('stations')}`;
      _headerTitleText += ' | ';
      _headerTitleText += _parameters.length === 1 ? this.graphName || _parameters[0] : `${_parameters.length} ${this.i18n.t('parameters')}`;
    }
    this.stationSelectList = [];
    this.filteredStationList.forEach(item => {
      this._stationlist[item.station_id] && this._stationlist[item.station_id].parametertype_name.split(";").includes(this.currentItem.item.parametertype_name) && this.stationSelectList.push(item);
    })
    if(this.currentCatchmentId){
      this.stationSelectList = this.stationSelectList.filter(item => item.BASSIN_INFOCRUE_NO === this.currentCatchmentId)
    }
    this.stationSelectList = orderBy(this.stationSelectList, "station_no")
    console.log("station list length ", this.stationSelectList.length)
    if(_stations.length ===1 && !this.isMultiGraph){
      return html`<div class="parameter-title">${ this.parameterDisplayName || _parameters[0]}</div><ki-icon class="navbutton" icon="ki ki-chevron-left"  @click="${this.prevStation}"></ki-icon>
        <vaadin-select @change="${this.__stationSelect}" class="stationselect" naturalMenuWidth value="${this.currentItem.station_id}">
          <template>
            <vaadin-list-box>
              ${this.stationSelectList.map(item =>  {
                return html`<vaadin-item value="${item.station_id}">${template(this.headerLabel.replaceAll("§", "$"), item)}</vaadin-item>` 
              })}
              </vaadin-list-box>
          </template>
      </vaadin-select>
      <ki-icon class="navbutton" icon="ki ki-chevron-right"  @click="${this.nextStation}"></ki-icon>`
    }else{
      return html`<span id="headerTitleText" title="${_headerTitleText}">${_headerTitleText}</span>`
    }
    
  }

  __stationSelect(event){
    const station = find(this.filteredStationList, {station_id: event.target.value});
    const topic = this.currentItem.item.parametertype_name === "H" || this.currentItem.item.parametertype_name === "Q"  ?  `${station.stationparameter_no}-hr` : null;
    if(station){
      this.dispatchEvent(
        new CustomEvent('selectstation', {
          bubbles: true,
          composed: true,
          detail: {
            station,
            topic
          }
        }),
      );
      this.currentStationId = `${station.site_no}/${station.station_no}`
    }
  }

  _setLevelsTsData() {
    const _t = this;
    const promises = [];
    this.levels &&
      this.levels.forEach(level => {
        if (level.type === 'ts') {
          level[_t._tsPathProperty] = level[_t._tsPathProperty] || level.tsPath || '';
          promises.push(_t._getTsData(level));
        }
      });
    if (isEmpty(promises)) {
      this._reloadDataGrid();
      this.requestUpdate();
      return;
    }
    this.promiseLoader(
      Promise.all(promises).then(
        data => {
          data.forEach(promData => {
            _t.levels.forEach(level => {
              if (level.type === 'ts' && level[_t._tsPathProperty] === promData[0][_t._tsPathProperty]) {
                level.unit = promData[0].ts_unitsymbol;
                level.data = promData[0].data || [];
                if(level.valuetype === 'PoR'){
                  level.data = level.data.filter(item => item[1])
                }
                level.value = level.data.length > 0 && level.valuetype === 'PoR' ? last(level.data)[1] : '';
             //   _t._insertLevelDataExtremes(level.data, level.from, level.to);
                level.columns = 'timestamp,value';
                _t._getSeries(level, 'line');
              }
            });
            _t._reloadDataGrid();
            _t.requestUpdate();
          });
        },
        () => {
          // console.log('Error tsData');
        },
      ),
    );
  }

  _setVisibilityQualityBar() {
    const qualityBarVisibleLS = localStorage.getItem(QUALITY_VISIBILITY_KEY);
    const qualityBarNode = this.renderRoot.querySelector('#qualityBar');
    if (qualityBarNode && !this.hideQbar) {
      qualityBarNode.style.display = qualityBarVisibleLS || 'flex';
      const classToHide = qualityBarVisibleLS === 'flex' ? 'empty' : 'selected';
      const classToShow = qualityBarVisibleLS === 'flex' ? 'selected' : 'empty';
      this.renderRoot.querySelector('#quality-btn').classList.remove(classToHide);
      this.renderRoot.querySelector('#quality-btn').classList.add(classToShow);
    }
  }

  _updateTooltipFormatter(chart) {
    const _t = this;
    const qualityBarNode = this.renderRoot.querySelector('#qualityBar');
    const absoluteOffset = this.graphConfig.absoluteOffset > 0 ? parseFloat(this.graphConfig.absoluteOffset) : null;
    chart._chart.update({
      tooltip: {
        formatter: event => {
          let aux = Utils.getTooltipFormatter(event, absoluteOffset, _t.dateFormat);
          if (!qualityBarNode || !qualityBarNode.style || qualityBarNode.style.display === 'none') {
            return aux;
          }
          if (!event.chart || !event.chart.hoverPoint || !event.chart.hoverPoint.x) {
            return aux;
          }
          _t._tsPaths.forEach(tsPath => {
            const tsId = tsPath[this._idProperty];
            const qualityCode = _t._tableValues[tsId] && _t._tableValues[tsId][event.chart.hoverPoint.x] ? _t._tableValues[tsId][event.chart.hoverPoint.x].qualityCode : '';
            if (qualityCode !== undefined && qualityCode !== null) {
              const qualityValue = this.i18n.t(`qcode${qualityCode}`) || '';
              const itemColorTextObj = _t._getColorAndTextByCode(qualityCode) || {};
              const nodeStyle = `background: ${itemColorTextObj.color};color:${KiQualityBar.pickTextColor(itemColorTextObj.color)};border-radius: 5px;box-sizing: border-box;padding: 0 5px;`;
              const strToSearch = `additionalInfo ${tsId}`;
              const additionalInfoPosition = aux.indexOf(strToSearch);
              if (additionalInfoPosition > -1) {
                const positionToAdd = additionalInfoPosition + strToSearch.length + 2;
                const content = `<br/>${this.i18n.t(`quality`)}&nbsp;&nbsp;<span style="${nodeStyle}">${qualityValue}</span><br/>`;
                aux = [aux.slice(0, positionToAdd), content, aux.slice(positionToAdd)].join('');
              }
            }
          });
          return aux.substring(0, aux.length - 5);
        },
      },
    });
  }

  closeElement() {
    this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  clickDownloadItem(format) {
    const _t = this;
    let _format = format;
    if (format === 'json') {
      _format = 'dajson';
    }
    let _mimeType = '';
    if (_format === 'dajson') {
      _mimeType = 'application/json';
    } else if (_format === 'xlsx') {
      _mimeType = 'application/pdf';
    } else if (_format === 'ascii') {
      _mimeType = 'text/plain';
    } else if (_format === 'csv') {
      _mimeType = 'text/csv';
    } else if (_format === 'png') {
      const chart = this.renderRoot.querySelector('#chart');
      if (chart) {
        const title = `${this.currentItem.station_no}_${this.currentItem.station_name}_${this.currentItem.item.stationparameter_name}`;
        chart._chart.exportChartLocal({
          format: _format,
          filename: `${title}-${moment().format('L LT')}`,
        });
      }
      return;
    }

    const iframe = document.createElement('iframe');
    iframe.setAttribute('style', 'display:none');
    document.body.appendChild(iframe);

    const tsIds = [];
    this._tsPaths.forEach(item => {
      tsIds.push(item[_t._idProperty]);
    });

    const reqObj = {
      metadata: true,
      ts_id: tsIds,
      format: _format,
      mimeType: _mimeType,
      from: _t._fromTime.toISOString(),
      to: _t._toTime.toISOString(),
      language: "fr",
      downloadaszip: true,
      downloadfilename: `${this.currentItem.station_no}_${this.currentItem.station_name}_${this.currentItem.item.stationparameter_name}`,
    };
    if (_t.delimiter) {
      reqObj.csvdiv = _t.delimiter;
    }
    iframe.src = new KIWIS({ basePath: this.kiwisUrl }).getUrl(reqObj);
    iframe.onload = () => {
      // Error
      KiToast.showToast({ type: 'error', content: _t.i18n.t('downloadFailedWithTooManyTs') });
      document.querySelectorAll(`ki-toast`).forEach(toast => {
        toast.style.zIndex = '9999';
      });
    };
  }

  downloadData() {
    const downloadBtn = this.renderRoot.querySelector('#download-btn');
    if (!downloadBtn) {
      return;
    }
    const classToHideBtn = downloadBtn.classList.contains('selected') ? 'selected' : 'empty';
    const classToShowBtn = downloadBtn.classList.contains('selected') ? 'empty' : 'selected';
    downloadBtn.classList.remove(classToHideBtn);
    downloadBtn.classList.add(classToShowBtn);
    if (classToShowBtn === 'selected') {
      this.renderRoot.querySelector('#download-popup').show({});
    } else {
      this.renderRoot.querySelector('#download-popup').hide({});
    }
  }

  fullScreen() {
    const iconBtn = this.renderRoot.querySelector('#expand-btn');
    if (!iconBtn) {
      return;
    }
    iconBtn.icon = iconBtn.icon === 'ki ki-expand' ? 'ki ki-compress' : 'ki ki-expand';
    this.dispatchEvent(
      new CustomEvent('full-screen', {
        bubbles: true,
        composed: true,
        detail: {
          fullScreen: iconBtn.icon === 'ki ki-compress',
        },
      }),
    );
  }

  getTsPaths() {
    const getStationList = this.currentStationId ? new KIWIS({ basePath: this.kiwisUrl }).getStationList({flatten:true}, ["station_id", "parametertype_name"]) : [];
    return [this.getTsPath(Utils.joinTsPaths(this.tsPath, this._tsPathProperty)), getStationList];
  }

  getTsPath(tsPath) {
    const returnfields = ['site_no','station_id','station_no', 'station_name', 'ts_name', 'parametertype_name', 'ts_unitsymbol', 'ts_id', 'coverage', 'ts_path', 'ts_spacing', 'stationparameter_name', 'stationparameter_no'];
    const params = { 
      ts_path: tsPath,
    };
    return new KIWIS({ basePath: this.kiwisUrl }).getTimeseriesList(params, returnfields);
  }

  toggleQualityBar() {
    const qualityBarNode = this.renderRoot.querySelector('#qualityBar');
    if (qualityBarNode) {
      if (qualityBarNode.style.display === 'none') {
        qualityBarNode.style.display = 'flex';
      } else {
        qualityBarNode.style.display = 'none';
      }
      localStorage.setItem(QUALITY_VISIBILITY_KEY, qualityBarNode.style.display);
      this.requestUpdate();
    }
  }

  toggleTable() {
    const classToHideBtn = this.renderRoot.querySelector('#table-btn').classList.contains('selected') ? 'selected' : 'empty';
    const classToShowBtn = this.renderRoot.querySelector('#table-btn').classList.contains('selected') ? 'empty' : 'selected';
    this.renderRoot.querySelector('#table-btn').classList.remove(classToHideBtn);
    this.renderRoot.querySelector('#table-btn').classList.add(classToShowBtn);
    this.renderRoot.querySelector('.graphWrapper').style.flexGrow = classToShowBtn === 'selected' ? 0 : 1;

    const classToHideNode = this.renderRoot.querySelector('#tableWrapper').classList.contains('hidden') ? 'hidden' : 'visible';
    const classToShowNode = this.renderRoot.querySelector('#tableWrapper').classList.contains('hidden') ? 'visible' : 'hidden';
    this.renderRoot.querySelector('#tableWrapper').classList.remove(classToHideNode);
    this.renderRoot.querySelector('#tableWrapper').classList.add(classToShowNode);

    this.renderRoot.querySelector('.handler').classList.remove(classToHideNode);
    this.renderRoot.querySelector('.handler').classList.add(classToShowNode);
    this.requestUpdate();
  }
}

customElements.define('ki-timeseries-graph', KiTimeseriesGraph);
