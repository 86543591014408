import BaseTable from '@giswebgroup/ki-wcp-base/src/components/ki-table/BaseTable';
import PromiseData from '@giswebgroup/ki-wcp-base/src/components/ki-table/PromiseData';
import LazyRendering from '@giswebgroup/ki-wcp-base/src/components/ki-table/LazyRendering';
import SortAble from '@giswebgroup/ki-wcp-base/src/components/ki-table/SortAble';
import Mix from '@giswebgroup/ki-wcp-base/src/common/Mix';
import ColumnHider from '@giswebgroup/ki-wcp-base/src/components/ki-table/ColumnHider';
import ColumnFormatter from '@giswebgroup/ki-wcp-base/src/components/ki-table/ColumnFormatter';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import nls from '../../locales/index.nls';

// for inherit setter getter need define both
export default
@i18n(nls)
class KiTable extends Mix(BaseTable, LazyRendering, PromiseData, SortAble, ColumnHider, ColumnFormatter) {}

customElements.define('ki-timeseries-table', KiTable);
