import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: block;
    overflow: hidden;
  }
  .circle {
    width: 12px;
    height: 12px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
  }
  .icon {
    color: white;
    font-size: 9px;
    text-align: center;
    margin: 0 auto;
    padding-top: 1px;
  }
  .label {
    font-family: Arial;
    font-size: 8px;
  }
`;
