import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    --lumo-font-size-xxs: 0.75em;
    --lumo-font-size-xs: 0.8125em;
    --lumo-font-size-s: 0.875em;
    --lumo-font-size-m: 1em;
    --lumo-font-size-l: 1.125em;
    --lumo-font-size-xl: 1.375em;
    --lumo-font-size-xxl: 1.75em;
  }

  .view-container {
    flex: 3;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }

  .view-container ki-modal ki-station-searchbox {
    padding: 10px 5px 16px 5px;
  }

  .view-container .list-items {
    border-top: 1px solid gray;
    height: calc(100vh - 63px);
    overflow: scroll;
  }

  .notification {
    flex: 0;
    color: var(--theme-color, #0056a0);
    border: 1px solid var(--theme-color, #0056a0);
    border-width: 1px 0;
    background: #ebf2fa;
    text-align: center;
    padding: 8px;
    font-size: 1.4em;
  }

  .side-bar {
    flex-grow: 0;
    flex-shrink: 0;
    width: 0; 
    overflow: auto;
    transition: width 0.7s ease-in-out;
  }

  .nav-list {
    padding-top: 70px;
    height: 100%;
    box-sizing: border-box;
  }
  .nav-list ki-station-nav-list {
    border-top: 1px solid lightgray;
  }

  :host(.sidebar-mode) .side-bar {
    width: 435px;
    border-right: 1px solid lightgray;
  }
  ki-stack {
    width: 435px;
    height: 100%;
    background: white;
  }

  ki-station-searchbox {
    width: 350px;
    position: absolute;
    z-index: 9;
    left: 15px;
    top: 15px;
  }
  :host(.sidebar-mode) ki-station-searchbox {
    z-index: 29;
  }

  .list-toggle {
    position: absolute;
    z-index: 9;
    top: 15px;
    left: 380px;
    font-size: 16px;
    transition: box-shadow 0.2s ease-in-out 0.5s, background 0.2s ease-in-out 0.5s, color 0.2s ease-in-out 0.5s;
  }

  :host(.sidebar-mode) ki-station-searchbox::part(searchbox) {
    box-shadow: none;
    border: 1px solid lightgray;
    transition: all 0.2s ease-in-out 0.5s;
  }

  :host(.sidebar-mode) .list-toggle {
    box-shadow: none;
    background: var(--theme-color, #0056a0);
    color: white;
  }

  :host(.sidebar-mode) .list-toggle.disabled {
    box-shadow: none;
    background: gray;
    color: white;
    cursor: not-allowed;
  }

  ki-stack#view-stack {
    height: 100%;
    flex: 1 1 0;
  }

  ki-switcher {
    position: absolute;
    left: 48%;
    z-index: 3;
    top: 15px;
  }

  .hide {
    display: none !important;
  }

  ki-station-table,
  ki-station-map {
    height: 100%;
    background: white;
  }

  ki-station-download-bar {
    position: absolute;
    z-index: 9;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  ki-station-data-download-form {
    max-width: 600px;
    height: auto;
  }

  ki-annual-graph {
    height: 100%;
  }

  :host(.sm-screen) ki-search-filter-action {
    display: none; // TODO
  }

  :host(.sm-screen) ki-station-map::part(legend) {
    bottom: 130px;
    right: initial;
    left: 20px;
  }

  :host(.sm-screen) ki-station-searchbox {
    border: none;
    width: auto;
    display: block;
    position: relative;
    left: unset;
    top: unset;
  }

  :host(.sm-screen) ki-station-searchbox::part(searchbox) {
    box-shadow: none;
    border: 1px solid lightgray;
  }

  ki-list .station-item {
  }

  ki-station-detail-panel,
  ki-catchment-details {
    z-index: 39;
    background: white;
  }

  ki-catchment-details {
    overflow: auto;
  }

  :host(.flat) ki-station-searchbox::part(searchbox) {
    box-shadow: none;
    border: 1px solid lightgray;
  }

  :host(.flat) ki-switcher {
    border: 1px solid var(--theme-color);
    box-shadow: none;
  }

  :host(.flat) ki-icon-btn {
    box-shadow: none;
  }

  #graph.fullScreen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
    background: white;
  }

  :host(.fullScreen-mode) ki-station-searchbox {
    display: none !important;
  }

  .graph-container {
    z-index: 18;
    background: white;
  }

  ki-modal.custom-fullscreen.visible {
    position:fixed;
    top: 0;
    right: 0;
    bottom:0;
    left:0;
  }
  ki-station-alarm {
    width: 435px;
    border-left: 1px solid lightgray;
    transition: width 0.7s ease-in-out;
    max-height: 100%;
    overflow: hidden;
    position: relative;
  }

  ki-station-alarm.mobile {
    height: 100%;
    width: 100%;
    border-left: 0px solid lightgray;
  }
  .hidden {
    width: 0;
  }

  .header-sort{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .header-sort .header-content {
    max-width:20%;
  }

  .header-sort vaadin-select{
    max-width: 80%;
  }

  ki-icon-btn.announcement {
    z-index: 11;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    color: var(--theme-color-primary, #1d1d1b);
  }

`;
// ki-search-filter-action add flag to show or not
