import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: block;
    font-size: 14px;
  }

  .separator {
    border-bottom: 1px solid #ccc;
    margin: 0.5em 0;
  }

  .popover {
    position: relative;
  }

  .popover .titleSection {
    display: none;
  }

  .popover .tabSection {
    display: none;
  }

  .popover .popover-body {
    display: none;
    text-decoration: none;
  }

  .popover ki-icon-btn {
    box-shadow: none;
    border: 1px solid #1d1d1b;
  }

  .popover.active .popover-body {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    width: auto;
    height: auto;
    max-height: 400px;
    margin-top: 8px;
    z-index: 10;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .popover.active .popover-body .popover-content {
    display: flex;
  }

  .popover.active .popover-body .popover-content .button {
    border: 1px solid #ccc;
    color: grey;
    cursor: pointer;
    padding: 0.5em 0.8em;
    margin: 0.3em;
    border-radius: 50px;
    text-align: center;
    user-select: none;
  }

  .popover.active .popover-body .popover-content .invisibleButton {
    flex: 1;
    border: none;
    cursor: auto;
    pointer-events: none;
  }

  .popover.active .popover-body .popover-content .button.selected:hover,
  .popover.active .popover-body .popover-content .button.selected {
    color: #ffffff;
    border: 1px solid #fff;
  }

  .popover .graphConfigButton.selected,
  .popover.active .popover-body .popover-content .button.selected {
    background: #1093cd;
    border: 1px solid #fff;
    color: #ffffff;
  }

  .popover .graphConfigButton:hover,
  .popover.active .popover-body .popover-content .button:hover {
    color: #4a4a49;
    border: 1px solid #4a4a49;
  }

  .popover.active .popover-body .popover-content .node-content {
    display: flex;
  }

  .popover.active .popover-body .hide,
  .popover.active .popover-body .popover-content .hide {
    display: none;
  }

  .popover.active .popover-body .titleSectionDesktop {
    display: flex;
    flex-direction: row;
    height: auto;
    position: sticky;
    top: 0;
  }

  .popover.active .popover-body .popover-content .group {
    display: flex;
    flex-direction: column;
    padding: 0 0.5em 0.5em 0.5em;
    border-right: 1px solid #4a4a49;
    height: auto;
  }

  .popover.active .popover-body .popover-content .group,
  .popover.active .popover-body .titleSectionDesktop .titleGroup {
    width: 220px;
    border-right: 1px solid #4a4a49;
  }

  .popover.active .popover-body .titleSectionDesktop .no-border-title,
  .popover.active .popover-body .popover-content .no-border-group {
    border-right: 0 !important;
  }

  .popover.active .popover-body .titleSectionDesktop .titleGroup,
  .popover.active .popover-body .titleGroup {
    flex-direction: column;
    color: #4a4a49;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    padding: 0.5em;
    background: white;
  }

  .popover.active .popover-body .popover-content .additionalPaddingTop {
    padding-top: 1em;
  }

  .popover.active .popover-body .popover-content .indicators-content,
  .popover.active .popover-body .popover-content .years-content {
    flex-direction: column;
  }

  .popover.active .popover-body .popover-content .years-content .row {
    display: flex;
    align-items: flex-start;
  }

  .popover.active .popover-body .popover-content .years-content .row .button {
    flex: 1;
  }

  .popover .graphConfigButton {
    display: none;
    border: 1px solid #ccc;
    border-radius: 50px;
    color: grey;
    cursor: pointer;
    text-align: center;
    padding: 0.5em 1em;
    margin: 0 0.5em;
    user-select: none;
  }

  .popover .graphConfigButton.selected {
    color: #ffffff;
    border: 1px solid #fff;
  }

  .popover .graphConfigButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popover .graphConfigButton .iconText {
    padding-left: 0.5em;
  }

  @media (max-width: 1024px) {
    .popover .titleSection {
      display: flex;
      border-bottom: 1px solid #4a4a49;
      font-size: 1.2em;
      font-weight: bold;
      padding: 0.5em;
      color: #4a4a49;
    }

    .popover .titleSection div {
      padding-left: 0.5em;
    }

    .popover .titleSection ki-icon {
      cursor: pointer;
    }

    .popover .tabSection {
      padding: 0.5em 0;
      font-size: 1.2em;
      white-space: nowrap;
      display: block !important;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }

    .popover .tabSection .tab {
      display: inline-block;
      text-align: center;
      width: 33%;
      cursor: pointer;
    }

    .popover .tabSection .tab.selected {
      border-bottom: 4px solid #1093cd;
    }

    .popover.active .popover-body {
      margin-top: 0;
      position: fixed;
      top: 0;
      left: 0 !important;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }

    .popover.active .popover-body .popover-content {
      display: block;
      flex-direction: column;
      overflow: auto;
    }

    .popover.active .popover-body .titleSectionDesktop {
      display: none !important;
    }

    .popover.active .popover-body .popover-content .group {
      width: auto;
      overflow-y: hidden;
      border-right: none;
    }

    .popover.active .popover-body .popover-content .hiddenGroup {
      display: none !important;
    }

    .popover .graphConfigButton .iconText {
      padding-left: 0;
      display: none;
    }

    .popover.active .popover-body .popover-content .node-content {
      padding-top: 0;
    }
  }
`;
