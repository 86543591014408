import { html, LitElement } from 'lit-element';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import query from '@giswebgroup/ki-wcp-base/src/decorators/query';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-map';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-catchments-layer';
import '@giswebgroup/ki-wcp-water/src/components/ki-layers-control/ki-layers-control';
import autoRun from '@giswebgroup/ki-wcp-base/src/decorators/autoRun';
import '@giswebgroup/ki-wcp-base/src/components/ki-switcher/ki-switcher';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-base-layers';
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import '../../components/ki-station-alarm/ki-station-alarm';
import { getCatchmentStatus } from '../../views/ki-station-view/StationsStore';
import TagStyleConfig from '../../views/ki-station-view/TagStyleConfig';
import './ki-legend-bar';
import { getLayer } from '@giswebgroup/ki-wcp-water/src/common/ki-ol-layer-generator';
import {TopoJSON} from "ol/format";
import { css } from 'lit-element';

export default class KiCatchmentMap extends LoaderMixin(LitElement) {
  static styles = css`
  :host {
    display: block;
    overflow: hidden;
    display: flex;
    position: relative;
    cursor: pointer;
  }

  ki-station-map{
    flex: 1
  }


  ki-legend {
    left: 10%;
    bottom: 20%;
  }

  .hidden{
    display: none;
  }

  ki-switcher {
    margin-top: 10px;
    z-index: 11;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

  }

  ki-switcher .tab{
    color: red;
  }

  .attribution{
    bottom:10px;
    position: absolute;
    right: 10px;
  }
`;

  map;

  layer;

  view;

  @query('#map')
  __mapElement;

  constructor() {
    super();
    this.title = '';
    this.__hasActiveAlarms = false;
    this.jumpUrl = '';
    this.cstyle = {
      // tagProperty optional
      // order: first is highest
      label: 'Situation Infocrue',
      notInteractive: true,
      default:{
        marker: {
          backgroundColor: 'rgba(0,0, 0, 0)',
          color: 'rgba(255,255,255,1)',
          icon: 'ki ki-info',
          fontSize: 0.6,
        },
        style: {
          "stroke": {
            "color": "rgba(196, 196, 196, 0)",
            "width": 1
          },
          "fill": {
            "color": "rgba(101, 13, 27, 0)"
          }
        },
      },
      tags: [
        {
          name: 'Alerte',
          label: 'Alerte',
          filter(item) {
            return item.web_classes === 2;
          },
          marker: {
            backgroundColor: 'rgba(200,0,0,1)',
            shape: 'square',
            color: 'rgba(255,255,255, 1)',
            icon: ''
          },
          style: {
            "stroke": {
              "color": "rgba(196, 196, 196, 1.0)",
              "width": 1
            },
            "fill": {
              "color": "rgba(200,0,0,0.6)"
            }
          },
        },
        {
          name: 'Préalerte',
          label: 'Préalerte',
          filter(item) {
            return item.web_classes === 1;
          },
          marker: {
            shape: 'square',
            backgroundColor: 'rgba(254,230,83,1)',
            color: 'rgba(255,255,255, 1)',
            icon: '',
          },
          style: {
            "stroke": {
              "color": "rgba(196, 196, 196, 1.0)",
              "width": 1
            },
            "fill": {
              "color": 'rgba(254,230,83,0.6)'
            }
          },
        },
        {
          name: 'Normal',
          label: 'Normal',
          filter(item) {
            return item.web_classes === 0;
          },
          marker: {
            shape: 'square',
            backgroundColor: 'rgba(0,136, 64, 1)',
            color: 'rgba(255,255,255, 1)',
            icon: '',
            fontSize: 0.01,
          },
          style: {
            "stroke": {
              "color": "rgba(0,136, 64, 1.0)",
              "width": 1
            },
            "fill": {
              "color": "rgba(0,136, 64,0.6)"
            }
          },
        }
      ],
    };

    this.tagStyles = new TagStyleConfig();
    this.tagStyles.config = this.cstyle;
  }

  static get properties() {
    return {
      zIndex: { type: Number, attribute: 'z-index' },
      kiwisPath: { type: String },
      title: { type: String },
      jumpUrl: { type: String },
      attribution: { type: String },
    };
  }
  
  connectedCallback() {
    super.connectedCallback();
    this.renderRoot.addEventListener('click', e => {
      window.location.href = this.jumpUrl;
      e.stopPropagation();
    });
  }



  render() {
    if (!this.config) {
      return html``;
    }
    return html`
    <ki-switcher
    class="round ${this.__hasActiveAlarms ? '' : 'hidden'}"
    id="map-table-switcher"
    .options="${[
      { label: 'Avertissements', value: 'announcement', icon: 'ki ki-exclamation-triangle' }
    ]}"
    value="Avertissements"
  ></ki-switcher>
    <ki-station-map id="map" .view="${this._view}" key="map">
        <ki-base-layers
        .layers="${this.mapLayers}"
      ></ki-base-layers>

      <ki-catchments-layer
      zindexlayer = "7"
      .catchmentFeatures = ${this.__catchmentGeometries}
      .catchmentsStyles=${this.tagStyles.catchmentsStyles}
      .catchments="${this.__catchments}"
    ></ki-catchments-layer>
    </ki-station-map>
    <ki-legend
    forceFullDisplay
    .options="${[this.tagStyles.legendOptions]}">
  </ki-legend>
    <div class="attribution">${this.attribution}<div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  get _view() {
    return {
      zIndex: 11,
      extent:  [-6415, 11566, 346998, 179437],
      center: [170291, 95502],
      projection: 'EPSG:31370',
      zoom: 8.861281435871305,
      constrainResolution: false,
      maxZoom: 9,
      showFullExtent:true,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get mapLayers(){
    return [getLayer({
      url: 'https://hydrometrie.wallonie.be/services/kiwcp/data/rivers.png',
      type: 'staticimage',
      projection: 'EPSG:31370',
      extent: [-94768,-30401,435351,221404],
      zIndex: 15,
      visible: true,
      selectable: true,
      label: 'mainRivers'
    })]
  }

  loadGeometries(){
    return fetch('/services/kiwcp/data/catchments.json').then(response => {
      return response.json();
    }).then((geometries) => {
      const features = new TopoJSON().readFeatures(geometries)
      features.forEach(feat => {
        feat.setId(feat.get("ID_INFOCR").toString())
      })
      this.__catchmentGeometries = features;
    })
  }

  @autoRun('config')
  loadData() {
    if (!this.config || !this.kiwisPath) {
      return;
    }
    // eslint-disable-next-line consistent-return
    const kiwis = new KIWIS({ basePath: this.kiwisPath });
    Promise.all([getCatchmentStatus(kiwis, this.cstyle, {enableCatchmentByStatus: true} ), this.loadGeometries()]).then(([ catchments]) => {
      this.__catchments = catchments;
      this.__hasActiveAlarms = catchments.some(item => item.catchmentAlarmMessage)
      this.requestUpdate();
      const mapW = this.renderRoot.getElementById('map');
      mapW.map.getView().fit([-6415, 11566, 346998, 179437], mapW.map.getSize()); 
    });
  }
}

customElements.define('ki-catchment-map', KiCatchmentMap);
