import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
    --lumo-contrast-10pct: white;
  }

  .handler {
    width: 20px;
    padding: 0;
    cursor: ew-resize;
    flex: 0 0 auto;
    align-items: center;
    display: flex;
  }

  .handler::before {
    content: '';
    display: block;
    width: 4px;
    height: 5%;
    background: #acb4c1;
    margin: 0 auto;
    border-radius: 50px;
  }

  .header {
    border-bottom: 1px solid #e2e4ed;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    justify-content: space-between;
    padding: 0 16px;
    background: white;
  }

  .header ki-icon-btn {
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    width: auto;
  }

  .header ki-icon-btn.selected,
  .header ki-icon-btn:hover {
    box-sizing: border-box;
  }

  .header ki-icon-btn.selected,
  .header ki-icon-btn.selected:hover {
    color: #ffffff;
    background: #1093cd;
  }

  .header ki-icon-btn:hover {
    color: #4a4a49;
  }

  .header .separator {
    font-size: 1.2em;
    font-weight: 600;
    padding: 0 0.5em;
    color: #ccc;
  }

  .header .leftHeader {
    display: flex;
    align-items: center;
    color: rgb(74, 74, 73);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 300px;
    max-width: 800px;
  }

  .header .leftHeader .parameter-title {
    font-size: 1.5em;
    padding-right: 20px;
  }

  .header .leftHeader .navbutton {
    font-size: 1.5em;
    padding: 0 10px;
    cursor: pointer;
  }

  .header .leftHeader  vaadin-select{
    width: 100%;
    min-width: 200px;
  }
  .header .rightHeader {
    align-items: center;
    display: flex;
    white-space: nowrap;
  }

  .header .rightHeader ki-time-range-picker {
    margin: 0 0.5em;
  }

  .containerNode {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 0;
  }

  .containerNode {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 0;
  }

  .containerNode .graphWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
    padding-right: 10px;
  }

  .containerNode .graphWrapper #chart,
  .containerNode .graphWrapper #qualityBar {
    display: flex;
  }

  .containerNode .graphWrapper #qualityBar {
    height: 32px;
  }

  .containerNode .graphWrapper #chart {
    flex: 1;
    min-height: 0;
    position: relative;
  }

  .visible {
    display: flex;
  }

  .containerNode .tableWrapper.visible {
    flex: 0 0 510px;
    border-left: 1px solid #acb4c1;
  }

  .hidden {
    display: none;
    width: 0;
  }
  .handler {
    display: none;
  }
  .containerNode .tableWrapper {
    flex-direction: column;
    overflow: auto;
  }


  @media (max-width: 1024px) {
    .header{
      padding: 0 5px;
    }
    .containerNode .tableWrapper.visible {
      flex: 0 0 100%;
    }
    .header ki-icon-btn {
      margin: 0;
    }

    .header #expand-btn{
      display:none;
    }
    .header .leftHeader .parameter-title {
      font-size: 1.2em;
      padding-right: 0px;
    }
    .header .separator {
      display: none;
    }
    .containerNode .graphWrapper {
      width: 0;
      padding: 0;
    }
    #close-btn {
      display:none;
    }
  }
`;
