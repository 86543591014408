import { html, LitElement } from 'lit-element';
import moment from 'moment';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import style from './ki-quality-bar.css';
import nls from '../../locales/index.nls';
import { keyBy } from 'lodash-es';

const DATE_FORMAT = 'DD/MM/YYYY hh:mm';
const DEFAULT_EMPTY_QUALITY_OBJ = {
  key: '',
  code: '',
  description: '',
  color: 'rgba(255, 255, 255, 1.0)',
};

export default
@i18n(nls)
class KiQualityBar extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  margins;

  qualityCodes;

  _from;

  _to;

  _qualities;

  _qualityMatrix;

  constructor() {
    super();
    this.margins = {};
    this.qualityCodes = [];

    this._from = moment();
    this._to = moment();
    this._qualities = [];
    this._qualityMatrix = [];
  }

  static getDefaultQualities() {
    return [
      {
        key: 0,
        code: 'Validé',
        description: 'Validé',
        color: 'rgba(0, 197, 160, 0.3)',
      },
      {
        key: 40,
        code: 'Validé',
        description: 'Validé',
        color: 'rgba(0, 197, 160, 0.3)',
      },
      {
        key: 80,
        code: 'Validé',
        description: 'Validé',
        color: 'rgba(0, 197, 160, 0.3)',
      },
      {
        key: 120,
        code: 'Validé',
        description: 'Validé',
        color: 'rgba(0, 197, 160, 0.3)',
      },
      {
        key: 160,
        code: 'Validé',
        description: 'Validé',
        color: 'rgba(0, 197, 160, 0.3)',
      },
      {
        key: 161,
        code: 'Manuel',
        description: 'Manuel',
        color: 'rgba(74, 74, 73, 0.3)',
      },
      {
        key: 162,
        code: 'Manuel',
        description: 'Manuel',
        color: 'rgba(74, 74, 73, 0.3)',
      },
      {
        key: 163,
        code: 'Manuel',
        description: 'Manuel',
        color: 'rgba(74, 74, 73, 0.3)',
      },
      {
        key: 200,
        code: 'Non contrôlé',
        description: 'Non contrôlé',
        color: 'rgba(226, 228, 237, 0.6)',
      },
      {
        key: 210,
        code: 'Douteux',
        description: 'Contrôle automatique douteux',
        color: 'rgba(0, 24, 35, 0.2)',
      },
      {
        key: 253,
        code: 'VF',
        description: 'Valeurs fantomes',
        color: 'rgba(135, 224, 254, 0.3)',
      },
    ];
  }

  static getPeriodInMillisecond(from, to) {
    return moment(to).valueOf() - moment(from).valueOf();
  }

  static pickTextColor(color) {
    if (!color) {
      return '';
    }
    // More info here: https://awik.io/determine-color-bright-dark-using-javascript/
    let r;
    let g;
    let b;

    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      [r, g, b] = [color[1], color[2], color[3]];
    } else {
      color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;
      r = color > 16;
      g = color > 8 && 255;
      b = color && 255;
    }

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    return hsp > 128.5 ? '#000' : '#fff';
  }

  static get properties() {
    return {
      from: { type: String },
      to: { type: String },
      margins: { type: Object },
      qualities: { type: Array },
      qualityCodes: { type: Array },
    };
  }

  render() {
    this._sortQualities();
    this._createQualityMatrix();
    // language=html
    return html` <div id="qualityBar" class="qualityBar">${this._renderQualities()}</div> `;
  }

  _renderQualities() {
    return html`
      ${this._qualityMatrix.map((item, index) => {
        let _title = this.i18n.t(`qcode${item.key}`);
        let _value = item.key !== undefined && item.key !== null ? this.i18n.t(`qcode${item.key}`) : '';
        _title = _title === `qcode${item.key}` ? item.code : _title;
        _value = _value === `qcode${item.key}` ? item.code : _value;
        _title = _title === 'qcode' ? '' : _title;
        _value = _value === 'qcode' ? '' : _value;
        return html`
          <div
            class="quality"
            style="background: ${item.color}; width: ${item.width}px;"
            id="quality${index}"
            @mouseover="${() => {
              this._showTooltipInfo(item, index);
            }}"
          >
            <span style="color:${KiQualityBar.pickTextColor(item.color)};" title="${_title}">${_value}</span>
            ${this._renderPopup(item, index)}
          </div>
        `;
      })}
    `;
  }

  _renderPopup(item, index) {
    if (!item.code) {
      return html``;
    }
    return html`
      <paper-tooltip position="top" for="quality${index}" id="popup${index}">
        <div class="datesNode">${item.from.format(DATE_FORMAT)} - ${item.to.format(DATE_FORMAT)}</div>
        <div class="infoNode">${this.i18n.t('code')}: ${item.code}</div>
      </paper-tooltip>
    `;
  }

  set from(val) {
    this._from = moment(val);
    this.requestUpdate();
  }

  get from() {
    return this._from;
  }

  set to(val) {
    this._to = moment(val);
    this.requestUpdate();
  }

  get to() {
    return this._to;
  }

  set qualities(val) {
    this._qualities = val;
    this.requestUpdate();
  }

  get qualities() {
    return this._qualities;
  }

  _createQualityMatrix() {
    const _t = this;
    this._qualityMatrix = [];
    if (!this._qualities || !this._qualities.length > 0) {
      return;
    }
    this._setMargins();
    const pxPerMs = this._getPxPerMillisecond();
    let currFrom = this._from;
    let currTo = this._to;

    const firstQualityTimestamp = moment(this._qualities[0].timestamp);
    if (firstQualityTimestamp > currFrom) {
      // if we don't have data at the beggining -> white space
      currTo = firstQualityTimestamp;
      const qualityWidth = KiQualityBar.getPeriodInMillisecond(currFrom, currTo) * pxPerMs;
      this._qualityMatrix.push({
        from: currFrom,
        to: currTo,
        width: qualityWidth,
        ...DEFAULT_EMPTY_QUALITY_OBJ,
      });
      currFrom = firstQualityTimestamp;
    }
    const codeIndex = keyBy(KiQualityBar.getDefaultQualities(), "key");

    this._qualities.forEach((item, index) => {
      const qualityTimestamp = moment(item.timestamp);
      const nextTimestamp = index + 1 < _t._qualities.length ? moment(_t._qualities[index + 1].timestamp) : null;
      const nextValue = index + 1 < _t._qualities.length ? _t._qualities[index + 1].code : null;
      const nextValueName = codeIndex[nextValue]?.code;
      const currentValueName = codeIndex[item.code]?.code;
      const itemColorTextObj = _t._getColorAndTextByCode(item.code) || DEFAULT_EMPTY_QUALITY_OBJ;
      currTo = qualityTimestamp.valueOf() >= _t._to.valueOf() ? _t._to : qualityTimestamp;
      if (nextValue !== null && nextValueName !== currentValueName) {
        const qualityWidth = KiQualityBar.getPeriodInMillisecond(currFrom, currTo) * pxPerMs;
        _t._qualityMatrix.push({
          from: currFrom,
          to: currTo,
          width: qualityWidth,
          ...itemColorTextObj,
        });
        currFrom = qualityTimestamp;
      } else if (nextTimestamp === null && nextValue === null) {
        // Last array element
        const qualityWidth = KiQualityBar.getPeriodInMillisecond(currFrom, currTo) * pxPerMs;
        _t._qualityMatrix.push({
          from: currFrom,
          to: currTo,
          width: qualityWidth,
          ...itemColorTextObj,
        });
      }
    });
  }

  _getColorAndTextByCode(code) {
    this.qualityCodes = this.qualityCodes && this.qualityCodes.length > 0 ? this.qualityCodes : KiQualityBar.getDefaultQualities();
    return this.qualityCodes.find(qCode => qCode.key === code || qCode.code === code);
  }

  _getNodeWidth() {
    return this.offsetWidth;
  }

  _getPxPerMillisecond() {
    return this._getNodeWidth() / KiQualityBar.getPeriodInMillisecond(this._from, this._to);
  }

  _setMargins() {
    this.style.margin = `0 ${this.margins.marginRight}px 0 ${this.margins.marginLeft}px`;
  }

  _showTooltipInfo(item, index) {
    const popup = this.renderRoot.querySelector(`#popup${index}`);
    popup && popup.show({});
  }

  _sortQualities() {
    this._qualities.sort((a, b) => moment(a.timestamp) - moment(b.timestamp));
  }
}

customElements.define('ki-quality-bar', KiQualityBar);
