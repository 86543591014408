import * as moment from 'moment';

export default class PeriodsList {
  allowtime;

  constraints;

  periods;

  periodsWithoutTime;

  periodListOptions;

  value;

  constructor(classification) {
    this.constraints = classification && classification.constraints ? classification.constraints : {};

    const options =
      classification && classification.periods && classification.periods.length > 0
        ? classification.periods
        : [['custom', 'today', 'thisWeek', 'thisMonth', 'thisYear', 'theDaySoFar', 'last15day' ,'last30day', 'last60day', 'last6month', 'last1year', 'last2year']];

    this.allowtime === true ? (this.periods = options) : (this.periodsWithoutTime = options);
    this.periodListOptions = classification && classification.periodListOptions ? classification.periodListOptions : null;
    this.value = 'today';
  }

  set periods(val) {
    if (!val) {
      return;
    }
    this.periods = val;
  }

  get periods() {
    return this.periods;
  }

  set periodsWithoutTime(val) {
    if (!val) {
      return;
    }
    this.periodsWithoutTime = val;
  }

  get periodsWithoutTime() {
    return this.periodsWithoutTime;
  }

  static getDurationBySrt(periodStr) {
    let matched;
    let m;
    let unit;
    switch (periodStr) {
      case 'custom':
        return {
          from: moment(0),
          to: moment(0),
        };
      case 'today':
        return {
          from: moment().startOf('day'),
          to: moment().add(1, 'days').startOf('day'),
        };
      case 'thisWeek':
        return {
          from: moment().startOf('week'),
          to: moment().add(1, 'weeks').startOf('week'),
        };
      case 'thisMonth':
        return {
          from: moment().startOf('month'),
          to: moment().add(1, 'months').startOf('month'),
        };
      case 'thisYear':
        return {
          from: moment().startOf('year'),
          to: moment().add(1, 'years').startOf('year'),
        };
      case 'theDaySoFar':
        return {
          from: moment().startOf('day'),
          to: moment(),
        };
      case 'WTD':
        return {
          from: moment().startOf('week'),
          to: moment().startOf('day'),
        };
      case 'MTD':
        return {
          from: moment().startOf('month'),
          to: moment().startOf('day'),
        };
      case 'YTD':
        return {
          from: moment().startOf('year'),
          to: moment().startOf('day'),
        };
      case 'yesterday':
        m = moment().subtract(1, 'days');
        return {
          from: m.clone().startOf('day'),
          to: m.add(1, 'days').startOf('day'),
        };
      case 'dayBeforeYesterday':
        m = moment().subtract(2, 'days');
        return {
          from: m.clone().startOf('day'),
          to: m.add(1, 'days').startOf('day'),
        };
      case 'dayLastWeek':
        m = moment().subtract(1, 'weeks');
        return {
          from: m.clone().startOf('day'),
          to: m.add(1, 'days').startOf('day'),
        };
      case 'preWeek':
        m = moment().subtract(1, 'weeks');
        return {
          from: m.clone().startOf('week'),
          to: m.add(1, 'weeks').startOf('week'),
        };
      case 'preMonth':
        m = moment().subtract(1, 'months');
        return {
          from: m.clone().startOf('month'),
          to: m.add(1, 'months').startOf('month'),
        };
      case 'preYear':
        m = moment().subtract(1, 'years');
        return {
          from: m.clone().startOf('year'),
          to: m.add(1, 'years').startOf('year'),
        };
      case 'periodOfRecord':
        return {
          from: moment().startOf('year'),
          to: moment().startOf('day'),
        };
      default:
        matched = periodStr.match(/^last(\d+)(min|hour|day|month|year)/i);
        if (matched) {
          m = moment();
          unit = matched[2].toLowerCase();
          if (unit === 'min') {
            unit = 'minute';
          }
          return {
            from: m.clone().subtract(matched[1], `${unit}s`),
            to: m,
          };
        }
        return {};
    }
  }

  getPeriodsList() {
    const returnOptions = [];
    const options = this.allowtime === true ? this.periods : this.periodsWithoutTime;
    options.forEach(col => {
      const returnCol = [];
      col.forEach(opt => {
        returnCol.push({
          label: opt,
          dates: PeriodsList.getDurationBySrt(opt),
        });
      });
      returnOptions.push(returnCol);
    });
    return returnOptions;
  }
}
