import { html } from 'lit-element';
import moment from 'moment';
import { template } from "@giswebgroup/ki-wcp-base/src/common/util"
import icons from "@giswebgroup/ki-wcp-base/src/common/allicons.json"
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import { orderBy } from 'lodash-es';

function getIcon(iconClass, color = "#FFFFFF") {
  const icon = icons[iconClass];
  if(!icon){
    return "";
  }
  return icon.replace("<svg ", `<svg style='fill:${color};width:2em;vertical-align: top;'`)

}

export default function f(claz) {
  return class extends claz {

    sortListBy = "station_no";

    sortListByOrder = "asc";

    get stationMobileList(){
      return orderBy(this.__filteredStations, this.sortListBy, this.sortListByOrder)
    }

    _renderFilters() {
      const cols = this.__configuration.mobileSortFields || ["station_no", "station_name", "river_name", "catchment_status", "BASSIN_GESTION_WEB"];

      const changeColumn = e => {
        const val = e.target.value.split("-");
        [this.sortListBy, this.sortListByOrder] = val

        this.requestUpdate();
      };
      return html`<div class="header-sort">
          <div class="header-content">${this.i18n.t('sortBy')}:</div>
          <vaadin-select value="station_no-asc" @change="${changeColumn}">
            <template>
              <vaadin-list-box>
                ${cols.map(item =>  {
                  return html`
                  <vaadin-item value="${item}-asc">${this.i18n.t(item)} - ${this.i18n.t('asc')}</vaadin-item>
                  <vaadin-item value="${item}-desc">${this.i18n.t(item)} - ${this.i18n.t('desc')}</vaadin-item>
                  ` 
                })}
                </vaadin-list-box>
            </template>
          </vaadin-select>
      </div>`
    }

    toggleTable(e){
      this.fullscreen = e.detail.fullscreen;
      this.requestUpdate();
    }

    renderMobile() {
      // language=html
      return html`
        <ki-modal visible draggable trackable id="station-list" minContentHeight="72"  @togglefullscreen="${this.toggleTable}">
          ${this._renderSearchBox()}
          ${this._renderFilters()}
          <ki-list class="list-items"> ${this.fullscreen ? this.stationMobileList.map(s => this.renderListItem(s)): ""} </ki-list>
        </ki-modal>

        <ki-modal id="station-detail" trackable .visible="${!!this.currentStationId}" class="custom-fullscreen">
          <ki-station-detail-panel
            .kiwisPath="${this.kiwisUrl}"
            .station="${this.__currentStation}"
            .menuConfig="${this.stationMenu}"
            .additionalLayers="${this.mapLayers}"
            .detailsConfig="${this.__configuration.stationDetails}"
            .statusBarStyles="${this.tagStyles.statusBarStyles}"
            .mapBackGroundLayer="${this.__mapBackGroundLayer}"
            .isFavourite="${this._isFavourite(this.__currentStation?.station_id)}"
            .supporter="${this.__configuration.supporter}"
            @selectstation="${(e)=> {this.switchStation(e.detail)}}"
            @close="${this.closeStationBar}"
            @itemSelected="${this._showStationGraph}"
            @toggleFavourite="${this._toggleFavourite}"
          ></ki-station-detail-panel>
        </ki-modal>

        <ki-modal id="catchment-detail" trackable .visible="${!!this.currentCatchmentId}" class="custom-fullscreen">
          <ki-catchment-details
            .catchment="${this.__currentCatchment}"
            @close="${this.closeCatchment}"
            @itemSelected="${e => {
              this.topic = e.detail.value;
              this.currentStationId = e.detail.stationPath;
              setTimeout(() => {
                e.detail.element?.focus();
              },100);
            }}"
            .tagStyles="${this.catchmentTagStyles.statusBarStyles}"
            .catchmentTagStyles="${this.catchmentClassificationInfocrue.statusBarStyles}"
          >
          </ki-catchment-details>
        </ki-modal>

        <ki-modal id="station-alarm" trackable class="custom-fullscreen" .visible="${this.mapOrTable === 'announcement'}">
          <ki-station-alarm class="mobile" skipHistory="true" 
            @close="${() => {this.mapOrTable ="map"}}" 
            .catchments="${this.__catchments}" 
            .catchmentsStyles=${this.catchmentClassificationInfocrue.catchmentsStyles} 
            .config="${this.__configuration}">
          </ki-station-alarm>
        </ki-modal>

        <ki-modal style="display: flex;flex-direction: column;" id="station-graph" closeable trackable .visible="${!!this.__dynamicViewTemplate}" class="custom-fullscreen">
          <div key="graph" 
            style="z-index: 18;
            height: 100%;
            overflow: hidden;"
             @selectstation="${(e)=> {this.switchStation(e.detail)}}"
             >${this.__dynamicViewTemplate}
             </div>
        </ki-modal>

        <ki-stack id="view-stack" .selected="${this.mapOrTable}">
        <ki-icon-btn style="display:${this.__configuration.enableCatchmentByStatus && this.__catchments.some(item => item.catchmentAlarmMessage) ? 'block' : 'none'}"
          class="announcement"
          icon="ki ki-exclamation-triangle"
          @click="${() => {this.mapOrTable ="announcement"}}"
        ></ki-icon-btn>
          <ki-station-map .view="${this._view}" key="map" .stations="${this.__stations}">

            <ki-layers-control style="bottom:80px;left:20px;" .i18n=${this.i18n} .groups="${this.layerGroups}"></ki-layers-control>
            <ki-legends-control .disabled="${!this.__configuration.stationClassification}" .options="${this.tagStyles.legendOptions}"></ki-legends-control>
            <ki-station-map-zoom-control style="bottom:80px" label="map zoom" .homeExtent="${this._homeExtent}"></ki-station-map-zoom-control>
 
            <ki-base-layers
              .layers="${this.mapLayers}"
              @changed="${e => {
                this.__mapBackGroundLayer = e.detail.value;
              }}"
            ></ki-base-layers>
            <ki-stations-layer
              displayInLayerControl
              noAutoZoom
              @station-click="${this.clickStation}"
              .currentStation="${this.__currentStation}"
              .tagMarkers="${this.tagStyles.tagMarkers}"
              .tagSelectedMarkers="${this.tagStyles.tagSelectedMarkers}"
              .stations="${this.__filteredStations}"
              .infoTag="${this.__configuration.stationInfoPopup}"
              .labelTemplate="${this.__configuration.stationMapLabelTemplate || this.__configuration.stationLabelTemplate}"
            ></ki-stations-layer>

            <ki-catchments-layer
            zindexlayer = "7"
            .catchmentFeatures = ${this.__catchmentGeometries}
            .disabled=${!this.__configuration.enableCatchmentByStatus || !this.__catchments}
            .currentCatchment="${this.__currentCatchment}"
            @catchment-click="${this.clickCatchment}"
            .catchmentsStyles=${this.catchmentClassificationInfocrue.catchmentsStyles}
            .catchments="${this.__catchments}"
          ></ki-catchments-layer>

            <ki-catchments-layer
              zindexlayer = "6"
              .catchmentFeatures = ${this.__catchmentGeometries}
              name="calculatedCatchmentStatus"
              .currentCatchment="${this.__currentCatchment}"
              visible="false"
              @catchment-click="${this.clickCatchment}"
              .disabled=${!this.__configuration.enableCatchmentByObservation || !this.__calculatedCatchmentStatus}
              .catchmentsStyles=${this.catchmentTagStyles.catchmentsStyles}
              .catchments="${this.__calculatedCatchmentStatus}"
            ></ki-catchments-layer>
          </ki-station-map>
        </ki-stack>
      `;
    }


    renderListItem(station) {
      const marker = this.tagStyles.statusBarStyles[station.__tag] || { backgroundColor: '', color: '', icon: '' };
      const dateFormat = this.__configuration?.multiGraphOptions?.dateFormat || "L LT";
      return html`
        <ki-list-item
          class="station-item"
          @click="${() => this.clickStation({ detail: { value: `${station.site_no}/${station.station_no}` } })}"
          style="display:flex; padding:0; border-bottom: 1px solid grey;"
        >
          <div style="flex:0 0 40px;display: flex;padding: 5px; white-space: nowrap; background-color: ${marker.backgroundColor}; justify-content: center;">
              ${unsafeHTML(getIcon(marker.icon, marker.color))}
          </div>
          <div style="flex:1; padding: 5px 10px;">
            <table style="color: grey; font-size: 12px;">
              <tr style="font-size: 16px;">
                <td colspan="2">${template(this.__configuration.stationLabelFormats[0].value, station)}</td>
              </tr>
              ${this.__configuration.mobileListItem.map(item => {
                return html`<tr>
                <td>${item.label}</td>
                <td>${template(item.field, station)}</td>
              </tr>`
              })}
              <tr>
                <td>Valeur ${station.stationparameter_name}</td>
                <td><b>${this.numberformatter.format(station.ts_value)} ${station.ts_unitsymbol}</b> (${station.timestamp && moment(station.timestamp).format(dateFormat)})</td>
              </tr>
            </table>
          </div>
        </ki-list-item>
      `;
    }
  };
}
