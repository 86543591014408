import { html, LitElement } from 'lit-element';
import cloneDeep from 'lodash-es/cloneDeep';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import Highcharts from 'highcharts/highstock';
import * as moment from 'moment';
import { every, includes, isEmpty, forEach } from 'lodash-es';
import autoResize from '@giswebgroup/ki-wcp-base/src/decorators/autoResize';
import hclocales from '@giswebgroup/ki-wcp-water/src/common/highcharts-locales';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import offlineexporting from 'highcharts/modules/offline-exporting';
import nls from '../../locales/index.nls';
import style from './ki-chart.css';
import * as Utils from './ki-chart-utils';

more(Highcharts);
exporting(Highcharts);
offlineexporting(Highcharts);

const KEYS = {
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
};

const DELAY_TIME = 175;
const DOWN_MOUSE_WHEEL = 'DOWNMOUSEWHEEL';
const UP_MOUSE_WHEEL = 'UPMOUSEWHEEL';
const PHONE_BREAK_POINT = 375;
const ZOOM_DIVIDER = 2;

export default
@autoResize()
@i18n(nls)
class KiChart extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  _axisKeys;

  _chart;

  _gaps;

  _idProperty;

  _fromTime;

  _toTime;

  _lastKeyCode;

  _lastKeyCodeTimesPress;

  _timeseries;

  _timeout;

  _totalTimeseriesAddedInGraph;

  graphConfig;

  levels;

  zones;

  zoomEnabled;

  constructor() {
    super();

    this.PERIOD_SELECTED = 'periodselected';

    this._axisKeys = [];
    this._chart = null;
    this._gaps = {};
    this._idProperty = 'ts_id';
    this._fromTime = 0;
    this._toTime = 0;
    this._lastKeyCode = null;
    this._lastKeyCodeTimesPress = null;
    this._timeseries = [];
    this._timeout = null;
    this._totalTimeseriesAddedInGraph = 0;
    this.xMaxDate = moment().add(10, "years");
    this.xMinDate = moment("1900-01-01T00:00:00Z");
    this.graphConfig = {};
    this.levels = [];
    this.zones = {};
    this.zoomEnabled = null;
  }

  
  connectedCallback() {
    super.connectedCallback();
    const hcoptions = {
      colors: this.colors || ['#3f86cd', '#dc375d', '#679f2e', '#e27600', '#4a4a49', '#336598', '#e4c72b', '#00907a', '#ed583c', '#0689AE']
    }
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      hcoptions.lang = hclocales[this.i18n.language.substring(0, 2)]
    }
    Highcharts.setOptions(hcoptions);
  }

  firstUpdated() {
    this._setFirstDates();
    this._setPeriod(this._fromTime.toISOString(), this._toTime.toISOString());
    this._createChart();
    this._addKeyboardEvents();
    this._addSeries();
  }

  static get properties() {
    return {
      graphConfig: { type: Object },
      levels: { type: Array },
      zones: { type: Object },
      zoomEnabled: { type: Boolean },
    };
  }

  set from(val) {
    if (!val) {
      return;
    }
    this._fromTime = moment(val);
    if (this._chart && this._chart.xAxis && this._chart.xAxis[0]) {
      this._chart.xAxis[0].setExtremes(this._fromTime.valueOf(), this._toTime.valueOf(), false, true);
    }
    this.requestUpdate();
  }

  get from() {
    return this._fromTime;
  }

  set to(val) {
    if (!val) {
      return;
    }
    this._toTime = moment(val);
    if (this._chart && this._chart.xAxis && this._chart.xAxis[0]) {
      this._chart.xAxis[0].setExtremes(this._fromTime.valueOf(), this._toTime.valueOf(), false, true);
    }
    this.requestUpdate();
  }

  get to() {
    return this._toTime;
  }

  set gaps(val) {
    this._gaps = cloneDeep(val);
    this.requestUpdate();
  }

  get gaps() {
    return this._gaps;
  }

  set levels(val) {
    this.levels = cloneDeep(val);
    this.requestUpdate();
  }

  get levels() {
    return this.levels;
  }

  set timeseries(val) {
    this._msg = '';
    if (val.then) {
      // promise
      this.promiseLoader(
        val
          .then(timeseries => {
            this._timeseries = timeseries;
          })
          .catch(error => {
            this._msg = html` <span class="error">${error}</span> `;
          }),
      );
    } else {
      this._timeseries = cloneDeep(val);
      if (this._timeseries.length === 0) {
        this._msg = 'No Timeseries';
      }
      this.requestUpdate();
    }
  }

  get timeseries() {
    return this._timeseries;
  }

  _addKeyboardEvents() {
    const _t = this;
    const graph = this.renderRoot.querySelector('#graph');
    _t._createFalseButtonToBeFocused(graph);
    graph.addEventListener('mouseenter', () => {
      _t._falseButton.focus();
    });
    graph.addEventListener('mouseleave', () => {
      _t._falseButton.blur();
    });
    this._falseButton.addEventListener('keyup', event => {
      _t._checkEndKeyPress(event.keyCode);
    });
    graph.addEventListener('wheel', event => {
      event.preventDefault();
      const keyCode = event.deltaY < 0 ? UP_MOUSE_WHEEL : DOWN_MOUSE_WHEEL;
      _t._checkEndKeyPress(keyCode);
    });
  }

  _addPlotBandWhenDataNotExists() {
    if (!this.graphConfig.hideGapBand) {
      const _t = this;
      this._chart.xAxis[0].removePlotBand();
      forEach(this._gaps, (gaps, id) => {
        const serie = _t._chart.get(id) || _t._chart.get(Number(id));
        if (serie && serie.visible) {
          gaps.forEach(item => {
            _t._chart.xAxis[0].addPlotBand({
              color: 'rgba(127, 139, 145, 0.1)',
              from: item.from,
              to: item.to,
            });
          });
        }
      });
    }
  }

  _addSeries() {
    const _t = this;
    let dataProvider = '';
    if (!this._chart) {
      return;
    }
    while (this._chart.series.length) {
      _t._chart.series[0].remove(false);
    }
    Utils.assignColorToTimeseries(Highcharts.getOptions().colors, this._timeseries);
    this._timeseries &&
      this._timeseries.forEach(ts => {
        dataProvider = dataProvider === '' && ts.ADMINISTRATEUR ? `${this.i18n.t('dataProvider')}: ${ts.ADMINISTRATEUR}` : '';
        _t._drawSeriesChart(ts);
        _t._totalTimeseriesAddedInGraph += 1;
      });
    this.levels &&
      this.levels.forEach(ts => {
        if(ts.data){
          _t._addLevelSerie(ts);
          _t._totalTimeseriesAddedInGraph += 1;
        }
      });
    this._addPlotBandWhenDataNotExists();
    this._setMarginsChart();
    this._chart.addCredits({
      enabled: true,
      text: dataProvider,
      href: '',
      style: {
        cursor: 'arrow',
        fontSize: '16px',
      },
    });
    this._chart.redraw();
  }

  _addLevelSerie(level) {
    const _t = this;
    let data = [];
    let value;
    const xMin = this._fromTime.valueOf();
    const ZINDEX_INIT_LEVELS = 50;
    level.id = level.id ? level.id : level.name.trim();
    level.color = level.color === 'undefined' || !level.color ? this._getNextAppropiateColor() : level.color;
    const key = level[this.unitParam] || level.unit || level.ts_unitsymbol;
    if (!key) {
      return;
    }
    if (this._axisKeys.indexOf(key) === -1) {
      this._addYAxis(key);
    }
    const formatter = new Intl.NumberFormat(this.i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let absValue;
 
     if(level.value){
        value =level.value;
        data = [[xMin+1000*60*60*12, value]]  
        if(level.value !=="" && level.isAbsolute && this.graphConfig.absoluteOffset){
          value = parseFloat(this.graphConfig.absoluteOffset)-level.value;
          data = [[xMin+1000*60*60*12, value]]  
          absValue = level.value;
        }
    }
    if(level.tsShortName.includes("Cmd.Alarm") && value ===9999 || value ===999){
      data = [];
      value = null;
    }
    const plotOptions = {
      color: level.type === 'band' ? Utils.hexToRgbA(level.color, '0.1') : level.color,
      id: level.id,
      width: 2,
      value,
      zIndex: ZINDEX_INIT_LEVELS,
     label: {
              text: `${level.chartLabel || level.label} (${level.isAbsolute ? formatter.format(absValue):  formatter.format(value)}${level.unit})`,
              style:{color: level.color},
              align: level.isAbsolute ? 'right' :'left',
              x: level.isAbsolute ? -10 : 10
          }
    };

    const yAxisKeyIndex = this._axisKeys.indexOf(key) === -1 ? 0 : this._axisKeys.indexOf(key);
    const yAxis = this._chart.yAxis[yAxisKeyIndex];



    if(level.valuetype ==="eventlist"){
      let max = -Infinity;
      level.data.forEach((item, i) => {
          const val = item[1];
          const ts = item[0];
          max =  Math.max(max, val);
          yAxis.removePlotLine(level.id);
          yAxis.removePlotBand(`${level.id}_${i}`);
          yAxis.addPlotLine({
            color: level.type === 'band' ? Utils.hexToRgbA(level.color, '0.1') : level.color,
            id: `${level.id}_${i}`,
            width: 2,
            value: val,
            zIndex: ZINDEX_INIT_LEVELS,
            dashStyle: 'dash',
            label: {
                text:  `${moment(ts).format("L")} (${val} ${level.unit})`,
                align: "right",
                x:-20
              }
       });
      })
             data.push([xMin, max]);
    } else if (level.type === 'band') {
      plotOptions.from = level.from;
      plotOptions.to = level.to;
      yAxis.removePlotBand(level.id);
      yAxis.addPlotBand(plotOptions);
    } else if (level.valuetype === "LTV" || level.valuetype === "aggregation") {
      data= level.data;
      plotOptions.value = value;
      yAxis.removePlotLine(level.id);
      yAxis.addPlotLine(plotOptions);
    }

    const serieObj = {
      id: level.id,
      color: level.color,
      name: level.name,
      marker: { enabled: false },
      yAxis: key,
      isLevelSerie: true,
      enableMouseTracking: true,
      data,
      legendIndex: ZINDEX_INIT_LEVELS,
      zIndex: ZINDEX_INIT_LEVELS,
      events: {
        legendItemClick: evt => {
          if (evt.target.visible) {
            level.type === 'band' ? _t._chart.yAxis[yAxisKeyIndex].removePlotBand(level.id) : _t._chart.yAxis[yAxisKeyIndex].removePlotLine(level.id);
          } else {
            level.type === 'band' ? _t._chart.yAxis[yAxisKeyIndex].addPlotBand(plotOptions) : _t._chart.yAxis[yAxisKeyIndex].addPlotLine(plotOptions);
          }
          level.visible = !evt.target.visible;
        },
      },
      visible: Utils.checkBooleanValue(level.visible, true),
    };
    if (level.type === 'ts') {
      this._timeseries &&
        this._timeseries.forEach(() => {
          _t._totalTimeseriesAddedInGraph += 1;
        });
      this.levels &&
        this.levels.forEach(() => {
          _t._totalTimeseriesAddedInGraph += 1;
        });
    }
    this._chart.addSeries(serieObj);
    if (this._chart.get(level.id) && value !== '') {
      if (Utils.checkBooleanValue(level.visible, true)) {
        level.type === 'band' ? _t._chart.yAxis[yAxisKeyIndex].addPlotBand(plotOptions) : _t._chart.yAxis[yAxisKeyIndex].addPlotLine(plotOptions);
      } else {
        level.type === 'band' ? _t._chart.yAxis[yAxisKeyIndex].removePlotBand(level.id) : _t._chart.yAxis[yAxisKeyIndex].removePlotLine(level.id);
      }
    }
  }

  _createTimeseriesFromZone(zone, ts, gridData) {
    const _t = this;
    const newData = [];
    ts.color = zone.color || null;
    ts.marker = zone.marker || ts.marker || {};
    ts.name = zone.name || ts.name;
    ts.lineWidth = zone.lineWidth || 0;
    ts.gapSize = zone.gapSize || 1;

    gridData.forEach(elem => {
      if (elem && elem[`${zone.property}_${ts[_t._idProperty]}`] && includes(zone.values, `${elem[`${zone.property}_${ts[_t._idProperty]}`]}`)) {
        newData.push([elem.id, Number(elem[ts[_t._idProperty]])]);
      } else if (isEmpty(zone.values)) {
        const checkCondition = every(_t.zones[ts.ts_shortname], obj => {
          return !includes(obj.values, `${elem[`${zone.property}_${ts[_t._idProperty]}`]}`);
        });
        if (checkCondition) {
          newData.push([elem.id, Number(elem[ts[_t._idProperty]])]);
        }
      }
    });
    ts.data = newData;
    return ts;
  }

  _drawSeriesChart(ts) {
    const _t = this;
    const _id = ts[this._idProperty];
    const key = ts[this.unitParam] || ts.unit || ts.ts_unitsymbol || '';
    const yAxisLabel = `${ts.stationparameter_name} [${key}]`
    this._addYAxis(key, yAxisLabel);
    if (this.zones && this.zones[ts.ts_shortname] && this.gridData) {
      forEach(this.zones[ts.ts_shortname], zone => {
        const timeserieZone = _t._createTimeseriesFromZone(zone, ts, _t.gridData);
        const chartConfig = { id: _id, yAxis: key, ...timeserieZone };
        this._chart.addSeries(chartConfig, false);
      });
    } else {
      const chartConfig = { id: _id, yAxis: key, ...ts };
      if (chartConfig && chartConfig.chartConfig && chartConfig.chartConfig.type === 'column' && chartConfig.isEquidistant && chartConfig.resolution) {
        this._chart.update({ plotOptions: { column: { pointRange: moment.duration(chartConfig.resolution).asMilliseconds() } } }, false);
      }
      if(chartConfig.type !== "arearange") {
        chartConfig.gapSize = this.graphConfig.gapSize || 1;
      }
      this._chart.addSeries(chartConfig, false);
    }
  }

  _getNextAppropiateColor() {
    const colorIndex = this._totalTimeseriesAddedInGraph;
    return Highcharts.getOptions().colors[colorIndex % Highcharts.getOptions().colors.length];
  }

  _addAbsoluteAxis(yAxisObj, key, graphConfig) {
    const offset = parseFloat(graphConfig.absoluteOffset);
    const formatter = new Intl.NumberFormat(this.i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (!this._chart.get(`${key}_absolute`)) {
      this._chart.addAxis(
        {
          id: `${key}_absolute`,
          title: { text: graphConfig.absoluteYAxisLabel || `${key} absolute` },
          opposite: true,
          minPadding: 0.05,
          maxPadding: 0.05,
          showLastLabel: false,
          linkedTo: this._axisKeys.indexOf(key),
          labels: {
            formatter() {
              return formatter.format(offset - this.value );
            },
          },
        },
        false,
        false,
      );
    }
  }

  _addYAxis(key, yAxisLabel) {
    const _t = this;
    this._chart.yAxis.forEach(axis => {
      const yAxisObj = {
        id: key,
        opposite: _t._getYAxisOpposite(),
        title: { text: this.graphConfig?.yAxis?.title?.text || yAxisLabel },
        min: axis.min,
        max: axis.max,
        showEmpty: false,
      };

      if (_t._axisKeys.length === 0) {
        _t._chart.yAxis[0].update(yAxisObj);
        _t._axisKeys.push(key);
        if (this.graphConfig.absoluteOffset > 0) {
          _t._addAbsoluteAxis(yAxisObj, key, this.graphConfig);
        }
      } else if (_t._axisKeys.indexOf(key) === -1) {
        _t._chart.addAxis(yAxisObj);
        _t._axisKeys.push(key);
      }
    });
  }

  _checkEndKeyPress(keyCode) {
    const _t = this;
    clearTimeout(this._timeout);

    switch (keyCode) {
      case KEYS.RIGHT_ARROW:
      case KEYS.LEFT_ARROW:
      case KEYS.UP_ARROW:
      case KEYS.DOWN_ARROW:
      case UP_MOUSE_WHEEL:
      case DOWN_MOUSE_WHEEL:
        _t._countAndSaveKeyPress(keyCode);
        break;
      default:
        _t._lastKeyCode = null;
        _t._lastKeyCodeTimesPress = null;
        break;
    }

    this._timeout = setTimeout(() => {
      if (_t._lastKeyCode && _t._lastKeyCodeTimesPress) {
        switch (_t._lastKeyCode) {
          case KEYS.RIGHT_ARROW:
            _t._setPeriodFromEvent('RIGHT', false);
            break;
          case KEYS.LEFT_ARROW:
            _t._setPeriodFromEvent('LEFT', false);
            break;
          case KEYS.UP_ARROW:
            _t._setPeriodFromEvent('UP', false);
            break;
          case UP_MOUSE_WHEEL:
            _t._setPeriodFromEvent('UP', true);
            break;
          case KEYS.DOWN_ARROW:
            _t._setPeriodFromEvent('DOWN', false);
            break;
          case DOWN_MOUSE_WHEEL:
            _t._setPeriodFromEvent('DOWN', true);
            break;
          default:
            _t._setPeriodFromEvent('DOWN', true);
            break;
        }
      }
      _t._lastKeyCode = null;
    }, DELAY_TIME);
  }

  _countAndSaveKeyPress(keyCode) {

    if (keyCode === this._lastKeyCode) {
      this._lastKeyCodeTimesPress += 1;
    } else {
      this._lastKeyCodeTimesPress = 1;
      this._lastKeyCode = keyCode;
    }
  }

  _createChart() {
    const _graphConfig = Object.assign(Utils.graphConfig(this), this.graphConfig);
    this._chart = Highcharts.StockChart(this.renderRoot.querySelector('#graph'), _graphConfig);
    this._chart.xAxis[0].setExtremes(this._fromTime.valueOf(), this._toTime.valueOf(), false, true);
  }

  resize() {
    this._chart && this._chart.reflow();
  }

  _createFalseButtonToBeFocused(node) {
    this._falseButton = document.createElement('button'); // Create a <button> element
    this._falseButton.style = 'width:0; height:0; padding:0; background:transparent; border:none; color:transparent;';
    node.appendChild(this._falseButton);
    this._falseButton.focus();
  }

  _getYAxisOpposite() {
    let _opposite = false;
    let left = 0;
    let right = 0;
    if (this._chart && this._chart.yAxis) {
      this._chart.yAxis.forEach(axis => {
        if (axis.opposite === true) {
          right += 1;
        } else {
          left += 1;
        }
      });
      _opposite = left > right;
    }
    return _opposite;
  }

  _getZoomType() {
    return this.zoomEnabled ? 'x' : 'none';
  }

  _setFirstDates() {
    this._fromTime = this._fromTime || moment().startOf('year');
    this._toTime = this._toTime || moment().endOf('year');
  }

  _setMarginsChart() {
    const MOBILE_MARGIN = 50;
    const NORMAL_MARGIN = 100;
    const MARGIN = window.innerWidth <= PHONE_BREAK_POINT ? MOBILE_MARGIN : NORMAL_MARGIN;
    let left = 0;
    let right = 0;
    if (this._chart && this._chart.yAxis) {
      this._chart.yAxis.forEach(axis => {
        if (axis.opposite === true) {
          right += 1;
        } else {
          left += 1;
        }
      });
    }
    this._chart.update({
      chart: {
        marginLeft: left * MARGIN, // px
        marginRight: right * MARGIN, // px
      },
    });
  }

  _setPeriod(from, to) {
    this._fromTime = moment(from);
    this._toTime = moment(to);

    this.dispatchEvent(
      new CustomEvent(this.PERIOD_SELECTED, {
        bubbles: true,
        detail: {
          from: this._fromTime.toISOString(),
          to: this._toTime.toISOString(),
        },
      }),
    );
    if (this._chart) {
      this._chart.xAxis[0].setExtremes(this._fromTime.valueOf(), this._toTime.valueOf());
    }
  }

  _setPeriodFromEvent(direction, isMouseWheel) {
    let auxFrom;
    let auxTo;
    let currentDiff;
    let mult;
    if (direction === 'UP' || direction === 'DOWN') {
      auxFrom = this._fromTime;
      auxTo = this._toTime;
      this._lastKeyCodeTimesPress = this._lastKeyCodeTimesPress >4 ? 4 :this._lastKeyCodeTimesPress;
      while (this._lastKeyCodeTimesPress > 0) {
        const currentFrom = auxFrom;
        const currentTo = auxTo;
        if (direction === 'UP') {
          if (isMouseWheel && this._currentMouseTimeStamp) {
            auxFrom = moment(currentFrom + (this._currentMouseTimeStamp - currentFrom) / ZOOM_DIVIDER);
            auxTo = moment(currentTo - (currentTo - this._currentMouseTimeStamp) / ZOOM_DIVIDER);
          } else {
            auxFrom = moment(currentFrom + (currentTo - currentFrom) / ZOOM_DIVIDER / ZOOM_DIVIDER);
            auxTo = moment(currentTo - (currentTo - currentFrom) / ZOOM_DIVIDER / ZOOM_DIVIDER);
          }
        } else if (isMouseWheel && this._currentMouseTimeStamp) {
          auxFrom = moment(currentFrom - (this._currentMouseTimeStamp - currentFrom) / ZOOM_DIVIDER);
          auxTo = moment(currentTo + (currentTo - this._currentMouseTimeStamp) / ZOOM_DIVIDER);
        } else {
          auxFrom = moment(currentFrom - (currentTo - currentFrom) / ZOOM_DIVIDER / ZOOM_DIVIDER);
          auxTo = moment(currentTo + (currentTo - currentFrom) / ZOOM_DIVIDER / ZOOM_DIVIDER);
        }
        this._lastKeyCodeTimesPress -= 1;
      }
    } else if (direction === 'LEFT' || direction === 'RIGHT') {
      mult = direction === 'LEFT' ? -1 : 1;
      currentDiff = this._toTime.diff(this._fromTime) * mult;
      auxFrom = this._fromTime.add(currentDiff * this._lastKeyCodeTimesPress).format();
      auxTo = this._toTime.add(currentDiff * this._lastKeyCodeTimesPress).format();
    }
    if(auxFrom<this.xMinDate){
      auxFrom = this.xMinDate;
    }
    
    if(auxTo> this.xMaxDate){
      auxTo = this.xMaxDate;
    }
    this._setPeriod(auxFrom, auxTo);
  }

  render() {
    if (this._chart) {
      const _graphConfig = Object.assign(Utils.graphConfig(this), this.graphConfig);
      this._chart.update(_graphConfig);
    }
    this._addSeries();
    // language=html
    return html` <div id="graph"></div> `;
  }

  updated() {
    if (this._chart) {
      this._chart.reflow();
    }
  }
}

customElements.define('ki-chart', KiChart);
