/* eslint-disable no-empty */
import { html, LitElement } from 'lit-element';
import '@giswebgroup/ki-wcp-base/src/components/ki-icon/ki-icon';
import autoResize from '@giswebgroup/ki-wcp-base/src/decorators/autoResize';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import moment from 'moment/moment';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import { orderBy } from 'lodash-es';
import nls from '../../locales/index.nls';
import style from './ki-station-alarm.css';
// import TagStyleConfig from '../../views/ki-station-view/TagStyleConfig';

export default
@autoResize()
@i18n(nls)
class KiStationAlarm extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  constructor(){
    super();
    this.__$loaderCount = 1;
  }

  static get properties() {
    return {
      catchments: { type: Array },
      catchmentsStyles: { type: Array },
    };
  }

  render() {
    return html`
      ${this._renderLoader()}
      <div class="containerNode">
        <div class="header"">${this.i18n.t('catchment_alarm_status')}      <ki-icon class="close" icon="ki ki-times" @click="${this.close}"></ki-icon></div>
        <div class="bodyNode">
            ${this.catchments.length ? this.statusMessages.map(
              item => {
                const tag = this.catchmentsStyles[item.__tag] || this.catchmentsStyles.Normal;
                return html`
                <div>
                  <div style="background-color:${tag.marker.backgroundColor};" class="leftColumn ${item.__tag==="_default" ? 'hidden' : ''}">
                    <div style="color:rgba(29,29,29,1);font-weight:bold;" class="alarmLevel">${tag.name}</div>
                  </div>
                  <div class="rightColumn">
                    <div class="top">
                      <div class="stationName">${item.station_name}</div>
                      <div class="timestamp">${item.catchmentAlarmTimestamp}</div>
                    </div>
                    <div class="content">
                      <div class="message">${item.catchmentAlarmMessage}</div>
                      <div class="blank"></div>
                    </div>
                  </div>
                </div>
              `},
            ): this.showBeforeLoaded()}
         </div>
    </div>
    `;
  }

  showBeforeLoaded(){
    return this.loading ? html`` : html`{<div class="nomessage">Pas d'avertissement<div>`;
  }

  close() {
    this.dispatchEvent(
      new CustomEvent('close', {
        detail: {},
      }),
    );
  }

  set catchments(val){
    const oldVal = this._catchments;
    this._catchments = val;
    if(val.length){
      this.__$loaderCount = 0;
    }
    this.requestUpdate('catchments', oldVal);
  }

  get catchments(){
    return this._catchments;
  }

  get statusMessages(){

      return orderBy(this.catchments.filter(item => item.catchmentAlarmMessage), ["web_classes", "timestamp"],["desc", "desc"])
  }
}

customElements.define('ki-station-alarm', KiStationAlarm);
