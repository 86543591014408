/* eslint-disable */
import queryParam from '@giswebgroup/ki-wcp-base/src/decorators/queryParam';
import localStorage from '@giswebgroup/ki-wcp-base/src/decorators/localStorage';
import query from '@giswebgroup/ki-wcp-base/src/decorators/query';
import computed from '@giswebgroup/ki-wcp-base/src/decorators/computed';
import traverse from '@giswebgroup/ki-wcp-base/src/common/traverse';
import autoRun from '@giswebgroup/ki-wcp-base/src/decorators/autoRun';
import fetchData from './StationsStore';
import Mix from '@giswebgroup/ki-wcp-base/src/common/Mix';
import PropertyDefaultValue from '@giswebgroup/ki-wcp-base/src/common/PropertyDefaultValue';
import { translate, template } from '@giswebgroup/ki-wcp-base/src/common/util';
import { remove } from 'lodash-es/array';
import { getLayer } from '@giswebgroup/ki-wcp-water/src/common/ki-ol-layer-generator';
import {countBy, keyBy} from "lodash-es";
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import {TopoJSON} from "ol/format";
export default function f(claz) {
  return class extends Mix(claz, PropertyDefaultValue) {
    // element properties
    static get properties() {
      return {
        kiwisPath: { type: String },
        config: { type: String },
        usergroups: { type: String, initial: () => 'web_s0_public' },
        currentStationId: { type: String },

        // for inner
        __catchmentGeometries: { type: Array, initial: () => []},
        __stations: { type: Array, initial: () => [] },
        __catchments: { type: Array, initial: () => [] },
        __calculatedCatchmentStatus: { type: Array, initial: () => [] },
        __stationFilter: { type: Array, initial: () => () => true },
        __sidebarStackKey: { type: String }, // null means hide
        __dynamicViewTemplate: { type: Object },
        __selectedStations: { type: Array, initial: () => [] },
        selectedTs: { type: Array, initial: () => [] },
      };
    }
    
    @queryParam('topic')
    topic;

    @queryParam('station')
    currentStationId;

    @queryParam('catchment')
    currentCatchmentId;

    @queryParam('mode')
    mapOrTable = 'map';

    @queryParam('search')
    searchContent;

    //

    @localStorage()
    __mapBackGroundLayer = 'walonmap';

    @localStorage({ type: Array })
    __favouriteStationIds = [];

    //

    // todo move query to render part
    @query('#nav-stack')
    __navStack;

    // data relationships
    // computed


    @computed(['config', 'usergroups'])
    get __configuration() {
      let config = {
        stationLabelTemplate: '${station_no} | ${station_name} | ${river_name}',
        stationLabelFormats: [
          {
            label: '$i18n{station_name}  | $i18n{station_no}  | $i18n{river_name}',
            value: '${station_name} | ${station_no} | ${river_name}',
          },
          {
            label: '$i18n{station_name} | $i18n{river_name} | $i18n{station_no}',
            value: '${station_name} | ${river_name} | ${station_no} ',
          },
          {
            label: '$i18n{station_no}  | $i18n{station_name}  | $i18n{river_name}',
            value: '${station_no} | ${station_name} | ${river_name}',
          },
          {
            label: '$i18n{station_no}  | $i18n{river_name}  | $i18n{station_name}',
            value: '${station_no} | ${river_name} | ${station_name}',
          },
          {
            label: '$i18n{river_name} | $i18n{station_no} | $i18n{station_name}',
            value: '${river_name} | ${station_no} | ${station_name}',
          },
          {
            label: '$i18n{river_name} | $i18n{station_name} | $i18n{station_no}',
            value: '${river_name} | ${station_name} | ${station_no}',
          },
        ],
        ...{ classification: {}, stationColumns: [] }, // default
        ...this.__config,
      };
      config.stationLabelTemplate = config.stationLabelFormats[0].value;
      config = translate(config, this.i18n);

      // TODO
      this._homeExtent = [-6415, 11566, 346998, 179437];
      this.tagStyles.config = config.stationClassification || { tags: [] };
      this.catchmentTagStyles.config = config.catchmentClassification || { tags: [] };
      this.catchmentClassificationInfocrue.config = config.catchmentClassificationInfocrue || { tags: [] };
      return config;
    }

    @computed('__configuration')
    get filterFieldList() {
      let filterFieldList = this.__configuration.filterFieldList || this.__configuration.stationColumns.map(col => col.field).filter(c => c.indexOf('__') !== 0);
      filterFieldList = filterFieldList.map(f => this.__configuration.stationColumns.find(c => c.field === f)).filter(f => !!f);
      return filterFieldList;
    }

    @computed('__configuration')
    get mapLayers(){
      return this.__configuration.mapLayers.map(c => getLayer(c));
    }


    @computed(['__stationFilter', '__stations'])
    get __filteredStations() {
      return this.__stations.filter(this.__stationFilter);
    }

    @computed(['__filteredStations', '__favouriteStationIds'])
    get __favouriteStations() {
      return this.__filteredStations.filter(station => {
        return this.__favouriteStationIds.find(stationId => stationId === station.station_id) !== undefined;
      });
    }

    @computed(['currentStationId', '__stations'])
    get __currentStation() {
      return this.__stations?.find(s => this.currentStationId === `${s.site_no}/${s.station_no}`) || '';
    }

    @computed(['currentCatchmentId', '__catchments'])
    get __currentCatchment() {
      return this.__catchments?.find(s => this.currentCatchmentId === s.BASSIN_INFOCRUE_NO) || '';
    }

    @computed('__configuration')
    get localStorageId() {
      return `ki-station-view-${this.__configuration.groupId}`;
    }

    @computed(['__catchments', v => v.i18n.language])
    get __switcherOptions() {
      const tabs = [
        { label: 'Map', value: 'map' },
        { label: 'Table', value: 'table' },
      ];
      if (this.__configuration.enableCatchmentByStatus && this.__catchments.some(item => item.catchmentAlarmMessage)) {
        const alarmCounter = countBy(
          this.__catchments.filter(item => item.__tag!== "Normal" && item.__tag!=="_default"),
          '__tag',
        );
        const badges = [];
        for (const [key, value] of Object.entries(alarmCounter)) {
          badges.push({
            label:key,
            count: value,
            bgcolor: `${this.catchmentClassificationInfocrue.catchmentsStyles[key]?.marker.backgroundColor}`,
            color: `${this.catchmentClassificationInfocrue.catchmentsStyles[key]?.marker.color}`,
          })
        }
        tabs.push({ icon: this.__catchments.some(item => item.catchmentAlarmMessage) ? "ki ki-exclamation-triangle": null, label: 'Announcement', value: 'announcement', badges: badges });
      }
      return tabs.map(tab => {
        return { label: this.i18n.t(tab.label), value: tab.value, badges: tab.badges, icon: tab.icon };
      });
    }

    @computed(['kiwisPath', '__currentStation', v => v.__configuration?.stationDetailMenu])
    get stationMenu() {
      if (this.kiwisPath && this.__configuration && this.__currentStation) {
        const args = { kiwisPath: this.kiwisUrl, ...this.__currentStation };
        return traverse(this.__configuration.stationDetailMenu, s => template(s, args));
      }
      return [];
    }

    get _view() {
      return {
        extent:  [-6415, 11566, 346998, 179437],
        center: [170291, 95502],
        projection: 'EPSG:31370',
        zoom: 8.861281435871305,
        constrainResolution: false,
        showFullExtent:true,
      };
    }
    // async update


    getStationConfig(stationId){
      const stationObj = this.__stations?.find(s => stationId === `${s.station_id}`) || null;
      const args = { kiwisPath: this.kiwisUrl, ...stationObj}
      return traverse(this.__configuration.stationDetailMenu, s => template(s, args));
    }

    loadGeometries(){
      return fetch('/services/kiwcp/data/catchments.json').then(response => {
        return response.json();
      }).then((geometries) => {
        const features = new TopoJSON().readFeatures(geometries)
        features.forEach(feat => {
          feat.setId(feat.get("ID_INFOCR").toString())
        })
        this.__catchmentGeometries = features;
      })
    }

    @autoRun(['config'])
    loadData() {
      if (!this.__configuration.groupName || !this.kiwisPath) {
        return;
      }
      console.log("fetching data",this.kiwisUrl,this.__configuration.groupName)
      // eslint-disable-next-line consistent-return
      return fetchData({ ...this.__configuration, kiwisPath: this.kiwisUrl, i18n: this.i18n, usergroup:this.usergroups}).then(({ stations, catchments, calculatedCatchmentStatus }) => {
          this.loadGeometries().then(()=>{
            this.__stations = stations;
            this.__catchments = catchments;
            this.__calculatedCatchmentStatus = calculatedCatchmentStatus;
            this.postDataLoad();
            this.refreshCount = this.refreshCount + 1;
        })
      });
    }

    //TODO
    @autoRun(['__currentCatchment', '__currentStation', 'mapOrTable'])
    _updateSidebarStackKey() {
      if (this.__currentCatchment) {
        this.__sidebarStackKey = 'catchment-detail';
      } else if (this.__currentStation) {
        this.__sidebarStackKey = 'station-bar';
      } else if (this.mapOrTable === 'announcement') {
        this.__sidebarStackKey = 'announcement';
      } else if(this.__navStack){
        this.__sidebarStackKey = this.__navStack.history.pop();
      }
    }

    _toggleFavourite(e) {
      const { isFavourite, stationId } = e.detail;
      if (isFavourite) {
        this.__favouriteStationIds = [...this.__favouriteStationIds, stationId];
      } else {
        this.__favouriteStationIds = remove(this.__favouriteStationIds, id => id !== stationId);
      }
    }

    _isFavourite(stationId) {
      return this.__favouriteStationIds.indexOf(stationId) >= 0;
    }

    async downloadStations() {
      const kiwis = new KIWIS({ basePath: this.kiwisUrl });
      const ug = this.usergroups || "web_s0_public";
      const web_g0_public = [{
        "field":"station_no",
        "label":"Code PZ de la station",
      }, {
        "field":"CODESO",
        "label":"Code ESO de la station",
      }, {
        "field":"MASESO_WEB",
        "label":"Code de la masse d'eau souterraine"
    }, {
      "field":"MASESO_LIBELLE",
      "label":"Nom de la masse d'eau souterraine"
    },
       {
        "field":"COMMUNE_LOCALITE", 
        "label":"Commune"
      }, {
        "field":"ISIN_LOCALITE", 
        "label":"Localité"
      }, {
        "field":"station_local_x", 
        "label":"Coordonnée X en Lambert 72 (m)"
      },  {
        "field":"station_local_y", 
        "label":"Coordonnée Y en Lambert 72 (m)"
      },  {
        "field":"station_carteasting", 
        "label":"Coordonnée X en Lambert 2008 (m)"
      },  {
        "field":"station_cartnorthing", 
        "label":"Coordonnée Y en Lambert 2008 (m)"
      },   {
        "field":"GR_EQUIPEMENT", 
        "label":"Enregistrement de la station"
      },{
        "field": "GROUND_DATUM",
        "label":"Altitude du sol (m)"
      },{
        "field": "PROFPUITS",
        "label":"Profondeur de la station (m)"
      }
    ]
  
    const web_g2_privates = web_g0_public;
  
    const web_g3= [
      {
        "field":"station_no",
        "label":"Code PZ de la station",
      }, {
        "field":"CODESO",
        "label":"Code ESO de la station",
      }, {
        "field": "station_name",
        "label":"Nom d l'ouvrage"
      },{
        "field":"MASESO_WEB",
        "label":"Code de la masse d'eau souterraine"
      }, {
        "field":"MASESO_LIBELLE",
        "label":"Nom de la masse d'eau souterraine"
      },
       {
        "field":"COMMUNE_LOCALITE", 
        "label":"Commune"
      }, {
        "field":"ISIN_LOCALITE", 
        "label":"Localité"
      }, {
        "field":"station_local_x", 
        "label":"Coordonnée X en Lambert 72 (m)"
      },  {
        "field":"station_local_y", 
        "label":"Coordonnée Y en Lambert 72 (m)"
      },  {
        "field":"station_carteasting", 
        "label":"Coordonnée X en Lambert 2008 (m)"
      },  {
        "field":"station_cartnorthing", 
        "label":"Coordonnée Y en Lambert 2008 (m)"
      }, {
        "field": "station_latitude",
        "label": "Coordonnée X en ETRS 89"
      },
      {
        "field": "station_longitude",
        "label": "Coordonnée Y en ETRS 89"
      },{
        "field": "GROUND_DATUM",
        "label":"Altitude du sol (m)"
      },
     {
      "field": "REPERE_DE_MESURE_VALEUR",
      "label":"Altitude du dernier repère de mesure (m)"
    },{
      "field": "REPERE_MESURE_VALIDITE",
      "label":"Date de début de validité du dernier repère"
    },
    {
      "field": "DESCREPRERE",
      "label": "Description du repère"
    },{
      "field": "PROFPUITS",
      "label":"Profondeur de la station (m)"
    },{
      "field": "DEBCREPINE",
      "label":"Profondeur du sommet des crépines (m)"
    },{
      "field": "FINCREPINE",
      "label":"Profondeur de la base des crépines (m)"
    },{
      "field": "NAPAQUI",
      "label":"Nappe aquifère"
    },{
      "field":"GR_EQUIPEMENT", 
      "label":"Enregistrement de la station"
    }]
          
    const web_g1_deso =[
      {
        "field":"station_no",
        "label":"Code PZ de la station",
      }, {
        "field":"CODESO",
        "label":"Code ESO de la station",
      }, {
        "field": "station_name",
        "label":"Nom d l'ouvrage"
      },{
        "field":"MASESO_WEB",
        "label":"Code de la masse d'eau souterraine"
      }, {
        "field":"MASESO_LIBELLE",
        "label":"Nom de la masse d'eau souterraine"
      },
       {
        "field":"COMMUNE_LOCALITE", 
        "label":"Commune"
      }, {
        "field":"ISIN_LOCALITE", 
        "label":"Localité"
      }, {
        "field":"station_local_x", 
        "label":"Coordonnée X en Lambert 72 (m)"
      },  {
        "field":"station_local_y", 
        "label":"Coordonnée Y en Lambert 72 (m)"
      },  {
        "field":"station_carteasting", 
        "label":"Coordonnée X en Lambert 2008 (m)"
      },  {
        "field":"station_cartnorthing", 
        "label":"Coordonnée Y en Lambert 2008 (m)"
      }, {
        "field": "station_latitude",
        "label": "Coordonnée X en ETRS 89"
      },
      {
        "field": "station_longitude",
        "label": "Coordonnée Y en ETRS 89"
      },{
        "field": "GROUND_DATUM",
        "label":"Altitude du sol (m)"
      },
     {
      "field": "REPERE_DE_MESURE_VALEUR",
      "label":"Altitude du dernier repère de mesure (m)"
    },{
      "field": "REPERE_MESURE_VALIDITE",
      "label":"Date de début de validité du dernier repère"
    },
    {
      "field": "DESCREPRERE",
      "label": "Description du repère"
    },{
      "field": "PROFPUITS",
      "label":"Profondeur de la station (m)"
    },{
      "field": "DEBCREPINE",
      "label":"Profondeur du sommet des crépines (m)"
    },{
      "field": "FINCREPINE",
      "label":"Profondeur de la base des crépines (m)"
    },
    {
      "field": "TYPPUITS",
      "label":"Type de puits"
    },
    {
      "field": "TYPE_STATION",
      "label":"Type de station"
    }, 
    {
      "field": "NAPAQUI",
      "label":"Nappe aquifère"
    },
     {
      "field": "GR_RM_RESDCE",
      "label":"Réseau DCE"
    },
    {
     "field": "GR_IMPACTNETWORK",
     "label":"Réseau d'impact"
   },
   {
    "field": "GR_RM_TOURNEE",
    "label":"N° de tournée"
  },
   {
      "field":"GR_EQUIPEMENT", 
      "label":"Enregistrement de la station"
    },
    {
       "field":"GR_EQ_RETCAPREP", 
       "label":"Retrait capteur pour réparations"
     }, {
      "field": "GR_TYPECAPTEUR",
      "label": "Type de capteur"
    },
    {
      "field": "GR_EQ_LONGCAB",
      "label": "Longueur du cable (m)"
    },
    {
      "field": "GR_EQ_PLAMES",
      "label": "Plage de mesure"
    },
    {
      "field": "GR_EQ_NSERECO",
      "label": "N° de série de l'écolog"
    },
    {
      "field": "GR_EQ_NSEORPHEUS",
      "label": "N° de série de l'orphéus"
    },
    {
      "field": "GR_EQ_NSEITC",
      "label": "N° de série de l'ITC"
    },
    {
      "field": "GR_CG_ACCES",
      "label": "Accès"
    },

    {
      field: "GR_REM",
      label: "Remarques"
    },
    {
      field: "GR_CG_INSCAM",
      label: "Inspection Caméra ISSeP"
    },
    {
      field: "GR_CG_NIVELLMENT",
      label: "Nivellement"
    },
    {
      field: "GR_EQ_MOTIMPEQ",
      label: "Motif d'impossibilité d'équipement"
    }

]

    const web_s0_public = [
      {
        field:"station_no",
        label:"Code"
      },
      {
        field:"station_name",
        label:"Nom"
      },
      {
        field:"river_name",
        label:"Rivière "
      },
      {
        field:"station_local_x",
        label:"Lambert72_X "
      },
      {
        field:"station_local_y",
        label:"Lambert72_Y "
      },
      {
        field:"station_carteasting",
        label:"Lambert2008_X"
      },
      {
        field:"station_cartnorthing",
        label:"Lambert2008_Y"
      },
      {
        field:"station_latitude",
        label:"ETRS89_X"
      },
      {
        field:"station_longitude",
        label:"ETRS89_Y"
      },
      {
        field:"BASSIN_GESTION_WEB",
        label:"Bassin Versant"
      },
      {
        field:"CATCHMENT_SIZE",
        label:"Superficie BV"
      },
      {
        field:"BV_DCE",
        label:"Bassin DCE"
      },
      {
        field:"DISTHYDRO",
        label:"District hydrographique"
      },
      {
        field:"ISIN_LOCALITE",
        label:"Localité"
      },
      {
        field:"COMMUNE_LOCALITE",
        label:"Commune"
      },
      {
        field:"PROVINCE",
        label:"Province"
      },
      {
        field:"RESEAUX",
        label:"Gestionnaire"
      },
      {
        field:"station_diary_status",
        label:"Mise en service"
      },
      {
        field:"station_gauge_datum",
        label:"Altitude actuelle du repère"
      },
    ]
  
    const dltables = {
      web_g0_public,
      web_g1_deso,
      web_g2_privates,
      web_g3,
      web_s0_public,
      web_s1_dcenn: web_s0_public,
      web_s2_spw: web_s0_public,
      web_s3: web_s0_public,
      web_s4: web_s0_public,
      web_s5: web_s0_public,
    }
    const stationIds = this.__selectedStations.length ? this.__selectedStations : this.__filteredStations.map(item => item.station_no);
    const stList = await kiwis.getStationList({returnfields: "ca_sta,all"});
    const data = stList.filter(item => stationIds.includes(item.station_no));
    let csvData = "\uFEFF";
    const nl = "\r\n"
    dltables[ug].forEach(item => {
      csvData += `"${item.label}";` 
    })
    csvData += nl;

    const getValue = function(item, line){
      let val = line[item.field] || ""
      if(item.field === "COMMUNE_LOCALITE" && line.site_no ==="DGH"){
        val = line.ISIN_COMMUNE || "";
      }
      if(item.field === "station_gauge_datum" && line.object_type_shortname.includes("PLUVIOMETRIE_DGH_STATION")){
        val = line.station_elevation || "";
      }
      return `"${val}";`
    }

    data.forEach(line => {
      dltables[ug].forEach(item => {
        csvData+= getValue(item, line)
      })
      csvData += nl;
    })

    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(csvData));
    // Generate filename
    const label = ug.includes("_g") ? "piezometrie" :"hydrometrie";
    let filename = `${label}_stations.csv`;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
 //   const csvExporter = new ExportToCsv(options);
 //   csvExporter.generateCsv(this.__stations);
    }
  };

  
}
