import * as moment from 'moment';
import { forEach, isEmpty, isNull, isUndefined } from 'lodash-es';
import Highcharts, { numberFormat } from 'highcharts';
import { contrastColor } from "@giswebgroup/ki-wcp-base/src/common/util"

const DATE_FORMAT = 'DD/MM/YYYY hh:mm';

const NONE_INTERPOLATION = 1;
const UNKNOWN_INTERPOLATION = 99;
const LINEAR_INTERPOLATION = 2;
const CONST_UNTIL_NEXT = 3;
const CONST_SINCE_PREV = 4;
const LINEAR_UNTIL_NEXT = 5;
const LINEAR_SINCE_PREV = 6;

function _addOptionToLegend(options, icon, isVisible) {
  if (isVisible) {
    const btnHide = document.createElement('span');
    btnHide.style.marginLeft = "5px";
    btnHide.style.verticalAlign = "middle";
    btnHide.style.fill = "gray";
    btnHide.title = "Fermer";
    btnHide.innerHTML = icon;

    return btnHide.outerHTML;
  }
  return '';
}

function assignColorToTimeseries(colorsArray, timeseriesArray) {
  let currIndex = 0;
  timeseriesArray &&
    timeseriesArray.forEach(ts => {
      if (ts && !ts.color) {
        ts.color = colorsArray[currIndex];
        currIndex += 1;
      }
    });
}

function hexToRgbA(hex, alpha) {
  const POS_LAST_CHAR1 = 3;
  const POS_FIRST_CHAR2 = 3;
  const POS_LAST_CHAR2 = 5;
  const POS_FIRST_CHAR3 = 5;
  const POS_LAST_CHAR3 = 7;
  const r = parseInt(hex.slice(1, POS_LAST_CHAR1), 16);
  const g = parseInt(hex.slice(POS_FIRST_CHAR2, POS_LAST_CHAR2), 16);
  const b = parseInt(hex.slice(POS_FIRST_CHAR3, POS_LAST_CHAR3), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

function calcPeriod(period) {
  const to = moment();
  const from = moment(to).subtract(moment.duration(period));
  return {
    from: from.valueOf(),
    to: to.valueOf(),
  };
}

function checkBooleanValue(value, defaultValue) {
  return isUndefined(value) || isNull(value) ? defaultValue : value;
}

function checkValidParams(item, listOfParams) {
  return listOfParams.every(param => item[param]);
}

function convertPeriod2PlainText(period) {
  let returnValue = '';
  let _per = period.substring(1);
  const small = Number.isNaN(_per.charAt(0));
  _per = small ? _per.substring(1, _per.length - 1) : _per.substring(0, _per.length - 1);
  const count = parseInt(_per, 10);
  const unit = period.charAt(period.length - 1);
  if (!small && unit === 'M') {
    returnValue = moment.duration(count, 'M').locale('en').humanize().replace(' ', '');
  } else {
    returnValue = moment.duration(count, unit.toLowerCase()).locale('en').humanize().replace(' ', '');
  }
  if (returnValue.charAt(0) === 'a') {
    returnValue = returnValue.substring(1);
    if (returnValue.charAt(0) === 'n') {
      returnValue = returnValue.substring(1);
    }
    returnValue = `1${returnValue}`;
  }
  return returnValue;
}

function dispatchEventChanges(eventName, detailObj) {
  document.dispatchEvent(
    new CustomEvent(eventName, {
      bubbles: true,
      detail: detailObj,
    }),
  );
}

function formatLegend() {
  let innerNodeHtml = this.name;

  let backgroundColor = this.visible ? this.color : '#ffffff';
  const _color = contrastColor(backgroundColor, true);
  const textColor = this.visible ? _color : hexToRgbA(_color, 0.5);
  let borderColor = this.visible ? this.color : hexToRgbA('#4a4a49', 0.5);
  if (this.color.startsWith('#')) {
    backgroundColor = this.visible ? hexToRgbA(this.color, 1) : '#fff';
    borderColor = this.visible ? hexToRgbA(this.color, 1) : hexToRgbA('#4a4a49', 0.5);
  }

  // TODO: check how to use font awesome icons here
  if(this.options.isMultiGraph && this.chart.series.length>1){
    innerNodeHtml += _addOptionToLegend(this.options, "<svg width='12px' height='12px' xmlns='http://www.w3.org/2000/svg' baseProfile='tiny' viewBox='0 0 18 18'><path d='M18 2.12L15.88 0 9 6.88 2.12 0 0 2.12 6.88 9 0 15.88 2.12 18 9 11.12 15.88 18 18 15.88 11.12 9z'/></svg>", true); // Remove button
  }
  const node = document.createElement('div');
  const customIDForClass = this.options.id ? this.options.id.replace(/[() ]/g, '') : '';
  if (customIDForClass) {
    node.classList.add(customIDForClass);
  }
  node.innerHTML = innerNodeHtml;
  node.setAttribute(
    'style',
    `font-family: Open Sans, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    font-weight:normal;padding:0.5em 1em;border-radius:50px;max-width:500px;
    color: ${textColor};
    border: 2px solid ${borderColor};
    background-color:${backgroundColor};`,
  );
  return node.outerHTML;
}

function formatNumber(num) {
  return Number(num).toFixed(2);
}

function getAddition(item) {
  const aggregationObj = item ? item.aggregation : null;
  const durationInMs = moment.duration(item.resolution, 's').asMilliseconds();
  return aggregationObj ? aggregationObj.inMs || durationInMs : durationInMs;
}

function getAggregation(resolution) {
  const plainText = convertPeriod2PlainText(resolution);
  const ms = moment.duration(resolution, 's').asMilliseconds();
  return {
    inMs: ms,
    inPlainText: plainText,
  };
}

function getChartType(aggregation) {
  return aggregation && aggregation.max_ts_path ? 'arearange' : 'line';
}

function getMillisecondsPeriod(resolution, baseTimeStamp, nextTimeStamp) {
  return baseTimeStamp && nextTimeStamp ? moment(nextTimeStamp).valueOf() - moment(baseTimeStamp).valueOf() : moment.duration(resolution, 's').asMilliseconds();
}

function getMinMaxTsPaths(tsPath, minTsPath, maxTsPath) {
  const tsParts = tsPath.split('/');
  const siteNo = tsParts[0];
  const stationNo = tsParts[1];
  const stationparameterNo = tsParts[2];
  return {
    // eslint-disable-next-line no-template-curly-in-string
    maxTsPath: maxTsPath.replace('${site_no}/${station_no}/${stationparameter_no}', `${siteNo}/${stationNo}/${stationparameterNo}`),
    // eslint-disable-next-line no-template-curly-in-string
    minTsPath: minTsPath.replace('${site_no}/${station_no}/${stationparameter_no}', `${siteNo}/${stationNo}/${stationparameterNo}`),
  };
}

function getTooltipFormatter(event, absoluteOffset, dateFormat) {
  const sObjet = [];
  const points = event.chart.hoverPoints;
  points.forEach(point => {
    const _isEquidistant = isUndefined(point.series.options.isEquidistant) || isNull(point.series.options.isEquidistant) ? false : point.series.options.isEquidistant;
    let date = moment(point.x).format(dateFormat || DATE_FORMAT);
    if (_isEquidistant) {
      const addition = getAddition(point.series.options);
      const pointWithAgg = moment(point.x + addition).format(dateFormat || DATE_FORMAT);
      date = addition < 0 ? `${pointWithAgg} \u2014 ${date}` : `${date} \u2014 ${pointWithAgg}`;
    }
    let _seriePoint = `<span style='color:${point.color}'>\u25CF</span> ${point.series.name} : `;
    if (point.series.type === 'arearange' || point.series.options.type === 'arearange') {
      _seriePoint += `<b>${numberFormat(point.low)} \u2014 ${numberFormat(point.high)}</b>`;
    } else {
      _seriePoint += `<b>${numberFormat(point.y)}</b>`;
      if (absoluteOffset) {
        _seriePoint += ` (${numberFormat(absoluteOffset - point.y )})`;
      }
    }
    sObjet.push({
      date: `${date}<br/>`,
      seriePoint: _seriePoint,
      additionalInfo: `<span style="padding-left: 0.5em;margin-bottom: 0.1em;" class="additionalInfo ${point.series.options.id}"></span><br/>`,
    });
  });
  sObjet.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
  let tooltipInfo = '';
  let currDate = '';
  sObjet.forEach(elem => {
    if (currDate !== elem.date) {
      currDate = elem.date;
      tooltipInfo += elem.date;
    }
    tooltipInfo += elem.seriePoint;
    tooltipInfo += elem.additionalInfo;
  });
  return tooltipInfo;
}

function graphConfig(context) {
  Highcharts.setOptions({ lang: { decimalPoint: ',' } });
  return {
    time: {
      useUTC: false,
    },
    title: { text: null },
    chart: {
      animation: false,
      resetZoomButton: {
        position: {
          align: 'center',
          verticalAlign: 'top',
        },
      },
      events: {
        selection: event => {
          if (event && event.xAxis && event.xAxis[0]) {
            const extremesObject = event.xAxis[0];
            context._setPeriod(moment(extremesObject.min).toISOString(), moment(extremesObject.max).toISOString());
          }
          return false;
        },
        contextmenu: event => {
          console.log('click');
          event.preventDefault();
          return false;
        },
      },
      zoomType: context._getZoomType(),
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: context.maxNumPoints || 5000,
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    plotOptions: {
      column: {
        stickyTracking: true,
        pointPadding: 0,
        groupPadding: 0,
        pointPlacement: 'between',
        borderWidth: 1,
        pointRange: 24 * 3600 * 1000,
      },
      series: {
        states: {
          hover:{
            lineWidthPlus:0
          },
          inactive: {
            opacity: 1
          }
        },
        marker: {
          enabled: true,
          radius: 2,
        },
        allowPointSelect: true,
        dataGrouping: { enabled: false },
        events: {
          legendItemClick: evt => {
            evt.preventDefault();
            if(evt.browserEvent.target.tagName !=="DIV"){

              context.dispatchEvent(
                new CustomEvent('removetimeseries', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    ts_path: evt.target.options.ts_path
                  },
                }),
              );
              evt.target.remove();
            } else  if (evt.target.visible) {
              evt.target.hide();
            } else {
              evt.target.show();
            }
            context._addPlotBandWhenDataNotExists();
          },
          show: event => {
            dispatchEventChanges('showts', event);
          },
          hide: event => {
            dispatchEventChanges('hidets', event);
          },
        },
      },
    },
    series: [{ data: [] }],
    tooltip: {
      backgroundColor: '#FFFFFF',
      borderColor: 'rgba(255, 255, 255, 0)',
      formatter: event => {
        return this && typeof this.getTooltipFormatter.onChange !== 'undefined' ? this.getTooltipFormatter(event) : getTooltipFormatter(event);
      },
      useHTML: true,
      shared: true,
      split: false,
    },
    legend: {
      itemStyle: {
        width: 500
      },
      enabled: true,
      itemDistance: 8,
      itemMarginBottom: 8,
      useHTML: true,
      symbolWidth: 0.0001,
      symbolHeight: 0.0001,
      symbolRadius: 0.0001,
      labelFormatter: formatLegend,
    },
    exporting: {
      chartOptions: {
        legend: {
          useHTML: false,
          itemStyle: {"color": "#333333", "cursor": "pointer", "fontSize": "8px", "fontWeight": "bold", "textOverflow": "ellipsis"},
          symbolWidth: undefined,
          symbolHeight: undefined,
          itemMarginTop: 3,
          itemMarginBottom: 3,
          margin:2,
          symbolRadius: undefined,
          labelFormatter: function(){
            return this.name
          },
        }
      },
      enabled: false,
      fallbackToExportServer: false,
    },
    xAxis: {
      type: 'datetime',
      ordinal: false,
      tickPixelInterval: 140,
      labels: {
        align: 'left',
        formatter() {
          let diffExtremes = 0;
          if (this.chart && this.chart.xAxis[0]) {
            const xAxisExtremes = this.chart.xAxis[0].getExtremes();
            if (xAxisExtremes.min && xAxisExtremes.max) {
              diffExtremes = xAxisExtremes.max - xAxisExtremes.min;
            }
          }
          let formatter = 'L';
          if (moment.duration(diffExtremes) > 0 && moment.duration(diffExtremes) < moment.duration(3, 'days')) {
            formatter = 'L LT';
          }
          return moment(this.value).format(formatter);
        },
      },
    },
    yAxis: {
      minPadding: 0.05,
      maxPadding: 0.05,
      showLastLabel: true,
      labels: {
        formatter() {
          let diffExtremes = 0;
          if (this.axis) {
            const yAxisExtremes = this.axis.getExtremes();
            if (yAxisExtremes.min !== null && yAxisExtremes.min !== undefined && yAxisExtremes.max !== null && yAxisExtremes.max !== undefined) {
              diffExtremes = yAxisExtremes.max - yAxisExtremes.min;
            }
          }
          const decimals = diffExtremes > 100 ? 0 : 2;
          return numberFormat(this.value, decimals);
        },
      },
    },
  };
}


function insertInterpolationType(tsObj, interpolationType, chartType) {
  const lastDigitDivider = 10;
  const _interpolationType = interpolationType % lastDigitDivider;
  if (!tsObj) {
    tsObj = {};
  }
  if (!tsObj.chartConfig) {
    tsObj.chartConfig = {};
  }

  if (chartType === 'line') {
    if (_interpolationType === UNKNOWN_INTERPOLATION || _interpolationType === NONE_INTERPOLATION) {
      tsObj.chartConfig.lineWidth = 0;
      tsObj.chartConfig.marker = {
        enabled: true,
      };
      tsObj.chartConfig.states = { hover: { lineWidthPlus: 0 } };
    } else if (_interpolationType === LINEAR_INTERPOLATION) {
      tsObj.chartConfig.step = null;
    } else if (_interpolationType === CONST_UNTIL_NEXT) {
      tsObj.chartConfig.step =  null;
    } else if (_interpolationType === CONST_SINCE_PREV) {
      tsObj.chartConfig.step =  null;
    } else if (_interpolationType === LINEAR_UNTIL_NEXT) {
      tsObj.chartConfig.step =  null;
    } else if (_interpolationType === LINEAR_SINCE_PREV) {
      tsObj.chartConfig.step = null;
    }
  } else {
    tsObj.chartConfig = {
      type: chartType,
      step: null,
      marker: {
        enabled: false,
        radius: 1,
      },
    };
  }
}

function isEquidistant(resolution) {
  return !isUndefined(resolution) && !isEmpty(resolution);
}

function joinObjects(...args) {
  const idMap = {};
  // Iterate over arguments
  for (let i = 0; i < args.length; i += 1) {
    // Iterate over individual argument arrays
    for (let j = 0; j < args[i].length; j += 1) {
      const currentID = args[i][j].timestamp;
      if (!idMap[currentID]) {
        idMap[currentID] = {};
      }
      // Iterate over properties of objects in arrays
      forEach(args[i][j], (value, key) => {
        idMap[currentID][key] = value;
      });
    }
  }
  // push properties of idMap into an array
  const newArray = [];
  forEach(idMap, value => {
    newArray.push(value);
  });
  return newArray;
}

function joinTsPaths(tsPathArray, tsPathProperty) {
  const _tsPathStr = [];
  tsPathArray.forEach(ts => {
    return _tsPathStr.push(ts[tsPathProperty]);
  });
  return _tsPathStr.join();
}

function msToTime(msValue) {
  const MS_IN_S = 1000;
  const MIN_IN_H = 60;
  let s = msValue;
  const ms = s % MS_IN_S;
  s = (s - ms) / MS_IN_S;
  const secs = s % MIN_IN_H;
  s = (s - secs) / MIN_IN_H;
  const mins = s % MIN_IN_H;
  const hrs = (s - mins) / MIN_IN_H;
  return `${hrs}:${mins}:${secs}`;
}

function reOrderResolutionArray(resolution, resolutionArray) {
  if (!isEmpty(resolution)) {
    return resolutionArray;
  }
  resolutionArray.push(resolution);
  const auxResolutionNumber = [];
  const copyResolutionIndex = resolutionArray;
  resolutionArray = [];
  copyResolutionIndex.forEach(currentResolution => {
    auxResolutionNumber.push(moment.duration(currentResolution, 's').asMilliseconds());
  });
  auxResolutionNumber.sort((a, b) => {
    return a - b;
  });
  auxResolutionNumber.forEach(currentResolutionNumber => {
    copyResolutionIndex.forEach(currentResolution => {
      if (moment.duration(currentResolution, 's').asMilliseconds() === currentResolutionNumber) {
        resolutionArray.push(currentResolution);
      }
    });
  });
  return resolutionArray;
}

export {
  DATE_FORMAT,
  assignColorToTimeseries,
  calcPeriod,
  checkBooleanValue,
  checkValidParams,
  convertPeriod2PlainText,
  dispatchEventChanges,
  formatLegend,
  formatNumber,
  getAddition,
  getAggregation,
  getChartType,
  getMillisecondsPeriod,
  getMinMaxTsPaths,
  getTooltipFormatter,
  graphConfig,
  hexToRgbA,
  insertInterpolationType,
  isEquidistant,
  joinObjects,
  joinTsPaths,
  msToTime,
  reOrderResolutionArray,
};
