import { html, LitElement } from 'lit-element';
import * as moment from 'moment';
import { find, forEach } from 'lodash-es';
import Highcharts, { numberFormat } from 'highcharts';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import KiToast from '@giswebgroup/ki-wcp-base/src/components/ki-toast/ki-toast';
import autoResize from '@giswebgroup/ki-wcp-base/src/decorators/autoResize';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import "@giswebgroup/ki-wcp-base/src/components/ki-popup/ki-popup";
import "@giswebgroup/ki-wcp-base/src/components/ki-list/ki-list";
import more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import offlineexporting from 'highcharts/modules/offline-exporting';
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import KiGraphConfiguration from '../ki-graph-configuration/ki-graph-configuration';
import style from './ki-annual-graph.css';
import "./ki-annual-table"
import nls from '../../locales/index.nls';
import { formatLegend } from '../ki-chart/ki-chart-utils';

const CACHE_HEADER_TIME = 300;
const DATE_FORMAT = 'DD/MM';
const DECIMALS = 3;

more(Highcharts);
exporting(Highcharts);
offlineexporting(Highcharts);

export default
@autoResize()
@i18n(nls)
class KiAnnualGraph extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  _axisKeys;

  _dataGridCols;

  _dataGridData;

  _defaultPeriod;

  _idProperty;

  _mainTimeserie;

  _opposite;

  _tsPathProperty;

  _tableValues;

  _tableVisible;

  additionalTsPaths;

  alarms;

  defaultYear;

  indicators;

  kiwisUrl;

  fromParam;

  toParam;

  tsPath;

  showGraphConfig;

  constructor() {
    super();
    Highcharts.setOptions({
      colors: ['#dc375d', '#3f86cd', '#679f2e', '#e27600', '#4a4a49', '#336598', '#e4c72b', '#00907a', '#ed583c', '#0689AE'],
    });
    this._axisKeys = [];
    this._dataGridCols = [];
    this._tsToLoad = [];
    this._dataGridData = [];
    this._defaultPeriod = 'P1Y';
    this._idProperty = 'ts_id';
    this._mainTimeserie = 'ts_id';
    this._opposite = false;
    this._tsPathProperty = 'ts_path';
    this._tableValues = {};
    this._tableVisible = false;
    this._timeoutDelay = null;
    this.downloadItems = this.downloadItems || [
      {
        label: 'PNG',
        value: 'png',
      },
    ];
    this.alarms = [];
    this.additionalTsPaths = [];
    this.defaultYear = this.getAttribute('defaultYear') || moment().format('YYYY');
    this.indicators = [];
    this.kiwisUrl = '';
    this.fromParam = 'from';
    this.toParam = 'to';
    this.tsPath = null;
    this.graphConfig = this.graphConfig || {};
    this.showGraphConfig = true;
    this.showAlarms = this.showAlarms || 'true';
    this.showIndicators = this.showIndicators || 'true';
    this.numberFormatter = new Intl.NumberFormat(this.i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
 
  }

  static get properties() {
    return {
      additionalTsPaths: { type: Array },
      alarms: { type: Array },
      defaultYear: { type: String },
      indicators: { type: Array },
      kiwisUrl: { type: String },
      tsPath: { type: String },
      showGraphConfig: { type: Boolean },
      showAlarms: { type: String },
      showIndicators: { type: String },
    };
  }

  static calcPeriod(period) {
    const to = moment();
    const from = moment(to).subtract(moment.duration(period));
    return {
      from: from.valueOf(),
      to: to.valueOf(),
    };
  }

  static getYAxisKey(item) {
    return KiAnnualGraph.templater`${'ts_unitsymbol'}`(item);
  }

  static joinObjects(...args) {
    const idMap = {};
    // Iterate over arguments
    for (let i = 0; i < args.length; i += 1) {
      // Iterate over individual argument arrays
      for (let j = 0; j < args[i].length; j += 1) {
        const currentID = args[i][j].timestamp;
        if (!idMap[currentID]) {
          idMap[currentID] = {};
        }
        // Iterate over properties of objects in arrays
        forEach(args[i][j], (value, key) => {
          idMap[currentID][key] = value;
        });
      }
    }
    // push properties of idMap into an array
    const newArray = [];
    forEach(idMap, value => {
      newArray.push(value);
    });
    return newArray;
  }

  static templater(strings, ...keys) {
    return data => {
      const temp = strings.slice();
      keys.forEach((key, i) => {
        // eslint-disable-next-line operator-assignment
        temp[i] = temp[i] + data[key];
      });
      return temp.join('');
    };
  }

  closeElement() {
    this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  toggleTable() {
    const classToHideBtn = this.renderRoot.querySelector('#table-btn').classList.contains('selected') ? 'selected' : 'empty';
    const classToShowBtn = this.renderRoot.querySelector('#table-btn').classList.contains('selected') ? 'empty' : 'selected';
    this.renderRoot.querySelector('#table-btn').classList.remove(classToHideBtn);
    this.renderRoot.querySelector('#table-btn').classList.add(classToShowBtn);

    this.dispatchEvent(new CustomEvent('toggletable', {}));
  }

  downloadData() {
    const downloadBtn = this.renderRoot.querySelector('#download-btn');
    if (!downloadBtn) {
      return;
    }
    const classToHideBtn = downloadBtn.classList.contains('selected') ? 'selected' : 'empty';
    const classToShowBtn = downloadBtn.classList.contains('selected') ? 'empty' : 'selected';
    downloadBtn.classList.remove(classToHideBtn);
    downloadBtn.classList.add(classToShowBtn);
    if (classToShowBtn === 'selected') {
      this.renderRoot.querySelector('#download-popup').show({});
    } else {
      this.renderRoot.querySelector('#download-popup').hide({});
    }
  }

  clickDownloadItem(format) {
   if (format === 'png') {
      if (this._chart) {
        const title = this.renderRoot.querySelector('#infoMainTimeserie').innerText.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        this._chart.exportChartLocal({
          format: 'png',
          filename: `${title}-${moment().format('YYYY-MM-DD_HH-mm-ss')}`,
        });
      }
    }
  }

  render() {
    // language=html
    return html`
      ${this._renderLoader()}
      <div class="navBar">
        <div class="leftHeader">
          <div id="infoMainTimeserie"></div>
        </div>
        <div class="rightHeader">
          <div id="graphconfigopt"></div>
          <div class="separator">|</div>
          <ki-icon-btn id="download-btn" title="${this.i18n.t('download')}" icon="ki ki-arrow-alt-to-bottom" @click="${this.downloadData}"></ki-icon-btn>
          <ki-popup for="download-btn" top="40px" left="0" id="download-popup">
            <ki-list>
              ${this.downloadItems.map(downloadItem => {
                return html`<ki-list-item @click="${() => this.clickDownloadItem(downloadItem.value)}"> ${downloadItem.label} </ki-list-item>`;
              })}
            </ki-list>
          </ki-popup>

          <ki-icon-btn id="table-btn" style="display:none" icon="ki ki-table" title="${this.i18n.t('showHideTable')}" @click="${this.toggleTable}"></ki-icon-btn>
          <ki-icon-btn id="close-btn" icon="ki ki-times" title="${this.i18n.t('closeView')}" @click="${this.closeElement}"></ki-icon-btn>
        </div>
      </div>
      <div class="containerNode">
        <div class="graphWrapper">
          <div id="graph"></div>
        </div>
        <div class="handler hidden"></div>
        <div id="tableWrapper" class="tableWrapper hidden">
          <ki-annual-table id="dataGrid" .columns="${this._dataGridCols}" .data="${this._dataGridData}"></ki-annual-table>
        </div>
      </div>
    `;
  }

  firstUpdated() {
    const _t = this;
    Highcharts.setOptions({ lang: { decimalPoint: ',' } });
    const handler = this.renderRoot.querySelector('.handler');
    if (handler) {
      const wrapper = this.renderRoot.querySelector('.containerNode');
      const graphWrapper = this.renderRoot.querySelector('.graphWrapper');
      const tableWrapper = this.renderRoot.querySelector('.tableWrapper');
      let isHandlerDragging = false;

      handler.addEventListener('mousedown', e => {
        if (e.target === handler) {
          isHandlerDragging = true;
        }
      });

      this._mousemoveEvent = e => {
        if (!isHandlerDragging) {
          return false;
        }
        const containerOffsetLeft = wrapper.offsetLeft || e.target.clientWidth - wrapper.clientWidth;
        const pointerRelativeXpos = e.clientX - containerOffsetLeft;
        const boxAminWidth = 60;

        tableWrapper.style.flex = 1;
        graphWrapper.style.width = `${Math.max(boxAminWidth, pointerRelativeXpos - 8)}px`;
        graphWrapper.style.flexGrow = 0;
        _t._chart.reflow();
        return true;
      };
      this.addEventListener('mousemove', this._mousemoveEvent);
      this._mouseupEvent = e => () => {
        isHandlerDragging = false;
      };
      this.addEventListener('mouseup', this._mouseupEvent);
    }

    this._getTsPaths().then(list => {
      _t._mainTimeserie = find(list, ts => {
        return ts[_t._tsPathProperty] === _t.tsPath;
      });
      if (!_t._mainTimeserie) {
        KiToast.showToast({ type: 'error', content: _t.i18n.t('noTSDetected') });
        return;
      }
      const minMaxDates = this._getMinMaxDates(_t._mainTimeserie);
      if (!_t.defaultYear || _t.defaultYear > minMaxDates.max.year()) {
        _t.defaultYear = minMaxDates.max.year();
      }
      _t._createNavBar(_t._mainTimeserie);
      _t._createChart();
//      _t._addTimeserie(_t._mainTimeserie[_t._tsPathProperty], _t.defaultYear, null);
      _t._emitDatesChanged();
    });
  }


  disconnectedCallback() {
    this.removeEventListener('addalarm', this._addalarmEvent);
    this.removeEventListener('addindicator', this._addindicatorEvent);
    this.removeEventListener('selectedyear', this._selectedyearEvent);
    this._mousemoveEvent && this.removeEventListener('mousemove', this._mousemoveEvent);
    this._mouseupEvent && this.removeEventListener('mouseup', this._mouseupEvent);
    super.disconnectedCallback();
  }

  _addIndicator(values) {
    const _t = this;
    if (values.isSelected) {
      if (values.tsPath) {
        this.promiseLoader(
          this._getLastTsData(values.tsPath).then(
            promData => {
              const item = Array.isArray(promData) ? promData[0] : promData;
              _t._printIndicator(item, values);
            },
            () => {
              KiToast.showToast({
                type: 'error',
                content: 'The requested values of the time series are currently not available',
              });
            },
          ),
        );
      } else {
        this._printIndicator(values, values);
      }
    } else {
      this._chart.yAxis.forEach(yAxis => {
        yAxis.removePlotLine(`${values.tsId}`);
      });
      this._removeTimeserie(`${values.tsId}`);
    }
  }

  _addTimeserie(tsToLoad) {
    const _t = this;
    this.promiseLoader(
      this._getTsData(tsToLoad)
        .then(promData => {
          let dataProvider = '';
          promData.forEach((tsitem, i)=>{
            const tsItemConfig = tsToLoad[i];

              const item = Array.isArray(tsitem) ? tsitem[0] : tsitem;
              const mySeries = this._getSeries(item, tsItemConfig.year);
              const yAxisKey = item[_t.unitParam] || item.ts_unitsymbol || _t._mainTimeserie[this.unitParam] || _t._mainTimeserie.ts_unitsymbol;
              const tsName = tsItemConfig.label;
              const mySeriesObj = {
                id: `${item[_t._idProperty]}_${tsItemConfig.year}`,
                name: tsName,
                data: mySeries,
                yAxis: yAxisKey,
                marker: {
                  enabled: false,
                }
              };
              _t._addYAxis(item, yAxisKey);
              _t._chart.addSeries(mySeriesObj, false);
            
          })

          _t._reloadDataGrid();
          dataProvider = dataProvider === '' && _t._mainTimeserie && _t._mainTimeserie.ADMINISTRATEUR ? `${this.i18n.t('dataProvider')}: ${_t._mainTimeserie.ADMINISTRATEUR}` : '';
          _t._chart.addCredits({
            enabled: true,
            text: dataProvider,
            href: '',
            style: {
              cursor: 'arrow',
              fontSize: '16px',
            },
          });
          _t._chart.redraw();
          _t._chart.reflow();
        })
        .catch(() => {
          KiToast.showToast({ type: 'error', content: 'Request Failed' });
        }),
    );
  }

  _addTimeserieEvent(evt, tsPath, tsId, type) {
    if (!evt || !evt.detail || !evt.detail.values || !this._mainTimeserie) {
      return;
    }
    if (evt.detail.values.isSelected) {
      const tsName = evt.detail.values.label || this.i18n.t(evt.detail.values.tsShortName) || null;
      clearTimeout(this._timeoutDelay)
      this._tsToLoad.push({
        year: evt.detail.values.yearSelected, 
        valuetype: evt.detail.values.valuetype, 
        label: tsName || evt.detail.values.yearSelected,
        tsPath,
        to: evt.detail.values.to
      })
      this._timeoutDelay = setTimeout(()=>{
          this._addTimeserie(this._tsToLoad);
          this._tsToLoad = [];
      }, 100)
      
    } else {
      this._removeTimeserie(tsId);
    }
  }

  _addYAxis(item, yAxisKey) {
    const textYAxis = KiAnnualGraph.getYAxisKey(this._mainTimeserie);

    const yAxisObj = {
      id: yAxisKey,
      opposite: this._opposite,
      title: { text: `${item.stationparameter_name} [${textYAxis}]` },
      labels: {
        formatter() {
          let diffExtremes = 0;
          if (this.axis) {
            const yAxisExtremes = this.axis.getExtremes();
            if (yAxisExtremes.min !== null && yAxisExtremes.min !== undefined && yAxisExtremes.max !== null && yAxisExtremes.max !== undefined) {
              diffExtremes = yAxisExtremes.max - yAxisExtremes.min;
            }
          }
          const decimals = diffExtremes > 100 ? 0 : 2;
          return numberFormat(this.value, decimals);
        },
      },
    };
    if (this?.graphConfig?.yAxis) {
      Object.assign(yAxisObj, this.graphConfig.yAxis);
    }
    if (this._axisKeys.length === 0) {
      this._chart.yAxis[0].update(yAxisObj);
      this._axisKeys.push(yAxisKey);
      if (this.graphConfig.absoluteOffset > 0) {
        this._addAbsoluteAxis(yAxisObj, yAxisKey, this.graphConfig);
      }
      this._opposite = !this._opposite;
    } else if (this._axisKeys.indexOf(yAxisKey) === -1) {
      this._chart.addAxis(yAxisObj);
    }
  }


  _addAbsoluteAxis(yAxisObj, key, graphConfig) {
    const offset = parseFloat(graphConfig.absoluteOffset);
    const formatter = new Intl.NumberFormat(this.i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (!this._chart.get(`${key}_absolute`)) {
      this._chart.addAxis(
        {
          id: `${key}_absolute`,
          title: { text: graphConfig.absoluteYAxisLabel || `${key} absolute` },
          opposite: true,
          minPadding: 0.05,
          maxPadding: 0.05,
          showLastLabel: false,
          linkedTo: this._axisKeys.indexOf(key),
          labels: {
            formatter() {
              return formatter.format(offset - this.value );
            },
          },
        },
        false,
        false,
      );
    }
  }

  _checkValidFromAndToParams(item) {
    return item[this.fromParam] && item[this.toParam];
  }

  _createChart() {
    this._chart = new Highcharts.Chart(this.renderRoot.querySelector('#graph'), this._getChartConfig());
  }

  resize() {
    this._chart && this._chart.reflow();
  }

  _createNavBar(tsPathData) {
    if(!tsPathData){
      return;
    }
    const _t = this;
    const navBarNode = this.renderRoot.querySelector('#graphconfigopt');
    const minMaxObj = this._getMinMaxDates(tsPathData);
    const minFrom = minMaxObj.min.year();
    const maxFrom = minMaxObj.max.year();
    if (!_t.defaultYear || _t.defaultYear > maxFrom) {
      _t.defaultYear = maxFrom;
    }
    const navBar = new KiGraphConfiguration({
      alarms: this.alarms,
      initOpen: 'false',
      showYears: 'true',
      indicators: this.indicators,
      kiwisUrl: this.kiwisUrl,
      tsPath: tsPathData[this._tsPathProperty],
      timeserieObj: tsPathData,
      from: minFrom,
      to: maxFrom,
      selectRange: false,
      // selectedRange: { from: this.defaultYear, to: this.defaultYear },
      showGraphConfig: this.showGraphConfig,
      showAlarms: this.showAlarms,
      showIndicators: this.showIndicators,
    });
    navBarNode.appendChild(navBar);
    this._setInfoTsValue(`${tsPathData.station_no || ''} | ${tsPathData.station_name} | ${tsPathData.parametertype_name}`);
    navBar.addEventListener('toggletable', () => {
      _t._toggleTable();
    });
    navBar.updateComplete.then(() => {
      _t._emitDatesChanged();
    });
    this._addalarmEvent = e => {
      if (!e || !e.detail || !e.detail.values || !_t._mainTimeserie) {
        return;
      }
      _t._addIndicator(e.detail.values);
    };
    navBar.addEventListener('addalarm', this._addalarmEvent);

    this._selectedyearEvent = e => {
      const tsPath = _t._mainTimeserie[_t._tsPathProperty];
      const tsId = `${_t._mainTimeserie[_t._idProperty]}_${e.detail.values.yearSelected}`;
      _t._addTimeserieEvent(e, tsPath, tsId);
    };

    navBar.addEventListener('selectedyear', this._selectedyearEvent);

    this._addindicatorEvent = e => {
      if (e.detail.values.type === 'ts') {
        const tsId = `${e.detail.values.tsId}_${e.detail.values.yearSelected}`;
        _t._addTimeserieEvent(e, e.detail.values.tsPath, tsId, "indicator");
      } else {
        _t._addIndicator(e.detail.values);
      }
    };
    navBar.addEventListener('addindicator', this._addindicatorEvent);
  }

  _emitDatesChanged() {
    this.dispatchEvent(
      new CustomEvent('wiskidateschanged', {
        bubbles: true,
        detail: {
          fromTime: moment(this.defaultYear, 'YYYY'),
          toTime: moment(this.defaultYear, 'YYYY'),
        },
      }),
    );
  }

  _getChartConfig() {
    const absoluteOffset = this.graphConfig.absoluteOffset > 0 ? parseFloat(this.graphConfig.absoluteOffset) : null;
    const formatter = new Intl.NumberFormat(this.i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const categoriesText = [
      this.i18n.t('Jan'),
      this.i18n.t('Feb'),
      this.i18n.t('Mar'),
      this.i18n.t('Apr'),
      this.i18n.t('May'),
      this.i18n.t('Jun'),
      this.i18n.t('Jul'),
      this.i18n.t('Aug'),
      this.i18n.t('Sep'),
      this.i18n.t('Oct'),
      this.i18n.t('Nov'),
      this.i18n.t('Dec'),
    ];
    return {
      title: { text: null },
      chart: {
        renderTo: this.renderRoot.querySelector('#graph'),
        type: 'line',
        zoomType: 'x',
      },
      credits: { enabled: false },
      rangeSelector: { enabled: false },
      navigator: { enabled: false },
      scrollbar: { enabled: false },
      legend: {
        enabled: true,
        itemDistance: 8,
        itemMarginBottom: 8,
        useHTML: true,
        symbolWidth: 0.0001,
        symbolHeight: 0.0001,
        symbolRadius: 0.0001,
        labelFormatter: formatLegend,
      },
      exporting: { 
        enabled: false,
        chartOptions: {
          legend: {
            useHTML: false,
            itemStyle: {"color": "#333333", "cursor": "pointer", "fontSize": "8px", "fontWeight": "bold", "textOverflow": "ellipsis"},
            symbolWidth: undefined,
            symbolHeight: undefined,
            itemMarginTop: 3,
            itemMarginBottom: 3,
            margin:2,
            symbolRadius: undefined,
            labelFormatter: function(){
              return this.name
            },
          }
        },
       },
      plotOptions: { series: { dataGrouping: { enabled: false } } },
      xAxis: {
        type: 'datetime',
    //    tickInterval: 30 * 24 * 3600 * 1000, // 1 month
        ordinal: false,
        min: moment(this.defaultYear, 'YYYY').startOf('year').valueOf(),
        max: moment(this.defaultYear, 'YYYY').endOf('year').valueOf(),
        labels: {
          align: 'left',
          formatter: evt => {
            return categoriesText[moment(evt.value).month()];
          },
        },
      },
      tooltip: {
        pointFormatter () {
            let tt =  `<span style="color:'${this.series.color}'"></span> ${this.series.name}: <b>${formatter.format(this.y)}</b>`
            tt = absoluteOffset ? `${tt } ( ${formatter.format(absoluteOffset-this.y)})` : tt
            return  `${tt}<br/>`;
        },
        valueDecimals: DECIMALS,
        backgroundColor: '#FFFFFF',
        borderColor: 'rgba(255, 255, 255, 0)',
        shared: true,
        split: false,
        xDateFormat: '%d/%m',
      },
    };
  }

  _getColumnsGrid() {
    const columns = [
      {
        field: 'timestamp',
        label: this.i18n.t('time_stamp'),
      },
    ];
    this._chart.series.forEach(collect => {
      if (!collect.options.isLevelSerie) {
        columns.push({
          id: collect.options.id,
          field: collect.options.id,
          label: collect.name,
        });
      }
    });
    return columns;
  }

  _getDataGrid() {
    const args = [];
    forEach(this._tableValues, (timeseries, tsId) => {
      const json = [];
      forEach(timeseries, values => {
        const temp = {};
        temp.id = values.timestamp;
        temp.timestamp = moment(values.timestamp).format(DATE_FORMAT);
        temp[tsId] = values.value;
        json.push(temp);
      });
      args.push(json);
    });
    let toSort = KiAnnualGraph.joinObjects.apply(KiAnnualGraph.joinObjects, args); // Pass all elements in args as parameter list
    toSort = toSort.sort((a, b) => {
      return a.id - b.id;
    });
    const dataGridNode = this.renderRoot.querySelector('#dataGrid');
    if (dataGridNode && dataGridNode.shadowRoot) {
      const shadow = dataGridNode.shadowRoot;
      const shadowStyle = document.createElement('style');
      shadowStyle.textContent = `
        .cell {
          line-height: 25px;
        }
      `;
      shadow.appendChild(shadowStyle);
    }
    return toSort;
  }

  _getMinMaxDates(tsPathData) {
    const _t = this;
    let min = null;
    let max = null;
    if (_t._checkValidFromAndToParams(tsPathData)) {
      if (!min || min > moment(tsPathData[_t.fromParam]).valueOf()) {
        min = moment(tsPathData[_t.fromParam]).valueOf();
      }
      if (!max || max < moment(tsPathData[_t.toParam]).valueOf()) {
        max = moment(tsPathData[_t.toParam]).valueOf();
      }
    } else {
      const period = KiAnnualGraph.calcPeriod(_t._defaultPeriod);
      if (!min || min > period.from) {
        min = period.from;
      }
      if (!max || max < period.to) {
        max = period.to;
      }
    }
    return {
      min: moment(min),
      max: moment(max),
    };
  }

  _getSeries(data, year) {
    const _t = this;
    const mySeries = [];
    const dataColumns = data && data.columns ? data.columns.toLowerCase().split(',') : [];
    const timestampIndex = dataColumns.indexOf('timestamp');
    const valueIndex = dataColumns.indexOf('minimum') === -1 ? dataColumns.indexOf('value') : dataColumns.indexOf('minimum');
    let id = '';


    if (data && data.data) {
      id = `${data[this._idProperty]}_${year}`;
      this._tableValues[id] = {};
      const cleandata= []; 
      data.data.forEach((item, i) => {
        if(item[3] === -1){
          if(i> 0 && data.data[i+1]){
            const prev = data.data[i-1];
            const next = data.data[i+1];
            if(prev[3] !== 161 && prev[3] !== 162 && prev[3] !== 165 && next[3] !== 161 && next[3] !== 162 && next[3] !== 165){
              cleandata.push(item)
            }
          }
        }else{
          cleandata.push(item)
        }

      })
      cleandata.forEach(collect => {
        const timestampObj = moment(collect[timestampIndex]).set('year', _t.defaultYear);
        const tableObj = {
          id: timestampObj.valueOf(),
          timestamp: timestampObj.valueOf(),
          value: collect[valueIndex] === undefined || collect[valueIndex] === null ? null : numberFormat(collect[valueIndex], DECIMALS),
        };
        mySeries.push([timestampObj.valueOf(), collect[valueIndex]]);
        if (collect[valueIndex] !== null) {
          _t._tableValues[id][timestampObj.valueOf()] = tableObj;
        }
      });
    }
    if (mySeries && mySeries.length === 1) {
      const value = mySeries[0][1];
      const start = moment().set('year', _t.defaultYear).startOf('year');
      mySeries.unshift([start.valueOf(), value]);
      const end = moment(`${_t.defaultYear}-12-31`);

      while (!start.isSame(end)) {
        start.add(1, 'day');
        mySeries.push([start.valueOf(), value]);
        _t._tableValues[id][start.valueOf()] = {
          id: start.valueOf(),
          timestamp: start.valueOf(),
          value: numberFormat(value, DECIMALS),
        };
      }
      mySeries.push([moment().set('year', _t.defaultYear).endOf('year').valueOf(), value]);
    }
    return mySeries;
  }

  _getLastTsData(tsPath) {
    const reqObj = {
      forceCacheHeaderTime: CACHE_HEADER_TIME,
      valuesasstring: false,
      ts_path: tsPath,
      metadata: true,
      period: "complete",
      maxquality: 206,

      md_returnfields: 'station_name,station_no,ts_shortname,ts_name,ts_id,station_id,ts_path,site_no,stationparameter_name',
    };
    return new KIWIS({
      basePath: this.kiwisUrl,
    }).getTimeseriesValues(reqObj, ['Timestamp','Value','Interpolation Type','Quality Code'], 'json');
  }

  _getTsData(tsList) {
    const promises = [];
    const KiWISCon = new KIWIS({
      basePath: this.kiwisUrl,
    });

    tsList.forEach(tsitem => {
      if(!tsitem.status){
        const datesObj = tsList.valuetype ? moment(tsitem.to).subtract(1, "year") : moment(tsitem.year, 'YYYY');
        const fromDateStr = datesObj.startOf('year').toISOString();
        const toDateStr = datesObj.endOf('year').toISOString();
      
        const reqObj = {
          from: fromDateStr,
          to: toDateStr,
          forceCacheHeaderTime: CACHE_HEADER_TIME,
          valuesasstring: false,
          ts_path: tsitem.tsPath,
          maxquality: 206,
          metadata: true,
          md_returnfields: 'station_name,station_no,ts_shortname,ts_name,ts_id,station_id,ts_path,site_no,stationparameter_name',
        };
        tsitem.status = "processing"
        promises.push(KiWISCon.getTimeseriesValues(reqObj, ['Timestamp','Value','Interpolation Type','Quality Code'], 'json'))
      }
    })
    return Promise.all(promises)
  }

  _getTsPaths() {
    const returnfields = ['station_no', 'station_name', 'ts_name', 'parametertype_name', 'ts_unitsymbol', 'ts_id', 'coverage', 'ts_path', 'ts_spacing', 'ca_sta'];
    let { tsPath } = this;
    if (this.additionalTsPaths && this.additionalTsPaths.length > 0) {
      tsPath += `,${this.additionalTsPaths.join()}`;
    }
    const params = {
      ts_path: tsPath,
    };
    return new KIWIS({ basePath: this.kiwisUrl }).getTimeseriesList(params, returnfields);
  }

  _printIndicator(item, values) {
    const _t = this;
    const ZINDEX_INIT_LEVELS = 50;
    // Add YAxis
    const yAxisKey = item[this.unitParam] || item.ts_unitsymbol || this._mainTimeserie[this.unitParam] || this._mainTimeserie.ts_unitsymbol;
    this._addYAxis(item, yAxisKey);
    const yAxisKeyIndex = this._axisKeys.indexOf(yAxisKey) === -1 ? 0 : this._axisKeys.indexOf(yAxisKey);
    let value = item.data && item.data[0] ? item.data[0][1] : item.value || null;
    const color = values.color && values.color !== 'undefined' ? values.color : null;
    const yAxis = this._chart.yAxis[yAxisKeyIndex];
    // Add PlotBand
    let data = [];
    let origValue;
    if(value && values.isAbsolute && this.graphConfig.absoluteOffset){
        origValue = value;
        value = parseFloat(this.graphConfig.absoluteOffset)-value;
        data = [[new Date().getTime(), value]]  
    }

    const displayVal = values.isAbsolute ? origValue : value;
    const plotOptions = {
      color,
      id: item[this._idProperty],
      width: 2,
      value,
      zIndex: ZINDEX_INIT_LEVELS,     
      label: {
        align: values.isAbsolute ? 'right' :'left',
        text: `${values.label} (${this.numberFormatter.format(displayVal)}${yAxisKey})`,
        style:{color},
        x: values.isAbsolute ? -10 : 10
    }
    };
    if(values.valuetype ==="eventlist"){
      let max = -Infinity;
      item.data.forEach((event, i) => {
          const val = event[1];
          const ts = event[0];
          max =  Math.max(max, event);
          yAxis.removePlotLine(values.id);
          yAxis.removePlotBand(`${values.id}_${i}`);
          yAxis.addPlotLine({
            color,
            id: `${values.id}_${i}`,
            width: 2,
            value: val,
            dashStyle: 'dash',
            label: {
                text:  `${moment(ts).format("L")} (${this.numberFormatter.format(val)} ${values.unit})`,
                align: "right",
                x:-20
              }
       });
      })
      data = [[new Date().getTime(), max]];
    }else{
      if(item.ts_shortname.includes("Cmd.Alarm") && value ===9999 || value ===999){
        value = null;
      }

      this._chart.yAxis[yAxisKeyIndex].removePlotLine(item[this._idProperty]);
      this._chart.yAxis[yAxisKeyIndex].addPlotLine(plotOptions);
      data = [[new Date().getTime(), value]]
  }
    // Add Timeseries
    const mySeriesObj = {
      id: `${item[this._idProperty]}`,
      name: `${values.label || values.name || this.i18n.t(item.ts_shortname) || item.ts_shortname }`,
      data,
      marker: { enabled: false },
      yAxis: yAxisKey,
      isLevelSerie: true,
      events: {
        legendItemClick: evt => {
          if (evt.target.visible) {
            _t._chart.yAxis[yAxisKeyIndex].removePlotLine(item[_t._idProperty]);
          } else {
            _t._chart.yAxis[yAxisKeyIndex].addPlotLine(plotOptions);
          }
        },
      },
    };
    if (values.color && values.color !== 'undefined') {
      mySeriesObj.color = values.color;
    }else{
      mySeriesObj.color = "#999999";
    }
    this._chart.addSeries(mySeriesObj, false);
    this._chart.redraw();
    this._chart.reflow();
  }

  _reloadDataGrid() {
    this._dataGridCols = this._getColumnsGrid();
    this._dataGridData = this._getDataGrid();
    this.requestUpdate();
  }

  _removeTimeserie(id) {
    const serieToRemove = this._chart.get(id);
    if (serieToRemove) {
      serieToRemove.remove();
      this._reloadDataGrid();
    }
  }

  _toggleTable() {
    const classToHide = this.renderRoot.querySelector('#tableWrapper').classList.contains('hidden') ? 'hidden' : 'visible';
    const classToShow = this.renderRoot.querySelector('#tableWrapper').classList.contains('hidden') ? 'visible' : 'hidden';
    this.renderRoot.querySelector('#tableWrapper').classList.remove(classToHide);
    this.renderRoot.querySelector('#tableWrapper').classList.add(classToShow);

    this.renderRoot.querySelector('.graphWrapper').style.flexGrow = classToShow === 'visible' ? 0 : 1;

    this.renderRoot.querySelector('.handler').classList.remove(classToHide);
    this.renderRoot.querySelector('.handler').classList.add(classToShow);

    this._tableVisible = !this._tableVisible;
    this._chart.reflow();
  }

  _setInfoTsValue(val) {
    const infoTs = this.renderRoot.querySelector('#infoMainTimeserie');
    const leftHeaderNode = this.renderRoot.querySelector('.leftHeader');
    infoTs.innerHTML = val;
    leftHeaderNode.innerHTML += `<paper-tooltip for='infoMainTimeserie'>${val}</paper-tooltip>`;
  }
}

customElements.define('ki-annual-graph', KiAnnualGraph);
