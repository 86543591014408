import { html } from 'lit-element';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import moment from 'moment';
import KiStationInfo from '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-info';
import nls from '../../locales/index.nls';

export default
@i18n(nls)
class KiWcpWalhydroPopupPrecip extends KiStationInfo {
  _stationTmpl(station) {
    let dataprovider = station.ADMINISTRATEUR;
    if(station.site_no ==="DGH"){
      dataprovider = " SPW - MI"
    }else if(station.site_no ==="DCENN"){
      dataprovider = " SPW - ARNE"
    }
    return html`<div class="station-name">${station.station_name}</div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell label">${this.i18n.t('precipp_24h')}</div>
          <div class="cell">${station.ts_value != null ? `${this.valFormatter.format(station.ts_value)} ${station.ts_unitsymbol}` : '-'}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('precipp_1h')}</div>
          <div class="cell">${station.value1H != null ? `${this.valFormatter.format(station.value1H)} ${station.ts_unitsymbol}` : '-'}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('precipp_6h')}</div>
          <div class="cell">${station.value6H != null ? `${this.valFormatter.format(station.value6H)} ${station.ts_unitsymbol}` : '-'}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('precipp_3d')}</div>
          <div class="cell">${station.value3D != null ? `${this.valFormatter.format(station.value3D)} ${station.ts_unitsymbol}` : '-'}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('precipp_15d')}</div>
          <div class="cell">${station.value15D != null ? `${this.valFormatter.format(station.value15D)} ${station.ts_unitsymbol}` : '-'}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('last_measure')}</div>
          <div class="cell">${(station.timestamp && moment(station.timestamp).format('L LT')) || '-'}</div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('dataProvider')}</div>
          <div class="cell">${dataprovider}</div>
        </div>
      </div>`;
  }
}

customElements.define('ki-wcp-walhydro-popup-precip', KiWcpWalhydroPopupPrecip);
