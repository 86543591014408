/* eslint-disable*/
import { html, LitElement } from 'lit-element';
import * as moment from 'moment';
import datepicker from 'js-datepicker/dist/datepicker.min';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import style from './ki-time-range-picker.css';
import PeriodsList from './PeriodsList';
import nls from '../../locales/index.nls';
import { last } from 'lodash-es/array';

const DATE_FORMAT = 'DD/MM/YYYY';

export default
@i18n(nls)
class KiTimeRangePicker extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  _fromTime;

  _toTime;

  allowtimebox;

  constraints;

  fromDate;

  toDate;

  showPeriodList;

  constructor() {
    super();
    this._fromTime = null;
    this._toTime = null;
    this._periodsListOptions = null;

    this.allowtimebox = false;
    this.constraints = {};
    this.fromDate = null;
    this.toDate = null;
    this.showPeriodList = null;
    this._periodSelectionQue = [];
  }

  static get properties() {
    return {
      allowtimebox: { type: Boolean },
      constraints: { type: Object },
      showPeriodList: { type: Boolean },
      periods: { type: Array },
      _periodSelectionQue: { type: Array },
    };
  }

  set from(val) {
    if (!val || (this._fromTime && this._fromTime.isSame(val))) {
      return;
    }
    this._fromTime = moment(val);
    this.requestUpdate();
  }

  get from() {
    return this._fromTime;
  }

  set to(val) {
    if (!val || (this._toTime && this._toTime.isSame(val))) {
      return;
    }
    this._toTime = moment(val);
    this.requestUpdate();
  }

  get to() {
    return this._toTime;
  }

  render() {
    // language=html
    return html`
      <div class="input-group">
        <input id="from" type="text" />
        <input id="to" type="text" />
        <ki-icon-btn id="periodListBtn" icon="ki ki-calendar" title="${this.i18n.t('selectTimePeriod')}" @click="${this.openFromCalendar}"></ki-icon-btn>
      </div>
      <ki-icon-btn id="previousPeriod" title="${this.getPreviousPeriod()}" icon="ki ki-arrow-left" @click="${this.goToPreviousSelection}" class="${this._periodSelectionQue.length < 2 ? 'disabled' : ''}"></ki-icon-btn>
      <div id="periodListSummary" @click="${this.toggleOptions}"><ki-icon icon="ki ki-calendar"></ki-icon></div>
    `;
  }

  getPreviousPeriod() {
    const length = this._periodSelectionQue.length;
    const lastPer = length > 1 ? this._periodSelectionQue[length - 2] : null;
    if (lastPer) {
      return this.i18n.t('previouslySelected') + ' ' + lastPer.from.format('L') + ' - ' + lastPer.to.format('L');
    } else {
      return this.i18n.t('previouslySelected');
    }
  }

  firstUpdated() {
    this._createInputs();
    this._setVisibilityToNodes();
    this._bindEvents();
    this._setFirstDates();
  }

  goToPreviousSelection() {
    if (this._periodSelectionQue.length > 1) {
      this._periodSelectionQue.pop();
      const lastPeriod = last(this._periodSelectionQue);
      this._updateSelections(lastPeriod.from.toISOString(), lastPeriod.to.toISOString());
      this._emitDatesChanged(lastPeriod.from, lastPeriod.to, true);
    }
  }
  _updateSelections(from, to) {
    this._deselectPeriodList();
    this.toDate.navigate(new Date(to));
    this.fromDate.navigate(new Date(from));
    this._setValuesToNodes(from, to);
    this._checkPeriodListSelection(from, to);
  }
  _bindEvents() {
    const _t = this;
    this.renderRoot.querySelector('#from').addEventListener('change', e => {
      _t._changeEvent(e, _t.fromDate);
    });
    this.renderRoot.querySelector('#to').addEventListener('change', e => {
      _t._changeEvent(e, _t.toDate);
    });
    this._datadateupdateHandler = e => {
      if (e.detail.to && e.detail.from && !e.detail.ignoreHistory) {
        _t._addToPeriodQue(e.detail.from, e.detail.to);
      }
      _t._updateSelections(e.detail.from.toISOString(), e.detail.to.toISOString());
    };
    document.addEventListener('datadateupdate', this._datadateupdateHandler);
    this._gotopreviousselectionHandler = e => {
      _t.goToPreviousSelection();
    };
    this.addEventListener('gotopreviousselection', this._gotopreviousselectionHandler);
  }
  _changeEvent(e, input) {
    if (e && e.target && e.target.value && moment(e.target.value, DATE_FORMAT).isValid()) {
      input.setDate(new Date(moment(e.target.value, DATE_FORMAT).toISOString()));
      this._checkRangeAndEmitChanges(input);
    }
  }

  _checkRangeAndEmitChanges(input) {
    const range = input.getRange();
    this._setValuesToNodes(range.start, range.end);
    if (range && range.start && range.end) {
      this._deselectPeriodList();
      this._emitDatesChanged(range.start, range.end);
    }
  }

  _checkPeriodListSelection(from, to) {
    const _t = this;
    const ONE_DAY_MS = 86400000;
    const isPeriodLess24H = moment(to).valueOf() - moment(from).valueOf() < ONE_DAY_MS;
    this.renderRoot.querySelectorAll(`.button.selected`).forEach(btn => {
      btn.classList.remove('selected');
    });
    this._periodsListOptions.forEach(column => {
      column.forEach(period => {
        const _periodStart = period.dates.from.clone();
        const _periodEnd = period.dates.to.clone();
        const _firstCheck = _periodStart.valueOf() === moment(from).valueOf() && _periodEnd.valueOf() === moment(to).valueOf();
        const _additionalCheck = isPeriodLess24H ? false : _periodStart.startOf('day').valueOf() === moment(from).valueOf() && _periodEnd.endOf('day').valueOf() === moment(to).valueOf();
        if (_firstCheck || _additionalCheck) {
          _t.renderRoot.querySelectorAll(`.popover-button.selected`).forEach(btn => {
            btn.classList.remove('selected');
          });
          const nodesToSelect = _t.renderRoot.querySelectorAll(`[data-value*="from_${period.dates.from.valueOf()}_to_${period.dates.to.valueOf()}"]`);
          nodesToSelect &&
            nodesToSelect.forEach(node => {
              node.classList.add('selected');
              const icon = node.querySelector('.check-icon');
              if (icon) {
                icon.innerHTML = "<ki-icon icon='ki ki-check'></ki-icon>";
              }
            });
        }
      });
    });
  }

  _createInputs() {
    const _t = this;
    let _id = moment().toISOString();
    const options = {
      id: _id,
      startDay: 1, // 0 = Sunday, 1 = Monday
      showAllDates: true,
      position: 'br',
      customDays: [
        _t.i18n.t('sundayAbbr'),
        _t.i18n.t('mondayAbbr'),
        _t.i18n.t('tuesdayAbbr'),
        _t.i18n.t('wednesdayAbbr'),
        _t.i18n.t('thursdayAbbr'),
        _t.i18n.t('fridayAbbr'),
        _t.i18n.t('saturdayAbbr'),
      ],
      customMonths: [
        _t.i18n.t('January'),
        _t.i18n.t('February'),
        _t.i18n.t('March'),
        _t.i18n.t('April'),
        _t.i18n.t('May'),
        _t.i18n.t('June'),
        _t.i18n.t('July'),
        _t.i18n.t('August'),
        _t.i18n.t('September'),
        _t.i18n.t('October'),
        _t.i18n.t('November'),
        _t.i18n.t('December'),
      ],
      overlayButton: _t.i18n.t('Submit'),
      overlayPlaceholder: _t.i18n.t('year'),
      formatter: (input, date /*, instance*/) => {
        input.value = moment(date).format(DATE_FORMAT);
      },
      onHide: () => {
        _t.renderRoot.querySelector('#periodListBtn').classList.remove('selected');
        _t.renderRoot.querySelector('#periodListSummary').classList.remove('selected');
      },
    };
    const fromOptions = Object.assign({ }, options, {
      onShow: () => {
        _t.renderRoot.querySelectorAll('.to-tab').forEach(selElem => selElem.classList.remove('tab-selected'));
        _t.renderRoot.querySelectorAll('.from-tab').forEach(selElem => selElem.classList.add('tab-selected'));
        _t.renderRoot.querySelector('#periodListBtn').classList.add('selected');
      },
      onSelect: instance => {
        _t.toDate.show();
        _t._checkRangeAndEmitChanges(instance);
      },
    });
    const toOptions = Object.assign({ }, options, {
      onShow: () => {
        _t.renderRoot.querySelectorAll('.from-tab').forEach(selElem => selElem.classList.remove('tab-selected'));
        _t.renderRoot.querySelectorAll('.to-tab').forEach(selElem => selElem.classList.add('tab-selected'));
        _t.renderRoot.querySelector('#periodListBtn').classList.add('selected');
      },
      onSelect: instance => {
        _t._checkRangeAndEmitChanges(instance);
      },
    });
    this.fromDate = datepicker(this.renderRoot.querySelector('#from'), fromOptions);
    this.toDate = datepicker(this.renderRoot.querySelector('#to'), toOptions);
  }

  _createTabNode(className, calendarToShow, calendarToHide) {
    const _t = this;
    const tabNode = document.createElement('div');
    tabNode.classList.add('tab');
    tabNode.classList.add(className);
    tabNode.onclick = e => {
      e.stopPropagation();
      calendarToHide.hide();
      calendarToShow.show();
    };
    return tabNode;
  }

  _createTabsForMobile(node) {
    const tabsNode = document.createElement('div');
    tabsNode.classList.add('tabSection');

    const fromTabNode = this._createTabNode('from-tab', this.fromDate, this.toDate);
    const toTabNode = this._createTabNode('to-tab', this.toDate, this.fromDate);
    tabsNode.appendChild(fromTabNode);
    tabsNode.appendChild(toTabNode);

    node.insertBefore(tabsNode, node.firstChild);
  }

  _emitDatesChanged(from, to, ignoreHistory) {
    const auxFrom = moment(from).startOf('day');
    const auxTo = moment(to).endOf('day');
    this.dispatchEvent(
      new CustomEvent('changedate', {
        bubbles: true,
        detail: {
          fromTime: auxFrom,
          toTime: auxTo,
          ignoreHistory: ignoreHistory,
        },
      }),
    );
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    this.removeEventListener('datadateupdate', this._datadateupdateHandler);
    this.removeEventListener('gotopreviousselection', this._gotopreviousselectionHandler);
    super.disconnectedCallback();
  }
  _generatePeriodList(node) {
    const _t = this;
    this._periodsListOptions.forEach(columns => {
      const column = document.createElement('div');
      column.classList.add('popover-column');
      columns.forEach(opt => {
        const option = document.createElement('span');
        const optFrom = opt && opt.dates ? opt.dates.from : moment();
        const optTo = opt && opt.dates ? opt.dates.to : moment();
        option.innerHTML = `<span class="check-icon"></span><span>${_t.i18n.t(opt.label) || opt.label || opt || ''}</span>`;
        option.className = 'popover-button';
        option.setAttribute('data-value', `from_${optFrom.valueOf()}_to_${optTo.valueOf()}`);
        option.onclick = () => {
          _t.renderRoot.querySelectorAll('.selected').forEach(selElem => {
            var icon = selElem.querySelector('.check-icon');
            if (icon) {
              icon.innerHTML = '';
            }
            selElem.classList.remove('selected');
          });
          option.classList.add('selected');
          var icon = option.querySelector('.check-icon');
          if (icon) {
            icon.innerHTML = "<ki-icon icon='ki ki-check'></ki-icon>";
          }
          if (opt.label === 'custom') {
            column.classList.add('hide-mobile');
            setTimeout(() => {
              _t.fromDate.show();
            }, 50);
          } else if (opt.label === 'periodOfRecord') {
            if (this.constraints && this.constraints.min && this.constraints.max) {
              _t.selectPeriod(this.constraints.min.toISOString(), this.constraints.max.toISOString(), true);
            }
          } else {
            _t.selectPeriod(optFrom.toISOString(), optTo.toISOString(), true);
          }
        };
        column.appendChild(option);
      });
      node.insertBefore(column, node.childNodes[0]);
    });
  }

  _setFirstDates() {
    if (!this._fromTime || !this._toTime) {
      return;
    }
    this.selectPeriod(this._fromTime.toISOString(), this._toTime.toISOString(), false);
  }

  _setValuesToNodes(from, to) {
    const fromTabs = this.renderRoot.querySelectorAll('.from-tab');
    const toTabs = this.renderRoot.querySelectorAll('.to-tab');
    if (!from || !to) {
      this.renderRoot.querySelector('#periodListSummary').innerHTML = '<ki-icon icon="ki ki-calendar"></ki-icon>';
      fromTabs.forEach(selElem => (selElem.innerHTML = from ? `${moment(from).format(DATE_FORMAT)}` : ''));
      toTabs.forEach(selElem => (selElem.innerHTML = to ? `${moment(to).format(DATE_FORMAT)}` : ''));
    } else {
      this.renderRoot.querySelector('#periodListSummary').innerHTML = `${moment(from).format(DATE_FORMAT)} - ${moment(to).format(DATE_FORMAT)}`;
      fromTabs.forEach(selElem => (selElem.innerHTML = `${moment(from).format(DATE_FORMAT)}`));
      toTabs.forEach(selElem => (selElem.innerHTML = `${moment(to).format(DATE_FORMAT)}`));
    }
  }

  _setVisibilityToNodes() {
    const _t = this;
    if (!this.showPeriodList) {
      this.renderRoot.querySelector('#periodListBtn').style.display = 'none';
    }
    const periodsList = new PeriodsList({ allowtime: this.allowtimebox, periods: this.periods });
    this._periodsListOptions = periodsList.getPeriodsList();
    const nodes = this.renderRoot.querySelectorAll('.qs-datepicker-container');
    nodes.forEach(node => {
      _t._generatePeriodList(node);
      _t._createTabsForMobile(node);
    });
    this._setValuesToNodes(this._fromTime, this._toTime);
  }

  openFromCalendar(e) {
    e.stopPropagation();
    this.toDate.hide();
    const isHidden = this.fromDate.calendarContainer.classList.contains('qs-hidden');
    this.fromDate[isHidden ? 'show' : 'hide']();
  }
  _addToPeriodQue(from, to) {
    const lastItem = last(this._periodSelectionQue) || {};
    if (moment.isMoment(from) && this._fromTime && this._toTime && from && to && lastItem.from != from && lastItem.from != to) {
      this._periodSelectionQue.push({ from, to });
    }
  }
  selectPeriod(from, to, emitChange) {
    this.toDate.setDate(new Date(to), false);
    this.fromDate.setDate(new Date(from), false);

    this._updateSelections(from, to);
    //  this._addToPeriodQue(moment(from).startOf('day'), moment(to).startOf('day'))
    emitChange && this._emitDatesChanged(from, to);
  }
  _deselectPeriodList() {
    this.renderRoot.querySelectorAll(`.popover-button.selected`).forEach(btn => {
      btn.classList.remove('selected');
      const icon = btn.querySelector('.check-icon');
      if (icon) {
        icon.innerHTML = '';
      }
    });
  }
  toggleOptions(e) {
    e.stopPropagation();
    const button = this.renderRoot.querySelector('#periodListSummary');
    const column = this.renderRoot.querySelector('.popover-column');
    if (button) {
      if (button.classList.contains('selected')) {
        button.classList.remove('selected');
        this.fromDate.hide();
      } else {
        button.classList.add('selected');
        column.classList.remove('hide-mobile');
        this.fromDate.show();
      }
    }
  }
}

customElements.define('ki-time-range-picker', KiTimeRangePicker);
