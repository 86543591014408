/* eslint-disable class-methods-use-this */
import { html, LitElement } from 'lit-element';
import * as moment from 'moment';
import { forEach } from 'lodash-es';
import '@giswebgroup/ki-wcp-base/src/components/ki-icon/ki-icon';
import LoaderMixin from '@giswebgroup/ki-wcp-base/src/common/LoaderMixin';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import KIWIS from '@giswebgroup/ki-wcp-water/src/api/kiwis';
import style from './ki-graph-configuration.css';
import nls from '../../locales/index.nls';

const DEFAULT_ALARMS = [
  {
    tsShortName: 'Cmd.Alarm-Preal',
    color: '#FE7402',
  },
  {
    tsShortName: 'Cmd.Alarm-Aler',
    color: '#9C0F37',
  },
  {
    tsShortName: 'Cmd.Alarm-Crue',
    color: '#650D1B',
  },
  {
    tsShortName: 'Cmd.Alarm-DCC',
    color: '#30638E',
  },
];

const DEFAULT_INDICATORS = [
  {
    label: 'InterAn / Jour / Maximum',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.Max',
  },
  {
    label: 'InterAn / Jour / P95',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.P95',
  },
  {
    label: 'InterAn / Jour / P90',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.P90',
  },
  {
    label: 'InterAn / Jour / P85',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.P85',
  },
  {
    label: 'InterAn / Jour / Median',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.Med',
  },
  {
    label: 'InterAn / Jour / Mean',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.Mean',
  },
  {
    label: 'InterAn / Jour / P15',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.P15',
  },
  {
    label: 'InterAn / Jour / P10',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.P10',
  },
  {
    label: 'InterAn / Jour / P05',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.P05',
  },
  {
    label: 'InterAn / Jour / Minimum',
    tsShortName: 'Cmd.Rel.Abs.LTV.Day.Min',
  },

  {
    label: 'InterAn / Mois / Maximum',
    tsShortName: 'Cmd.RunOff.LTV.Month.Max',
  },
  {
    label: 'InterAn / Mois / P95',
    tsShortName: 'Cmd.RunOff.LTV.Month.P95',
  },
  {
    label: 'InterAn / Mois / P90',
    tsShortName: 'Cmd.RunOff.LTV.Month.P90',
  },
  {
    label: 'InterAn / Mois / P85',
    tsShortName: 'Cmd.RunOff.LTV.Month.P85',
  },
  {
    label: 'InterAn / Mois / Median',
    tsShortName: 'Cmd.RunOff.LTV.Month.Med',
  },
  {
    label: 'InterAn / Mois / Mean',
    tsShortName: 'Cmd.RunOff.LTV.Month.Mean',
  },
  {
    label: 'InterAn / Mois / P15',
    tsShortName: 'Cmd.RunOff.LTV.Month.P15',
  },
  {
    label: 'InterAn / Mois / P10',
    tsShortName: 'Cmd.RunOff.LTV.Month.P10',
  },
  {
    label: 'InterAn / Mois / P05',
    tsShortName: 'Cmd.RunOff.LTV.Month.P05',
  },
  {
    label: 'InterAn / Mois / Minimum',
    tsShortName: 'Cmd.RunOff.LTV.Month.Min',
  },
];

export default
@i18n(nls)
class KiGraphConfiguration extends LoaderMixin(LitElement) {
  // language=CSS
  static styles = style;

  alarms;

  from;

  to;

  initOpen;

  tsPath;

  referenceFloods;

  selectRange;

  showLabelButton;

  showAlarms;

  showIndicators;

  showYears;

  timeserieObj;

  _buttons;

  constructor(args) {
    super();
    this.kiwisUrl = args ? args.kiwisUrl : '';
    this.tsPath = args ? args.tsPath : '';
    this.from = args ? Number(args.from) || 0 : 0;
    this.to = args ? Number(args.to) || 0 : 0;
    this.initOpen = args && args.initOpen !== undefined && args.initOpen ? args.initOpen : 'false';
    this.selectRange = args && args.selectRange !== undefined && args.selectRange !== null ? args.selectRange : true;
    this.selectedRange = args && args.selectedRange !== undefined && args.selectedRange !== null ? args.selectedRange : {};
    this.showLabelButton = args ? args.showLabelButton : this.showLabelButton || 'yes';
    this.showAlarms = args && args.showAlarms !== undefined && args.showAlarms !== null ? args.showAlarms : 'true';
    this.showIndicators = args && args.showIndicators !== undefined && args.showIndicators ? args.showIndicators : 'true';
    this.showYears = args && args.showYears !== undefined && args.showYears !== null ? args.showYears : 'true';
    this.timeserieObj = args && args.timeserieObj !== undefined && args.timeserieObj !== null ? args.timeserieObj : {};
    this.alarms = args && args.alarms ? args.alarms : DEFAULT_ALARMS;
    this.indicators = args && args.indicators ? args.indicators : DEFAULT_INDICATORS;
    this.referenceFloods = args && args.referenceFloods ? args.referenceFloods : [];
    this.isMultiGraph = args && args.isMultiGraph || false
    this._buttons = {};
  }

  static get properties() {
    return {
      alarms: { type: Array },
      indicators: { type: Array },
      initOpen: { type: String },
      kiwisUrl: { type: String },
      tsPath: { type: String },
      from: { type: Number },
      to: { type: Number },
      referenceFloods: { type: Array },
      showLabelButton: { type: String },
      showAlarms: { type: String },
      showIndicators: { type: String },
      showYears: { type: String },
      timeserieObj: { type: Object },
    };
  }

  render() {
    let textButtonStyle;
    if (this.showLabelButton === 'no') {
      textButtonStyle = html` <ki-icon-btn icon="ki ki-chart-area" @click="${this.toggleCalendar}"></ki-icon-btn> `;
    } else {
      textButtonStyle = html`
        <div id="tablebutton" class="graphConfigButton" @click="${this.toggleCalendar}">
          <ki-icon icon="ki ki-chart-area"></ki-icon>
          <div class="iconText">${this.i18n.t('graphConfig')}</div>
        </div>
      `;
    }
    // language=html
    return html`
      ${this._renderLoader()}
      <span class="popover">
        ${textButtonStyle}
        <div class="popover-body">
          <div class="titleSectionDesktop">
            <div class="titleGroup" id="titleGroupYears">${this.i18n.t('years')}</div>
            <div class="titleGroup" id="titleGroupIndicators">${this.i18n.t('indicators')}</div>
            <div class="titleGroup no-border-title" id="titleGroupAlarms">${this.i18n.t('alarms')}</div>
          </div>
          <div class="titleSection">
            <ki-icon icon="ki ki-arrow-left" @click="${this.toggleCalendar}"></ki-icon>
            <div>${this.i18n.t('graphConfig')}</div>
          </div>
          <div class="tabSection" id="tabSection"></div>
          <div class="popover-content">
            <div class="group" id="yearsGroup">
              <div class="node-content years-content" id="yearsContent"></div>
            </div>
            <div class="group" id="indicatorsGroup">
              <div class="node-content indicators-content" id="indicatorsContent"></div>
            </div>
            <div class="no-border-group group" id="alarmsGroup">
              <div class="node-content indicators-content" id="alarmsContent"></div>
            </div>
          </div>
        </div>
      </span>
    `;
  }

  firstUpdated() {
    this.popover = this.renderRoot.querySelector('.popover').classList;

    this._generateContent();
    if (this.initOpen === 'true') {
      this.toggleCalendar();
    }
  }

  selectYears(minSelected, maxSelected) {
    forEach(this._buttons, btn => {
      const currentValue = Number(btn.getAttribute('data-value'));
      if (minSelected && maxSelected && minSelected <= currentValue && maxSelected >= currentValue) {
        btn.classList.add('selected');
      } else {
        btn.classList.remove('selected');
      }
    });
  }

  toggleCalendar() {
    const button = this.renderRoot.querySelector('#tablebutton');
    if (this.popover.contains('active')) {
      this.popover.remove('active');
      button && button.classList.remove('selected');
    } else {
      this.popover.add('active');
      button && button.classList.add('selected');
      const contentNode = this.renderRoot.querySelector('.popover-body');
      if (button && contentNode) {
        const btnWidth = button.clientWidth;
        const contentWidth = contentNode.clientWidth;
        const leftCalc = contentWidth - btnWidth;
        contentNode.style.left = `calc(-${leftCalc}px)`;
      }
    }
  }

  _createEvents() {
    const _t = this;
    this._wiskidateschanged = event => {
      const from = event && event.detail ? event.detail.fromTime : null;
      const to = event && event.detail ? event.detail.toTime : null;
      if (from && to) {
        _t.selectYears(from.year(), to.year());
      }
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this._createEvents();
    this.addEventListener('wiskidateschanged', this._wiskidateschanged);
  }

  disconnectedCallback() {
    this.removeEventListener('wiskidateschanged', this._wiskidateschanged);
     super.disconnectedCallback();
  }


  getIndicatorsTsPaths(indicatorsArray) {
    const tsPaths = [];
    const tsParts = this.tsPath ? this.tsPath.split('/') : [];
    if (indicatorsArray && tsParts[0] && tsParts[1] && tsParts[2]) {
      indicatorsArray.forEach(alarm => {
        if ((alarm.tsShortName && !alarm.type) || alarm.type === 'ts') {
          alarm.type = 'ts';
          tsPaths.push(`${tsParts[0]}/${tsParts[1]}/${tsParts[2]}/${alarm.tsShortName}`);
        }
      });
    }
    return tsPaths.join();
  }

  _addTab(groupNode, title, additionalClass) {
    const _t = this;
    const tabNode = this.renderRoot.querySelector('#tabSection');
    if (tabNode) {
      const className = additionalClass === 'selected' ? 'visibleGroup' : 'hiddenGroup';
      groupNode.classList.add(className);
      const tab = document.createElement('div');
      tab.className = `tab ${additionalClass || ''}`;
      tab.innerHTML = title;
      tab.onclick = () => {
        const selectedElems = tabNode.querySelectorAll('.selected');
        selectedElems.forEach(selElem => selElem.classList.remove('selected'));
        tab.classList.add('selected');
        const groupNodes = _t.renderRoot.querySelectorAll('.group');
        if (groupNodes && groupNodes.length > 0) {
          groupNodes.forEach(gNode => {
            if (gNode.id === groupNode.id) {
              gNode.classList.remove('hiddenGroup');
              gNode.classList.add('visibleGroup');
            } else {
              gNode.classList.remove('visibleGroup');
              gNode.classList.add('hiddenGroup');
            }
          });
        }
      };
      tabNode.appendChild(tab);
    }
  }

  _generateContent() {
    if (!this.tsPath) {
      return;
    }
    this.alarms = !this.alarms || this.alarms.length === 0 ? DEFAULT_ALARMS : this.alarms;
    this.indicators = !this.indicators || this.indicators.length === 0 ? DEFAULT_INDICATORS : this.indicators;
    this._generateYears();
    const returnfields = ['station_name', 'ts_shortname', 'ts_name', 'parametertype_name', 'ts_unitsymbol', 'ts_id', 'coverage', 'ts_path', 'ts_spacing'];
    new KIWIS({ basePath: this.kiwisUrl }).getTimeseriesList({ts_path:`${this.tsPath.split("/").slice(0,3).join("/")}/*`}, returnfields).then(tsList => {
      tsList = tsList.filter(item => item.from )
      const selected = this.showYears === 'false' ? 'selected' : null;
      this._generateAlarms(this.indicators, this.showIndicators, '#indicatorsContent', '#titleGroupIndicators', '#indicatorsGroup', 'addindicator', this.i18n.t('indicators'), tsList, selected);
      this._generateAlarms(this.alarms, this.showAlarms, '#alarmsContent', '#titleGroupAlarms', '#alarmsGroup', 'addalarm', this.i18n.t('alarms'), tsList);
    })

  }

  _generateAlarms(indicatorsArray, showNode, nodeId, titleGroupId, groupId, eventName, tabName, tsList, selected) {
    const _t = this;
    const groupNode = this.renderRoot.querySelector(groupId);
    this.getIndicatorsTsPaths(indicatorsArray);
    if (groupNode && showNode === 'false') {
      groupNode.classList.remove('group');
      groupNode.classList.add('hide');
      const titleGroupNode = this.renderRoot.querySelector(titleGroupId);
      titleGroupNode.classList.add('hide');
      return;
    }

    this._addTab(groupNode, tabName, selected);
    const node = this.renderRoot.querySelector(nodeId);
    node.innerHTML = '';
    _t._processAlarms(indicatorsArray, tsList, eventName, node);
  }

  _generateYears() {
    const _t = this;
    const groupNode = this.renderRoot.querySelector('#yearsGroup');
    if (groupNode && this.showYears === 'false') {
      groupNode.classList.remove('group');
      groupNode.classList.add('hide');
      const titleGroupYearsNode = this.renderRoot.querySelector('#titleGroupYears');
      titleGroupYearsNode.classList.add('hide');
      return;
    }

    this._addTab(groupNode, this.i18n.t('years'), 'selected');
    const ITEMS_PER_ROW = 3;
    const node = this.renderRoot.querySelector('#yearsContent');
    node.innerHTML = '';
    let count = 0;
    let rowNode = document.createElement('div');
    rowNode.className = 'row';
    node.appendChild(rowNode);
    for (let i = this.to; i >= this.from; i -= 1) {
      const yearButton = document.createElement('span');
      yearButton.innerHTML = i;
      yearButton.setAttribute('data-value', i);
      yearButton.className = 'button';
      if (this.selectedRange && (this.selectedRange.from === i || this.selectedRange.to === i)) {
        yearButton.classList.add('selected');
      }
      yearButton.onclick = () => {
        if (yearButton.classList.contains('selected')) {
          yearButton.classList.remove('selected');
        } else {
          yearButton.classList.add('selected');
        }
        _t._saveYearsInLocalStorage();
        _t._setSelectedRange(yearButton);
      };
      if (count < ITEMS_PER_ROW) {
        rowNode.appendChild(yearButton);
        count += 1;
      } else {
        rowNode = document.createElement('div');
        rowNode.className = 'row';
        node.appendChild(rowNode);
        rowNode.appendChild(yearButton);
        count = 1;
      }
      this._buttons[i] = yearButton;
    }
    while (count < ITEMS_PER_ROW) {
      const invisibleButton = document.createElement('span');
      invisibleButton.className = 'invisibleButton button';
      rowNode.appendChild(invisibleButton);
      count += 1;
    }
    this._selectYearsFromLocalStorage();
  }


  _processAlarms(indicatorsArray, tsData, eventName, node) {
    const _t = this;
    let _buttonsAdded = 0;
    indicatorsArray.forEach(indicator => {
      const tsIndicator = tsData.find(opt => opt.ts_shortname === indicator.tsShortName && indicator.type === 'ts');
      if (tsIndicator) {
        const indicatorId = `${tsIndicator.parametertype_name}${tsIndicator.ts_shortname}`.replaceAll(".","_")
        const button = document.createElement('span');
        tsIndicator.type = 'ts';
        tsIndicator.id = tsIndicator.ts_id;
        if(tsIndicator.ts_shortname.includes(".LTV.")){
           tsIndicator.valuetype = "LTV"
        }
        tsIndicator.valuetype = tsIndicator.valuetype || indicator.valuetype;
        tsIndicator.isAbsolute = indicator.isAbsolute;
        tsIndicator.chartLabel = indicator.chartLabel;
        const _label = this._getLabelByShortName(indicatorsArray, tsIndicator.ts_shortname) || _t.i18n.t(tsIndicator.ts_shortname.replace(/\./g, '_')) || tsIndicator.ts_shortname;
        tsIndicator.name = _label;
        button.innerHTML = _label;
        button.setAttribute('data-value', tsIndicator.ts_path);
        button.setAttribute('data-label', _label);
        button.setAttribute('data-color', this._getTsColor(indicatorsArray, tsIndicator.ts_shortname));
        button.className = `button id${indicatorId}`;
        button.onclick = evt => {
          this._setSelectedIndicator(button, eventName, tsIndicator, evt);
        };
        node.appendChild(button);
        _buttonsAdded += 1;
      } else if (indicator && indicator.type !== 'ts') {
        const button = document.createElement('span');
        if (indicator.type === 'field') {
          const valueUnit = indicator.field && _t.timeserieObj && _t.timeserieObj[indicator.field] ? _t.timeserieObj[indicator.field].split(' ') : [];
          if (valueUnit && valueUnit.length > 1) {
            if (valueUnit[1] !== _t.timeserieObj.ts_unitsymbol) {
              return;
            }
            _t.timeserieObj[`${indicator.field}_value`] = valueUnit[0]; // eslint-disable-line prefer-destructuring
            _t.timeserieObj[`${indicator.field}_unit`] = valueUnit[1]; // eslint-disable-line prefer-destructuring
            _t.timeserieObj[indicator.field] = _t.timeserieObj[`${indicator.field}_value`];
            indicator.value = valueUnit[0]; // eslint-disable-line prefer-destructuring
            indicator.ts_unitsymbol = valueUnit[1]; // eslint-disable-line prefer-destructuring
          }
        }
        let _label = '';
        if (indicator.name) {
          _label = indicator.name;
        } else if (indicator.fieldLabel && _t.timeserieObj) {
          _label = moment(_t.timeserieObj[`${indicator.fieldLabel}`]).format('DD/MM/YYYY') || indicator.fieldLabel || indicator.name || indicator.tsShortName;
          indicator.name = _label;
        } else {
          _label = indicator.tsShortName;
        }
        indicator.ts_id = _label.replace(/ /gi, '').replace(/[^\w\s]/gi, '');
        indicator.id = indicator.ts_id;
        button.innerHTML = _label;
        button.setAttribute('data-value', indicator.value);
        button.setAttribute('data-label', _label);
        button.setAttribute('data-color', indicator.color);
        button.className = `button id${indicator.ts_id}`;
        button.onclick = evt => {
          this._setSelectedIndicator(button, eventName, indicator, evt);
        };
        node.appendChild(button);
        _buttonsAdded += 1;
      }
    });
    if (_buttonsAdded === 0) {
      const infoNode = document.createElement('span');
      infoNode.style.overflow = 'hidden';
      infoNode.style.whiteSpace = 'break-spaces';
      if (eventName === 'addindicator') {
        infoNode.innerHTML = this.i18n.t('noIndicators');
      } else if (eventName === 'addalarm') {
        infoNode.innerHTML = this.i18n.t('noAlarms');
      }
      node.appendChild(infoNode);
    }
    !this.isMultiGraph && this._selectIndicatorsFromLocalStorage(eventName);
  }

  _getLabelByShortName(indicatorsArray, tsShortName) {
    const tsObj = indicatorsArray.find(alarm => alarm.tsShortName === tsShortName);
    return tsObj ? tsObj.label : '';
  }

  _getTsColor(indicatorsArray, tsShortName) {
    const tsObj = indicatorsArray.find(alarm => alarm.tsShortName === tsShortName);
    return tsObj ? tsObj.color : '';
  }

  _saveIndicatorInLocalStorage(tsId, isSelected, eventName) {
    const localStorageObject = JSON.parse(localStorage.getItem('ki-graph-configuration-indicators')) || {};
    if (!localStorageObject[eventName]) {
      localStorageObject[eventName] = {};
    }
    localStorageObject[eventName][tsId] = isSelected;
    localStorage.setItem('ki-graph-configuration-indicators', JSON.stringify(localStorageObject));
  }

  _saveYearsInLocalStorage() {
    const localStorageArray = [];
    this.renderRoot.querySelectorAll('.years-content .selected').forEach(node => {localStorageArray.push(node.dataset.value)});
     localStorage.setItem('ki-graph-configuration-years', JSON.stringify(localStorageArray));
  }

  _selectIndicatorsFromLocalStorage(eventName) {
    const _t = this;
    const localStorageObject = JSON.parse(localStorage.getItem('ki-graph-configuration-indicators')) || {};
    if (!localStorageObject || !localStorageObject[eventName]) {
      return;
    }
    forEach(localStorageObject[eventName], (value, key) => {
      const aux = _t.renderRoot.querySelector(`.id${key}`);
      if (aux && aux.classList && value === true) {
        aux.click();
      }
    });
  }

  _selectYearsFromLocalStorage() {
    const _t = this;
    let localStorageArray = JSON.parse(localStorage.getItem('ki-graph-configuration-years'));
    localStorageArray = localStorageArray && localStorageArray.length ? localStorageArray : [this.to.toString()];
    _t.renderRoot.querySelectorAll('.years-content .button').forEach(year => {
      const val = year.dataset.value;
      if (localStorageArray.includes(val)) {
        year.click();
      }
    });
  }

  _setSelectedIndicator(buttonCliked, eventName, item, event) {
    if (buttonCliked.classList.contains('selected')) {
      buttonCliked.classList.remove('selected');
    } else {
      buttonCliked.classList.add('selected');
    }
    const _isSelected = buttonCliked.classList.contains('selected');
    if (event.isTrusted) {
      const indicatorId = `${item.parametertype_name}${item.ts_shortname}`.replaceAll(".","_")
      this._saveIndicatorInLocalStorage(`${indicatorId}`, _isSelected, eventName);
    }
    const valuesObj = {
      color: buttonCliked.getAttribute('data-color'),
      id: item.id,
      isSelected: _isSelected,
      label: buttonCliked.getAttribute('data-label'),
      name: item.name,
      tsId: item.ts_id,
      valuetype: item.valuetype,
      tsSpacing: item.ts_spacing,
      isAbsolute: item.isAbsolute,
      chartLabel: item.chartLabel,
      ts_id: item.ts_id,
      tsPath: item.ts_path || '',
      ts_path: item.ts_path || '',
      tsShortName: item.ts_shortname || '',
      type: item.type,
      unit: item.unit || item.ts_unitsymbol,
      value: item.value || '',
      from: item.from || '',
      to: item.to || '',
    };
    if (item.to) {
      valuesObj.yearSelected = moment(item.to).year() - 1;
    }
    this.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: {
          values: valuesObj,
        },
      }),
    );
  }

  _setSelectedRange(buttonCliked) {
    if (this.selectRange) {
      const selected = [];
      forEach(this._buttons, btn => {
        if (btn.classList.contains('selected')) {
          selected.push(Number(btn.getAttribute('data-value')));
        }
      });
      const minSelected = Math.min(...selected);
      const maxSelected = Math.max(...selected);
      this.selectYears(minSelected, maxSelected);

      this.dispatchEvent(
        new CustomEvent('selectedyearrange', {
          bubbles: true,
          detail: {
            values: {
              from: minSelected,
              to: maxSelected,
            },
          },
        }),
      );
    } else {
      this.dispatchEvent(
        new CustomEvent('selectedyear', {
          bubbles: true,
          detail: {
            values: {
              yearSelected: buttonCliked.getAttribute('data-value'),
              isSelected: buttonCliked.classList.contains('selected'),
            },
          },
        }),
      );
    }
  }
}

customElements.define('ki-graph-configuration', KiGraphConfiguration);
