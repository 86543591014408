import { html, LitElement } from 'lit-element';
import moment from 'moment';

import '@giswebgroup/ki-wcp-base/src/components/ki-switcher/ki-switcher';
import responsive, { LG, MD, SM, ViewPort } from '@giswebgroup/ki-wcp-base/src/decorators/responsive';
import i18n from '@giswebgroup/ki-wcp-base/src/decorators/i18n';
import loader from '@giswebgroup/ki-wcp-base/src/decorators/loader';
import Mix from '@giswebgroup/ki-wcp-base/src/common/Mix';
import { orderBy, findIndex } from 'lodash-es';
import {getLayer} from "@giswebgroup/ki-wcp-water/src/common/ki-ol-layer-generator";
import nls from '../../locales/index.nls';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-map';
import style from './ki-station-view.css';

import {getWithExpiry, setWithExpiry} from '@giswebgroup/ki-wcp-base/src/common/util';
import '@giswebgroup/ki-wcp-base/src/components/ki-stack/ki-stack';
import '@giswebgroup/ki-wcp-base/src/components/ki-modal/ki-modal';
import TagStyleConfig from './TagStyleConfig';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-base-layers';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-stations-layer';
import '@giswebgroup/ki-wcp-water/src/components/ki-layers-control/ki-layers-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-legends-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-location-control/ki-location-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-download-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-map-zoom-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-map-overview-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-station-map-position-scale-control';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-layer-factory';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-map/ki-catchments-layer';
import '@giswebgroup/ki-wcp-water/src/components/ki-catchment-details/ki-catchment-details';
import '../../components/ki-timeseries-graph/ki-timeseries-graph';
import '../../components/ki-annual-graph/ki-annual-graph';
import '../../components/ki-wcp-walhydro-popup/ki-wcp-walhydro-popup-precip';
import renderDesktop from './render-desktop';
import renderMobile from './render-mobile';
import ViewStore from './ViewStore';


// consider only decorate when register as custom element

export default
@responsive
@i18n(nls)
@loader()
class KiStationView extends Mix(LitElement, ViewStore, renderDesktop, renderMobile) {
  // language=CSS
  static styles = style;

  refreshInterval = 60000; // 1mins, TODO bug: operating while reloading?

  constructor() {
    super();
    this.__refreshTimer = setInterval(() => {
      this.loadData();
    }, this.refreshInterval);
    this.refreshCount = 0;
    this.i18n.changeLanguage("fr-BE")
   // window.contextJsParameters && window.contextJsParameters.uilang && this.i18n.changeLanguage(window.contextJsParameters.uilang)
    this.__catchments = [];
    this.tagStyles = new TagStyleConfig(this);
    this.catchmentTagStyles = new TagStyleConfig(this);
    this.catchmentClassificationInfocrue = new TagStyleConfig(this);
    this.layerGroups = ['Layers', 'Information', { name: 'Backgrounds', type: 'radio' }];
    moment.locale(this.i18n.language);
    this.numberformatter = new Intl.NumberFormat(this.i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    this.dataSource = '0';
    this.projections = ['EPSG:31370', 'EPSG:3812', 'EPSG:4326'];
    this.__overViewLayer = {
      maxZoom: 6,
      minZoom: 6,
      layer: [getLayer({
      "type": "topojson",
      "url": "/services/kiwcp/data/overview.json",
      "label": "overview",
      "visible": true,
      "zIndex": 25,
      "style": {
        "stroke": {
          "color": "rgba(16,147,205,1)",
          "width": 2
        },
        "fill": {
          "color": "rgba(16,147,205,0.5)"
        }
      }
    })]
     }
  }

  static get properties() {
    return {
      dataSource: { type: String }
    };
  }

  get kiwisUrl(){
    return this.kiwisPath.includes("//") ? this.kiwisPath : `${window.location.protocol}//${window.location.host}${this.kiwisPath}`
  }

  connectedCallback() {
    super.connectedCallback();
    this.__config = window.StationConfigs[this.config];
  //  this.loadData();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  clickStation(e) {
    // this.closeTsGraph(e);
    this.__selectedStations = [];
    this.currentCatchmentId = undefined;
    this.currentStationId = e.detail.value;
  }

  postDataLoad(){
    if(this.refreshCount === 0 && this.__configuration.enableCatchmentByStatus && this.__catchments.some(item => item.catchmentAlarmMessage)){
      if(!getWithExpiry("announcementchecked")){
        setWithExpiry("announcementchecked", true, 3600)
        this.mapOrTable = "announcement";
      }
    } 
  }

  switchStation({station, topic}){
    let currentStation;
    const orderedStations = orderBy(this.__filteredStations, "station_no")
    if(station ===1 || station ===-1){
      const currentIndex = findIndex(orderedStations, item => this.__currentStation.station_no === item.station_no);
      let nextIndex = currentIndex+station

      if(nextIndex<0){
        nextIndex = orderedStations.length-1;
      }else if(nextIndex>orderedStations.length-1){
        nextIndex = 0;
      }
        currentStation = orderedStations[nextIndex]
        this.__selectedStations = [];
        this.currentCatchmentId = undefined;
    }else{
      currentStation = station;
    }
    if(topic){
      this.topic = topic;
    }
    if(currentStation){
      this.currentStationId = `${currentStation.site_no}/${currentStation.station_no}`;
    }
  }


  clickCatchment(e) {
    this.__selectedStations = [];
    this.currentStationId = undefined;
    this.currentCatchmentId = e.detail.value;
  }
  
  render() {
    if (ViewPort.size === SM && this.config) {
      return html` <div class="view-container">${this.renderMobile()}</div> `;
    }

    if (ViewPort.size === MD || (ViewPort.size === LG && this.config)) {
      return html` <div class="view-container">${this.renderDesktop()}</div> `;
    }

    return html``;
  }
}

customElements.define('ki-station-view', KiStationView);
