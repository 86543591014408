import { html } from 'lit-element';
import d from '@giswebgroup/ki-wcp-base/src/common/d';
import '@material/mwc-dialog';
import '@material/mwc-button';
import query from '@giswebgroup/ki-wcp-base/src/decorators/query';
import KiToast from '@giswebgroup/ki-wcp-base/src/components/ki-toast/ki-toast';
import * as moment from 'moment';
import '@giswebgroup/ki-wcp-water/src/components/ki-report/ki-station-report';
import '@giswebgroup/ki-wcp-water/src/components/ki-report/ki-annual-report';
import '@giswebgroup/ki-wcp-water/src/components/ki-report/ki-station-img';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-searchbox/ki-station-searchbox';
import '../../components/ki-station-detail-panel/ki-station-detail-panel';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-nav-list/ki-station-nav-list';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-download-bar/ki-station-download-bar';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-data-download-form/ki-station-data-download-form';
import '@giswebgroup/ki-wcp-water/src/components/ki-station-table/ki-station-table';
import '../../components/ki-station-alarm/ki-station-alarm';
import '../../components/ki-timeseries-graph/ki-timeseries-graph';
import { find } from 'highcharts';

export default function f(claz) {
  return class extends claz {
    // TODO
    toggleStationList() {
      this.__sidebarStackKey = this.__sidebarStackKey === 'nav-list' ? null : 'nav-list';
    }



    onStationsSelected(e) {
      this.__selectedStations = e.detail.selection;
    }

    clearSelection() {
      this.__selectedStations = [];
    }

    closeStationBar() {
      this.currentStationId = undefined;
    }

    closeCatchment() {
      this.currentStationId = undefined;
      this.currentCatchmentId = undefined;
    }

    fullScreenGraph(e) {
      this.renderRoot.querySelector('#graph').classList.toggle('fullScreen', e.detail.fullScreen);
      this.classList.toggle('fullScreen-mode', e.detail.fullScreen);
    }

    closeTsGraph(e) {
      this.topic = '';
      this.__dynamicViewTemplate = '';
      e && e.stopPropagation();
      this.fullScreenGraph({ detail: { fullScreen: false } });
      this.__navList.selectedTs = [];
      this.selectedTs = [];
    }

    _renderSearchBox() {
      return html`<ki-station-searchbox
        .fieldList="${this.filterFieldList}"
        .searchContent="${this.searchContent}"
        @search="${e => {
          this.searchContent = e.detail.searchContent;
          this.__stationFilter = e.detail.filter;
        }}"
        .localStorageId="${`${this.localStorageId}-searchbox`}"
      ></ki-station-searchbox>`;
    }

    _renderTable(){
      return html`<ki-station-table
              key="table"
              id="station-list"
              .tagStyles="${this.tagStyles.tableTagStyle}"
              
              .catchmentTagStyles="${this.catchmentClassificationInfocrue.tableTagStyle}"
              .catchmentCalcTagStyles="${this.catchmentTagStyles.tableTagStyle}"
              .stations="${this.__filteredStations}"
              .columns="${this.__configuration.stationColumns}"
              .usergroups="${this.usergroups}"
              .localStorageId="${`${this.localStorageId}-station-table`}"
              .currentStationId="${this.currentStationId}"
              @station-click="${this.clickStation}"
              @downloadstationsmd=${this.downloadStations}
              .selection="${this.__selectedStations}"
              @selection="${this.onStationsSelected}"
              checkboxTitle="${this.i18n.t('requestDownload')}"
            ></ki-station-table>`
    }

    _renderMap(){
      return html`<ki-station-map .view="${this._view}" key="map" .selectedStations="${this.__selectedStations}">
              <ki-layers-control .i18n=${this.i18n} .groups="${this.layerGroups}"></ki-layers-control>

              <ki-legends-control
                .disabled="${!this.__configuration.stationClassification}"
                .options="${[this.catchmentClassificationInfocrue.legendOptions, this.tagStyles.legendOptions]}"
              ></ki-legends-control>
              <ki-station-download-control .label="station download" @downloadstationsmd=${this.downloadStations}   .selectedStations="${this.__filteredStations}"></ki-station-download-control>
              <ki-station-map-zoom-control .label="map zoom" .homeExtent="${this._homeExtent}"></ki-station-map-zoom-control>
              <ki-station-map-overview-control .label="overview" .layerOptions="${this.__overViewLayer}"></ki-station-map-overview-control>
              <ki-station-map-position-scale-control .projections="${this.projections}" .label="position and scale"></ki-station-map-position-scale-control>
              <ki-base-layers
                .layers="${this.mapLayers}"
                @changed="${e => {
                    this.__mapBackGroundLayer = e.detail.value;
                  }}"
              ></ki-base-layers>
              <ki-stations-layer
                noAutoZoom
                displayInLayerControl
                @station-click="${this.clickStation}"
                @selection="${this.onStationsSelected}"
                .currentStation="${this.__currentStation}"
                .tagMarkers="${this.tagStyles.tagMarkers}"
                .tagSelectedMarkers="${this.tagStyles.tagSelectedMarkers}"
                .stations="${this.__filteredStations}"
                .selection="${this.__selectedStations}"
                .infoTag="${this.__configuration.stationInfoPopup}"
                .labelTemplate="${this.__configuration.stationMapLabelTemplate || this.__configuration.stationLabelTemplate}"
                multiSelectable
              ></ki-stations-layer>

              <ki-catchments-layer
              zindexlayer = "16"
              .catchmentFeatures = ${this.__catchmentGeometries}
              .disabled=${!this.__configuration.enableCatchmentByStatus || !this.__catchments}
              .currentCatchment="${this.__currentCatchment}"
              @catchment-click="${this.clickCatchment}"
              .catchmentsStyles=${this.catchmentClassificationInfocrue.catchmentsStyles}
              .catchments="${this.__catchments}"
            ></ki-catchments-layer>

              <ki-catchments-layer
                zindexlayer = "6"
                .catchmentFeatures = ${this.__catchmentGeometries}
                name="calculatedCatchmentStatus"
                .currentCatchment="${this.__currentCatchment}"
                .visible="false"
                @catchment-click="${this.clickCatchment}"
                .disabled=${!this.__configuration.enableCatchmentByObservation || !this.__calculatedCatchmentStatus}
                .catchmentsStyles=${this.catchmentTagStyles.catchmentsStyles}
                .catchments="${this.__calculatedCatchmentStatus}"
              ></ki-catchments-layer>
            </ki-station-map>`
    }

    renderNavlist() {
      return this.__sidebarStackKey ? html` <ki-station-nav-list
      id="navList"
      .stations="${this.__filteredStations}"
      .stationLabelTemplate="${this.__configuration.stationLabelTemplate}"
      .stationLabelFormats="${this.__configuration.stationLabelFormats}"
      .favouriteStations="${this.__favouriteStations}"
      .selectedTs="${this.selectedTs}"
      @itemSelected="${e => {
        this.selectedTs = e.detail.value;
        this._showGraph()
      }}"
      @labelClicked="${this.clickStation}"
    ></ki-station-nav-list>` : html``
    }

    renderDesktop() {
      this.classList.toggle('sidebar-mode', (!!this.__sidebarStackKey && this.__sidebarStackKey!== "announcement"));
      this.classList.toggle('flat', this.mapOrTable === 'table');
      const listToggleDisabled = !!this.__dynamicViewTemplate;
      // language=html
      return html`
        <ki-icon-btn id="toggle-station-list" title="${this.i18n.t('Station_list')}" class="list-toggle ${listToggleDisabled ? 'disabled' : ''}" icon="ki ki-bars" @click="${listToggleDisabled || this.toggleStationList}"></ki-icon-btn>
        ${this._renderSearchBox()}
        <div class="side-bar">
          <ki-stack id="nav-stack" .selected="${this.__sidebarStackKey}">
            <div key="nav-list" class="nav-list">
              ${this.renderNavlist()}
            </div>
            <ki-station-detail-panel
              key="station-bar"
              skipHistory="true"
              .kiwisPath="${this.kiwisUrl}"
              .station="${this.__currentStation}"
              .menuConfig="${this.stationMenu}"
              .detailsConfig="${this.__configuration.stationDetails}"
              .statusBarStyles="${this.tagStyles.statusBarStyles}"
              .mapBackGroundLayer="${this.__mapBackGroundLayer}"
              .isFavourite="${this._isFavourite(this.__currentStation?.station_id)}"
              .additionalLayers="${this.mapLayers}"
              .supporter="${this.__configuration.supporter}"
              @close="${this.closeStationBar}"
              @itemSelected="${this._showStationGraph}"
              @toggleFavourite="${this._toggleFavourite}" 
              @selectstation="${(e)=> {this.switchStation(e.detail)}}" 
            ></ki-station-detail-panel>
            <ki-catchment-details
              skipHistory="true"
              key="catchment-detail"
              .catchment="${this.__currentCatchment}"
              @close="${this.closeCatchment}"
              @itemSelected="${e => {
                this.topic = e.detail.value;
                this.currentStationId = e.detail.stationPath;
                setTimeout(() => {
                  e.detail.element?.focus();
                },100);
              }}"
              .tagStyles="${this.tagStyles.statusBarStyles}"     
              .catchmentTagStyles="${this.catchmentClassificationInfocrue.statusBarStyles}"
            >
            </ki-catchment-details>

          </ki-stack>
        </div>
        <ki-stack id="view-stack" .selected="${this.__dynamicViewTemplate ? 'graph' : 'view'}">
          <ki-stack key="view" id="map-table-view" .selected="${this.mapOrTable === 'table' ? 'table' : 'map'}">
            <ki-switcher
              class="round"
              id="map-table-switcher"
              .options="${this.__switcherOptions}"
              value="${this.mapOrTable}"
              @changed="${e => {
                this.mapOrTable = e.detail.value;
              }}"
            ></ki-switcher>

            <ki-station-download-bar .downloadLimit="${this.__configuration.downloadLimit || 10}"  .stations=${this.__selectedStations} @clear="${this.clearSelection}" @download-station-data="${this.showDownloadDialog}"></ki-station-download-bar>

            ${this.mapOrTable === 'table' ? this._renderTable() :  ""}
            ${this._renderMap()}

          </ki-stack>
   
          <div key="graph" 
            id="graph" 
            class="graph-container" 
            skipHistory="true"  
            @selectstation="${(e)=> {this.switchStation(e.detail)}}" 
            @full-screen="${this.fullScreenGraph}" 
            @close="${this.closeTsGraph}">
              ${this.__dynamicViewTemplate}
          </div>
        </ki-stack>
        <ki-station-alarm skipHistory="true"  @close="${() => {this.mapOrTable ="map"}}" class="${this.mapOrTable ==="announcement" ? '' : 'hidden'}" .catchments="${this.__catchments}" .catchmentsStyles=${this.catchmentClassificationInfocrue.catchmentsStyles} .config="${this.__configuration}">
        </ki-station-alarm>
        <mwc-dialog id="downloadDialog" hideActions heading="${this.i18n.t('download_Timeseries_from')} ${this.__selectedStations.length} ${this.i18n.t('s_stations')}">
          <ki-station-data-download-form id="downloadDataForm" @closedialog=${this.closeDownloadDialog} @datarequest=${this._downloadStationData} .downloadOptions="${this.__configuration.downloadOptions || null}">
          </ki-station-data-download-form>
    
        </mwc-dialog>
      `;
    }



    _showGraph() {
      // TODO workaround for bugs of ki-timeseries-graph
      if (this.selectedTs) {
        if (this.currentCatchmentId===undefined) {
          this.__sidebarStackKey = 'nav-list';
        }

        this.__dynamicViewTemplate = '';
        setTimeout(() => {
          const items = this.selectedTs;
          const altTs = this.__configuration?.multiGraphOptions?.ts_shortname;
          const pathReplace = this.__configuration?.multiGraphOptions?.pathReplace;
          const forceTransformation = this.__configuration?.multiGraphOptions?.forceTransformation;

          const removets = (e) => {
            if(e.detail.ts_path){
              const tsPath = altTs ? e.detail.ts_path.substring(0, e.detail.ts_path.lastIndexOf('/') + 1) : e.detail.ts_path
              this.selectedTs = this.selectedTs.filter(item => item.includes(tsPath))
            }
          }

          const events = {
            "removetimeseries": removets
          }

          const defaultGraphOptions = {
            isMultiGraph: true,
            tsPath: items.map(v => {
              let tsPath = altTs ? v.substring(0, v.lastIndexOf('/') + 1) + altTs : v;
              tsPath = pathReplace ? v.replace(pathReplace.from, pathReplace.to) : tsPath;
              return {
                ts_path: tsPath,
                forceTransformation,
                name: v,
                visible: true,
                type: this.__configuration?.multiGraphOptions?.type || "line"
              };
            }),
            downloadItems: [
              {
                label: 'PNG',
                value: 'png',
              },
            ],
            kiwisUrl: this.kiwisUrl,
            zoomEnabled: true,
            hideConfigure: true,
          };
          this.__dynamicViewTemplate = d('ki-timeseries-graph', { ...defaultGraphOptions, ...this.__configuration.multiGraphOptions }, events);
        });
      }
    }

    _showStationGraph(e) {
      // TODO move util with isUrl, use wrapper
      const { component, value, stationId } = e.detail;
      if (component === 'ki-station-report' || value === "station_description") {
        this.__dynamicViewTemplate = html`<ki-station-report
          path="${this.__currentStation.site_no}/${this.__currentStation.station_no}"
          reportSrv="${this.__configuration.reportSrv}"
        ></ki-station-report>`;
      } else if (component === 'ki-annual-report' || value === "annual_report") {
        this.__dynamicViewTemplate = html`<ki-annual-report
          path="${this.__currentStation.site_no}/${this.__currentStation.station_no}"
          reportSrv="${this.__configuration.reportSrv}"
        ></ki-annual-report>`;
      } else if (component === 'ki-station-img') {
        this.__dynamicViewTemplate = html`<ki-station-img src="${value}"></ki-station-img>`;
      } else if (this.stationMenu) {
        this.__dynamicViewTemplate = ''; // TODO workaround for bugs of ki-timeseries-graph
        let {stationMenu} = this;
        if(stationId){
           stationMenu = this.getStationConfig(stationId);
        }
        setTimeout(() => {
          let graph;
          stationMenu.forEach(m => {
            graph = graph || m.children.find(item => item.value === value)?.options;
          });
          if(!graph){
            stationMenu.forEach(m => {
              graph = graph || m.children.find(item => item.altvalue === value)?.options;
            });
          }
          if(graph){
            graph.config.filteredStationList = this.__filteredStations;
          }
          graph.config.station
          this.__dynamicViewTemplate = graph ? d(graph.component, graph.config) : '';
        });
      }
    }

    @query('#downloadDialog')
    __downloadDialog;

    @query('#downloadDataForm')
    __downloadDataForm;

    @query('#map-table-view')
    __mapTableView;

    @query('#navList')
    __navList;


    showDownloadDialog() {
      this.__downloadDataForm.reset();
      this.__downloadDialog.show();

      // TODO reset form.
    }

    closeDownloadDialog() {
      this.__downloadDialog.close();
      // TODO reset form.
    }

    _downloadStationData() {
      const formData = this.__downloadDataForm.getValues();
      if(!formData){
        return false;
      }
      const filteredTs = [];
      this.__stations
        .filter(station => this.__selectedStations.indexOf(station.station_no) !== -1)
        .forEach(station => {
          if (Array.isArray(formData.dataType)) {
            formData.dataType.forEach(item => {
              filteredTs.push(station.ts_path.replace(station.ts_shortname, item));
            })
          }else if(typeof formData.dataType === 'object'){
            const mapping = formData.dataType[station.stationparameter_no] ? formData.dataType[station.stationparameter_no] : formData.dataType.default
            filteredTs.push(station.ts_path.replace(station.ts_shortname, mapping));
          }
        });
      const data = {
        name: `walhydroExport_${moment().format('YYYY-MM-DDTHH:mm:SS')}`,
        description: `walhydroExport_${moment().format('YYYY-MM-DDTHH:mm:SS')}`,
        purpose: `${formData.purpose}`,
        startTime: `${moment().toISOString()}`,
        cardinality: 'SINGLE',
        notify: true,
        archive: false,
        status: 'ACTIVE',
        config: {
          dataProvisioning: {
            source: {
              columns: this.__downloadDataForm.returnfields,
              dateformat:"yyyy-MM-dd",
              ts_paths: filteredTs,
              from: formData.from,
              to: formData.to,
            },
            target: {
              format: formData.downloadFormat,
            },
          },
          notification: {
            recipients: [
              {
                tsCount: filteredTs.length,
                parameter: this.__stations?.[0]?.stationparameter_name,
                downloadFormat: formData.downloadFormat,
                purpose: formData.purpose,
                name: formData.name,
                email: formData.email,
                orgnization: formData.orgnization,
                dataType: formData.dataType,
                language: 'fr',
              },
            ],
          },
          archiving: {
            source: {
              x: 'y',
            },
            target: {
              x: 'y',
            },
          },
        },
      };
      fetch(this.__configuration.downloadBasePath, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(() => {
          this.__downloadDialog.close();
          KiToast.showToast({ content: this.i18n.t('downloadSuccess') });
        })
        .catch(e => {
          console.error(e);
          KiToast.showToast({ type: 'error', content: this.i18n.t('downloadFailed') });
        });
    }
  };
}
