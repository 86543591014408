import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #graph {
    display: flex;
    flex: 1;
    min-height: 0;
  }

  #graph .highcharts-container .highcharts-legend .highcharts-legend-item span {
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -moz-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -ms-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 50px;
  }

  #graph .highcharts-container .highcharts-legend .highcharts-legend-item span:hover {
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.23);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -o-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.23);
  }
`;
