import { css } from 'lit-element';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: auto;
  }

  .qualityBar {
    display: flex;
    flex-direction: row;
    height: 1.5em;
  }

  .qualityBar .quality {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    height: 100%;
  }

  .qualityBar .quality > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
    display: inline-block;
    max-width: 100%;
  }

  .qualityBar .quality .popup {
    padding: 4px 8px;
  }
`;
